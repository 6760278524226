import React from 'react';
import PageBanner from '../components/PageBanner';
import { useSelector } from 'react-redux';
import { ImmutableNewsletterItem } from '../types';
import NaturalSort from 'natural-sort';

function NewsletterView() {
  const naturalSort = NaturalSort();
  const newsletters = useSelector((state: any) => {
    return state.newsletters;
  });
  const renderNewsletterItems = () => {
    let newsletterItems: any = [];
    newsletters
      .sort((a: number, b: number) => naturalSort(b, a))
      .forEach((newsletterItem: ImmutableNewsletterItem) => {
        newsletterItems.push(
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className="news-block">
              <div className="news-img block-effect">
                <a href={`/downloads/newsletters/${newsletterItem.get('link')}`}>
                  <img src={newsletterItem.get('image')} className="img-responsive" alt="blog-img-1" />
                </a>
              </div>
              <div className="news-content">
                <div className="news-desc">
                  <p className="news-date">
                    <i className="fa fa-calendar"></i>Posted {newsletterItem.get('date')}
                  </p>
                </div>
                <a href={`/downloads/newsletters/${newsletterItem.get('link')}`}>
                  <h4 className="news-heading">{newsletterItem.get('name')}</h4>
                </a>
                <div className="news-dtl">{newsletterItem.get('description')}</div>
                <a className="read-more" href={`/downloads/newsletters/${newsletterItem.get('link')}`}>
                  Read More<i className="fa fa-long-arrow-right"></i>
                </a>
                <br />
                <br />
              </div>
            </div>
          </div>
        );
      });
    return newsletterItems;
  };
  return (
    <>
      <PageBanner BannerImage={`url('images/top-banner.jpg')`} BannerTitle="Newsletters" />

      <div style={{ paddingTop: '1px', backgroundImage: 'url("images/thothbg1.jpg")' }}>
        <section id="news" className="news-main-block news-two">
          <div className="container">
            <div className="row">{renderNewsletterItems()}</div>
          </div>
        </section>
      </div>
    </>
  );
}
export default NewsletterView;

import Immutable from 'immutable';
import { combineReducers } from 'redux';
import AnimalsRealAndImaginary from '../../data/Questions/22/AnimalsRealAndImaginary.json';
import ArabaicAlphabetNames from '../../data/Questions/22/ArabaicAlphabetNames.json';
import ArabicAlphabet from '../../data/Questions/22/ArabicAlphabet.json';
import ArabicAlphabetValues from '../../data/Questions/22/ArabicAlphabetValues.json';
import Diseases from '../../data/Questions/22/Diseases.json';
import EgyptianGods from '../../data/Questions/22/EgyptianGods.json';
import ElderFuthark from '../../data/Questions/22/ElderFuthark.json';
import ElderFutharkNames from '../../data/Questions/22/ElderFutharkNames.json';
import EmperorsScaleColor from '../../data/Questions/22/EmperorsScaleColor.json';
import EmpressesScaleColor from '../../data/Questions/22/EmpressesScaleColor.json';
import EnglishAlphabet from '../../data/Questions/22/EnglishAlphabet.json';
import EnochianAlphabet from '../../data/Questions/22/EnochianAlphabet.json';
import EnochianAlphabetNames from '../../data/Questions/22/EnochianAlphabetNames.json';
import FourtyBuddhistMeditations from '../../data/Questions/22/FourtyBuddhistMeditations.json';
import GoldenDawnTarotTitles from '../../data/Questions/22/GoldenDawnTarotTitles.json';
import GreekAlphabet from '../../data/Questions/22/GreekAlphabet.json';
import GreekAlphabetNames from '../../data/Questions/22/GreekAlphabetNames.json';
import GreekAlphabetNumberValues from '../../data/Questions/22/GreekAlphabetNumberValues.json';
import GreekGods from '../../data/Questions/22/GreekGods.json';
import HeavansOfAssiah from '../../data/Questions/22/HeavansOfAssiah.json';
import HebrewLetters from '../../data/Questions/22/HebrewLetters.json';
import HebrewLettersEnglish from '../../data/Questions/22/HebrewLettersEnglish.json';
import HinduDeities from '../../data/Questions/22/HinduDeities.json';
import HumanBody from '../../data/Questions/22/HumanBody.json';
import KingsScaleColor from '../../data/Questions/22/KingsScaleColor.json';
import LegendaryOrdersOfBeing from '../../data/Questions/22/LegendaryOrdersOfBeing.json';
import MagicalPowers from '../../data/Questions/22/MagicalPowers.json';
import MagicalWeapons from '../../data/Questions/22/MagicalWeapons.json';
import MysticNumbers from '../../data/Questions/22/MysticNumbers.json';
import PathValues from '../../data/Questions/22/PathValues.json';
import Perfumes from '../../data/Questions/22/Perfumes.json';
import PreciousStones from '../../data/Questions/22/PreciousStones.json';
import QueensScaleColor from '../../data/Questions/22/QueensScaleColor.json';
import RomanGods from '../../data/Questions/22/RomanGods.json';
import TarotRomanNumerals from '../../data/Questions/22/TarotRomanNumerals.json';
import TranscendentalMorality from '../../data/Questions/22/TranscendentalMorality.json';
import VegetableDrugs from '../../data/Questions/22/VegetableDrugs.json';

const twentyTwos = combineReducers({
  animalsRealAndImaginary: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        AnimalsRealAndImaginary.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewLetters: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewLetters.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  arabaicAlphabetNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ArabaicAlphabetNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  arabicAlphabet: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ArabicAlphabet.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  arabicAlphabetValues: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ArabicAlphabetValues.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  diseases: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Diseases.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  egyptianGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EgyptianGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  elderFuthark: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ElderFuthark.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  elderFutharkNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ElderFutharkNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  emperorsScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EmperorsScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  empressesScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EmpressesScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  englishAlphabet: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EnglishAlphabet.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  enochianAlphabet: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EnochianAlphabet.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  enochianAlphabetNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EnochianAlphabetNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  fourtyBuddhistMeditations: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        FourtyBuddhistMeditations.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  goldenDawnTarotTitles: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GoldenDawnTarotTitles.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  greekAlphabet: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GreekAlphabet.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  greekAlphabetNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GreekAlphabetNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  greekAlphabetNumberValues: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GreekAlphabetNumberValues.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  greekGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GreekGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  heavansOfAssiah: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HeavansOfAssiah.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewLettersEnglish: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewLettersEnglish.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hinduDeities: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HinduDeities.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  humanBody: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HumanBody.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  kingsScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        KingsScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  legendaryOrdersOfBeing: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        LegendaryOrdersOfBeing.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  magicalPowers: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MagicalPowers.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  magicalWeapons: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MagicalWeapons.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  mysticNumbers: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MysticNumbers.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  pathValues: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PathValues.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  perfumes: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Perfumes.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  preciousStones: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PreciousStones.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  queensScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        QueensScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  romanGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        RomanGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tarotRomanNumerals: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TarotRomanNumerals.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  transcendentalMorality: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TranscendentalMorality.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  vegetableDrugs: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        VegetableDrugs.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
});

export default twentyTwos;

import { combineReducers } from 'redux';
import foursReducer from './fours';
import sevensReducer from './sevens';
import tensReducer from './tens';
import twelvesReducer from './twelves';
import twentyTwosReducer from './twentyTwos';
import thirtyTwosReducer from './thirtyTwos';
import setsReducer from './registry';

const questions = combineReducers({
  fours: foursReducer,
  sevens: sevensReducer,
  tens: tensReducer,
  twelves: twelvesReducer,
  twentyTwos: twentyTwosReducer,
  thirtyTwos: thirtyTwosReducer,
  sets: setsReducer,
});

export default questions;

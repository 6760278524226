//import unauthenticatedClient from '../../client';
import axios from 'axios';
import unauthenticatedClient from '../../client';
export const EXPORT_CONTACT = 'EXPORT_CONTACT';
export const CONTACT_ERROR = 'CONTACT_ERROR';

export const contact = payload => async dispatch => {
  const axoisConfig = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  try {
    const res = await axios.post(`${unauthenticatedClient.defaults.url}contact/contact`, payload, axoisConfig);
    dispatch({
      type: EXPORT_CONTACT,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: CONTACT_ERROR,
      payload: console.log(e),
    });
  }
};

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Header from './components/Header';
import PreLoader from './components/PreLoader';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import AboutView from './views/About';
import HomeView from './views/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import FourOFourView from './views/404';
import OfficersView from './views/Officers';
import DuesView from './views/Dues';
import EventsView from './views/Events';
import ThelemaView from './views/Thelema';
import OTOView from './views/OTO';
import EGCView from './views/EGC';
import InitiationView from './views/Initiation';
import AAView from './views/AA';
import LibraryView from './views/Library';
import DonationsView from './views/Donations';
import NewsletterView from './views/Newsletter';
import DownloadView from './views/Download';
import MasonryView from './views/Masonry';
import ContactView from './views/Contact';
import SetView from './views/Set';
import UserView from './views/User';
import SocialView from './views/social';
function App() {
  if (window.location.protocol === 'https:') {
    window.location.href = window.location.href.replace('https:', 'http:');
  }
  return (
    <html>
      <Header />
      <body style={style.pageStyle}>
        <PreLoader />
        <Router>
          <NavBar />
          <Switch>
            <Route path="/" exact={true}>
              <HomeView />
            </Route>
            <Route path="/about" exact={true}>
              <AboutView />
            </Route>
            <Route path="/officers" exact={true}>
              <OfficersView />
            </Route>
            <Route path="/dues" exact={true}>
              <DuesView />
            </Route>
            <Route path="/donate" exact={true}>
              <DonationsView />
            </Route>
            <Route path="/events" exact={true}>
              <EventsView />
            </Route>
            <Route path="/thelema" exact={true}>
              <ThelemaView />
            </Route>
            <Route path="/oto" exact={true}>
              <OTOView />
            </Route>
            <Route path="/egc" exact={true}>
              <EGCView />
            </Route>
            <Route path="/initiation" exact={true}>
              <InitiationView />
            </Route>
            <Route path="/library" exact={true}>
              <LibraryView />
            </Route>
            <Route path="/newsletters" exact={true}>
              <NewsletterView />
            </Route>
            <Route path="/download" exact={true}>
              <DownloadView />
            </Route>
            <Route path="/aa" exact={true}>
              <AAView />
            </Route>
            <Route path="/masonry" exact={true}>
              <MasonryView />
            </Route>
            <Route path="/contact" exact={true}>
              <ContactView />
            </Route>
            <Route path="/social" exact={true}>
              <SocialView />
            </Route>
            <Route path="/studyGuide" exact={true}>
              <SetView />
            </Route>
            <Route exact={false} path="/user" component={UserView} />

            <Route>
              <FourOFourView />
            </Route>
          </Switch>
          <Footer />
        </Router>
      </body>
    </html>
  );
}
const style = {
  pageStyle: {
    backgroundColor: '#111111',
  },
};
export default App;

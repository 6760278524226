import { combineReducers } from 'redux';
import profile from './profile';
import officers from './officers';
import homeSliderItems from './homeSlider';
import duesListItems from './dues';
import eventItems from './events';
import library from './library';
import siteCopy from './siteCopy';
import newsletters from './newsletters';
import degreeListItems from './degrees';
import questions from './questions';
import scores from './scores';
import lapisUser from './lapisUser';
import quotes from './quotes';
import downloadListItems from './downloads';
import socialLinkList from './social';
export default combineReducers({
  profile,
  officers,
  homeSliderItems,
  duesListItems,
  eventItems,
  library,
  siteCopy,
  newsletters,
  degreeListItems,
  questions,
  scores,
  lapisUser,
  quotes,
  downloadListItems,
  socialLinkList,
});

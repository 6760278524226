import React from 'react';
import { useSelector } from 'react-redux';
import ImageAndTextLeft from '../components/ImageAndTextLeft';
import PageBanner from '../components/PageBanner';
import SectionBody from '../components/SectionBody';
import SectionHeading from '../components/SectionHeading';
import SectionSubHeading from '../components/SectionSubHeading';
import { getSiteCopyById } from '../shared/utilities';
import { ImmutableDuesListItem } from '../types';
function DuesView() {
  const dues = useSelector((state: any) => {
    return state.duesListItems;
  });
  const renderDuesItems = () => {
    let duesItems: any = [];
    dues.forEach((duesItem: ImmutableDuesListItem) => {
      duesItems.push(
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 row-top-spacing">
          <div className="donation-item">
            <div className="donation-img block-effect">
              <img src={duesItem.get('image')} className="img-responsive" alt="donation-img-1" />

              <span className="donation-btn">
                <a href={duesItem.get('payPalSubscriptionLink')} className="btn btn-default">
                  Subscribe via PayPal
                </a>
              </span>
            </div>
            <div className="donation-content">
              <a href={duesItem.get('payPalSubscriptionLink')}>
                <h4 className="donation-heading">
                  {duesItem.get('name')} <br />
                  {duesItem.get('fee')} per {duesItem.get('occurance')}
                </h4>
              </a>
              <p className="donation-dtl">{duesItem.get('description')}</p>
            </div>
          </div>
        </div>
      );
    });
    return duesItems;
  };
  return (
    <>
      <PageBanner BannerImage={`url('images/top-banner.jpg')`} BannerTitle="Dues" />
      <div style={{ paddingTop: '1px', backgroundColor: '#ffffff' }}>
        <section id="donation" className="donation-main-block donation-two">
          <div className="container">
            <div className="abt-content">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="abt-sectionOne">
                    <SectionHeading Heading={getSiteCopyById('dues.title')} />
                    <SectionSubHeading Heading={getSiteCopyById('dues.heading')} />
                    <SectionBody Heading={getSiteCopyById('dues.body')} />
                  </div>
                </div>
              </div>
              <ImageAndTextLeft
                ButtonLink={null}
                ButtonText={null}
                Image="images/donation/ganesh.jpg"
                HeaderText={getSiteCopyById('dues.questions.heading')}
                BodyText={getSiteCopyById('dues.questions.body')}
              />
            </div>
            <div className="row">{renderDuesItems()}</div>
          </div>
        </section>
      </div>
    </>
  );
}
export default DuesView;

export const officerList = [
  {
    Name: 'Frater Damien',
    Title: 'Master',
    Email: 'lapislazuli.master@oto-usa.org',
    Photo: 'images/officers/master.jpg',
    id: 1,
  },
  {
    Name: 'Soror 33',
    Title: 'Secretary',
    Email: 'lapislazuli.secretary@oto-usa.org',
    Photo: 'images/officers/secretary.jpg',
    id: 2,
  },
  {
    Name: 'Frater A.S.P.A.',
    Title: 'Treasurer',
    Email: 'lapislazuli.treasurer@oto-usa.org',
    Photo: 'images/officers/treasurer.jpg',
    id: 4,
  },
  {
    Name: 'Fr. קטשי',
    Title: 'Initiation Secretaty',
    Email: 'lapislazuli.initiation@oto-usa.org',
    Photo: 'images/officers/initsecreatry.jpg',
    id: 5,
  },
  {
    Name: 'Fr. שאמ',
    Title: 'Deputy Master',
    Email: 'lapislazuli.deputy@oto-usa.org',
    Photo: 'images/officers/deputy.jpg',
    id: 6,
  },
  // {
  //   Name: 'Fr. קטשי',
  //   Title: 'EGC Coordinator',
  //   Email: 'lapislazuli.egc@oto-usa.org',
  //   Photo: 'images/officers/egc.jpg',
  //   id: 6,
  // },
];

import Immutable from 'immutable';
import { combineReducers } from 'redux';
import Arbatel from '../../data/Questions/7/Arbatel.json';
import BabylonianPlanetaryGods from '../../data/Questions/7/BabylonianPlanetaryGods.json';
import Chakras from '../../data/Questions/7/Chakras.json';
import DaysOfTheWeek from '../../data/Questions/7/DaysOfTheWeek.json';
import DemonRanks from '../../data/Questions/7/DemonRanks.json';
import DoubleLetters from '../../data/Questions/7/DoubleLetters.json';
import HebrewPlanetarySpirits from '../../data/Questions/7/HebrewPlanetarySpirits.json';
import PlanetaryAngels from '../../data/Questions/7/PlanetaryAngels.json';
import PlanetaryMetals from '../../data/Questions/7/PlanetaryMetals.json';
import Planets from '../../data/Questions/7/Planets.json';
import SevenDeadlySins from '../../data/Questions/7/SevenDeadlySins.json';
const sevens = combineReducers({
  arbatel: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Arbatel.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  babylonianPlanetaryGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        BabylonianPlanetaryGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  chakras: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Chakras.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  daysOfTheWeek: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        DaysOfTheWeek.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  demonRanks: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        DemonRanks.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  doubleLetters: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        DoubleLetters.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewPlanetarySpirits: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewPlanetarySpirits.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  planetaryAngels: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PlanetaryAngels.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  planetaryMetals: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PlanetaryMetals.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  planets: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Planets.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  sevenDeadlySins: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        SevenDeadlySins.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
});

export default sevens;

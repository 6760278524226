/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ImmutableHintItem } from '../types';
import NaturalSort from 'natural-sort';
import { ImageMap } from '@qiuz/react-image-map';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import PageBanner from '../components/PageBanner';
import { GameModes } from '../shared/gameModes';
import { updateSetData } from '../redux/scores/actions';
import TextBlock from '../components/TextBlock';
import unauthenticatedClient from '../client';

function SetView() {
  const dispatch = useDispatch();
  const playModes = [GameModes.GAME_MODE_PLAY, GameModes.GAME_MODE_STUDY];
  const defaultPlayMode = playModes[1];

  const options = ['Select An Option'];
  const defaultOption = options[0];

  const naturalSort = NaturalSort();

  const setData = useSelector((state: any) => {
    return state.questions;
  });

  const scoreData = useSelector((state: any) => {
    return state.scores;
  });
  const loggedInUser = useSelector((state: any) => {
    return state.lapisUser;
  });

  //some state data
  const [gameMode, setGameMode] = useState<string>(GameModes.GAME_MODE_STUDY);
  const [setSubset, setSetSubset] = useState<any>();
  const [playItem, setPlayItem] = useState<any>();
  const [selectedSetId, setSelectedSetId] = useState<string>();
  const [selectedSetDescription, setSelectedSetDescription] = useState<string>('');
  useEffect(() => {
    if (setSubset) {
      setPlayItem(setSubset.toArray()[getRandomInt(setSubset.size)]);
    }
  }, [setSubset]);

  const getSetItemsArray = () => {
    let setItems: any = [];
    setData?.sets?.setData
      .sort((a: any, b: any) => naturalSort(a.get('setName'), b.get('setName')))
      .forEach((hintItem: any) => {
        if (hintItem.get('reduxMap')?.length > 0) {
          setItems.push({ value: hintItem.get('reduxMap'), label: hintItem.get('setName') });
        }
      });
    return setItems;
  };

  const getScore = () => {
    let scoreText = '';
    if (gameMode === GameModes.GAME_MODE_PLAY) {
      scoreData.forEach((scoreItem: any) => {
        if (scoreItem.get('setId') === selectedSetId) {
          scoreText = `Your Score: ${scoreItem.get('score')}`;
        }
      });
    }
    return scoreText;
  };
  const getPresentAnswer = (hint: string) => {
    let answer = '';
    switch (gameMode) {
      case GameModes.GAME_MODE_STUDY:
        setSubset?.forEach((hintItem: ImmutableHintItem) => {
          if (hint.toLocaleLowerCase() === hintItem.get('answer').toLocaleLowerCase()) {
            answer = hintItem.get('hint');
          }
        });
        break;
    }

    return answer;
  };

  const getRandomInt = (max: number) => {
    let rando = Math.floor(Math.random() * Math.floor(max));
    return rando;
  };

  const renderHint = () => {
    let hint: any = [];
    if (playItem) {
      switch (gameMode) {
        case GameModes.GAME_MODE_PLAY:
          hint.push(<TextBlock Heading="Hint" SubHeading="" Body={playItem[1].get('hint')}></TextBlock>);

          break;
      }
    }
    return hint;
  };
  const handleAnswerItemClicked = (a: any, i: any) => {
    if (gameMode === GameModes.GAME_MODE_PLAY) {
      if (playItem[1].get('answer').toLowerCase() === a.name.toLowerCase()) {
        setPlayItem(setSubset.toArray()[getRandomInt(setSubset.size)]);
        dispatch(
          updateSetData({
            userId: loggedInUser.toArray()[0][0],
            setId: selectedSetId,
            amount: '1',
          })
        );
      } else {
        dispatch(
          updateSetData({
            userId: loggedInUser.toArray()[0][0],
            setId: selectedSetId,
            amount: '-1',
          })
        );
      }
    }
  };
  const getUserName = () => {
    let userName: any = [];
    let userData = '';
    try {
      userData = loggedInUser?.toArray()[0][1].get('name');
    } catch (e) {}
    if (userData.length > 0) {
      userName.push(<div>{userData}</div>);
    } else {
      userName.push(
        <div>
          <a
            className="btn btn-default active"
            href={`${unauthenticatedClient.defaults.url}login/federated/google`}
            style={{ width: '100%', backgroundColor: '#000000' }}
          >
            Sign in with Google to Play
          </a>
          <br />
          <br />
        </div>
      );
    }
    return userName;
  };
  const renderCatagoryDescription = () => {
    return <TextBlock Heading="Description" SubHeading="" Body={selectedSetDescription}></TextBlock>;
  };

  const getImageHeight = () => {
    let height = '100vh';
    if (window.innerWidth <= 500) {
      height = '30vh';
    }
    return height;
  };

  const setHoverState = (data: any) => {};
  return (
    <>
      <PageBanner BannerTitle="Study Guide" BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ marginTop: '0px', paddingTop: '10px', backgroundImage: 'url("images/thothbg1.jpg")' }}>
        <section id="donation" className="donation-main-block donation-two">
          <div className="container" style={{ width: '99%' }}>
            <div
              style={{ paddingTop: '10px', height: getImageHeight(), backgroundColor: '#f9c140' }}
              className="col-md-2 portfolio-item images"
            >
              {setData?.sets?.setData ? (
                <>
                  <div>{getUserName()}</div>
                  <Dropdown
                    className="treeeDropClass"
                    controlClassName="treeeDropClass"
                    placeholderClassName="treeeDropClass"
                    menuClassName="treeeDropClass"
                    options={playModes}
                    onChange={(hintItem: any) => {
                      setGameMode(hintItem.value);
                    }}
                    value={defaultPlayMode}
                    placeholder="Select a Mode"
                  />
                  <Dropdown
                    className="treeeDropClass"
                    controlClassName="treeeDropClass"
                    placeholderClassName="treeeDropClass"
                    menuClassName="treeeDropClass"
                    options={getSetItemsArray()}
                    onChange={(hintItem: any) => {
                      const items = hintItem.value.split('.');
                      setSetSubset(setData[items[0]][items[1]]);
                      setData?.sets?.setData.forEach((hintItem2: any) => {
                        if (hintItem2.get('reduxMap') === hintItem.value) {
                          setSelectedSetId(hintItem2.get('id'));
                          setSelectedSetDescription(hintItem2.get('Description'));
                        }
                      });
                    }}
                    value={defaultOption}
                    placeholder="Select an option"
                  />
                  {getScore()}

                  {renderHint()}

                  {renderCatagoryDescription()}
                </>
              ) : null}
            </div>
            <div className="col-md-10">
              <ImageMap
                onClick={() => {
                  console.log('clky');
                }}
                onMapClick={(a, i) => {
                  handleAnswerItemClicked(a, i);
                }}
                map={[
                  {
                    left: '40%',
                    top: '2.5%',
                    height: '10%',
                    width: '20%',
                    name: 'KETHER',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('kether');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('kether')}</div>
                    ),
                  },
                  {
                    left: '75%',
                    top: '13%',
                    height: '10%',
                    width: '20%',
                    name: 'CHOKMAH',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('chokmah')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('chokmah');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '75%',
                    top: '34%',
                    height: '10%',
                    width: '20%',
                    name: 'CHESED',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('chesed')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('chesed');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '5%',
                    top: '34%',
                    height: '10%',
                    width: '20%',
                    name: 'GEBURAH',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('geburah')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('geburah');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '5%',
                    top: '55.5%',
                    height: '10%',
                    width: '20%',
                    name: 'HOD',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('hod')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('hod');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '75%',
                    top: '55.5%',
                    height: '10%',
                    width: '20%',
                    name: 'NETZACH',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('netzach')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('netzach');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '5%',
                    top: '13%',
                    height: '10%',
                    width: '20%',
                    name: 'BINAH',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('binah')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('binah');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '40%',
                    top: '45%',
                    height: '10%',
                    width: '20%',
                    name: 'TIPHARETH',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('tiphareth')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('tiphareth');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '40%',
                    top: '66%',
                    height: '10%',
                    width: '20%',
                    name: 'YESOD',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('yesod')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('yesod');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '40%',
                    top: '88%',
                    height: '10%',
                    width: '20%',
                    name: 'MALKUTH',
                    style: { cursor: 'pointer' },
                    render: (area: any, index: number) => (
                      <div style={styles.sphereItem}>{getPresentAnswer('malkuth')}</div>
                    ),
                    onMouseOver: () => {
                      setHoverState('malkuth');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '30%',
                    top: '9%',
                    height: '6%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(60deg)' },
                    name: 'beth',
                    render: (area: any, index: number) => <div style={styles.beth}>{getPresentAnswer('beth')}</div>,
                    onMouseOver: () => {
                      setHoverState('beth');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '65.5%',
                    top: '9.5%',
                    height: '6%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(-60deg)' },
                    name: 'aleph',
                    onMouseOver: () => {
                      setHoverState('aleph');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.aleph}>{getPresentAnswer('aleph')}</div>,
                  },
                  {
                    left: '47%',
                    top: '6%',
                    height: '24%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(90deg)' },
                    name: 'dalet',
                    onMouseOver: () => {
                      setHoverState('dalet');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.dalet}>{getPresentAnswer('dalet')}</div>,
                  },
                  {
                    left: '47.5%',
                    top: '20%',
                    height: '18%',
                    width: '5%',
                    name: 'gimel',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('gimel');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.gimel}>{getPresentAnswer('gimel')}</div>,
                  },
                  {
                    left: '47.5%',
                    top: '14%',
                    height: '2%',
                    width: '5%',
                    name: 'gimel',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('gimel');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '47.5%',
                    top: '41%',
                    height: '2%',
                    width: '5%',
                    name: 'gimel',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('gimel');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '12.8%',
                    top: '25%',
                    height: '8%',
                    width: '5%',
                    name: 'cheth',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('kether');
                    },
                    onMouseOut: () => {
                      setHoverState('cheth');
                    },
                    render: (area: any, index: number) => <div style={styles.cheth}>{getPresentAnswer('cheth')}</div>,
                  },
                  {
                    left: '12.8%',
                    top: '46%',
                    height: '8%',
                    width: '5%',
                    name: 'mem',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('mem');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.mem}>{getPresentAnswer('mem')}</div>,
                  },
                  {
                    left: '82.5%',
                    top: '25%',
                    height: '8%',
                    width: '5%',
                    name: 'vav',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('vav');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.vav}>{getPresentAnswer('vav')}</div>,
                  },
                  {
                    left: '82.5%',
                    top: '46%',
                    height: '8%',
                    width: '5%',
                    name: 'kaph',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('kaph');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.kaph}>{getPresentAnswer('kaph')}</div>,
                  },
                  {
                    left: '47%',
                    top: '27.5%',
                    height: '24%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(90deg)' },
                    name: 'teth',
                    onMouseOver: () => {
                      setHoverState('teth');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.teth}>{getPresentAnswer('teth')}</div>,
                  },
                  {
                    left: '68%',
                    top: '24%',
                    height: '14%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(30deg)' },
                    name: 'he',
                    onMouseOver: () => {
                      setHoverState('he');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.he}>{getPresentAnswer('he')}</div>,
                  },

                  {
                    left: '56%',
                    top: '41%',
                    height: '3%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(30deg)' },
                    name: 'he',
                    onMouseOver: () => {
                      setHoverState('he');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },

                  {
                    left: '27%',
                    top: '24%',
                    height: '14%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(-30deg)' },
                    name: 'zain',
                    onMouseOver: () => {
                      setHoverState('zain');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.zain}>{getPresentAnswer('zain')}</div>,
                  },
                  {
                    left: '39%',
                    top: '41%',
                    height: '3%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(-30deg)' },
                    name: 'zain',
                    onMouseOver: () => {
                      setHoverState('zain');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '47%',
                    top: '48.5%',
                    height: '24%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(90deg)' },
                    name: 'peh',
                    onMouseOver: () => {
                      setHoverState('peh');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.peh}>{getPresentAnswer('peh')}</div>,
                  },
                  {
                    left: '47.5%',
                    top: '78%',
                    height: '8%',
                    width: '5%',
                    style: { cursor: 'pointer' },
                    name: 'tav',
                    onMouseOver: () => {
                      setHoverState('tav');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.tav}>{getPresentAnswer('tav')}</div>,
                  },
                  {
                    left: '47.5%',
                    top: '56%',
                    height: '3.0%',
                    width: '5%',
                    name: 'samekh',
                    style: { cursor: 'pointer' },
                    onMouseOver: () => {
                      setHoverState('samekh');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.samekh}>{getPresentAnswer('samekh')}</div>,
                  },
                  {
                    left: '47.5%',
                    top: '62%',
                    height: '3.0%',
                    width: '5%',
                    style: { cursor: 'pointer' },
                    name: 'samekh',
                    onMouseOver: () => {
                      setHoverState('samekh');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                  },
                  {
                    left: '30%',
                    top: '40.5%',
                    height: '8%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(-60deg)' },
                    name: 'lamed',
                    onMouseOver: () => {
                      setHoverState('lamed');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.lamed}>{getPresentAnswer('lamed')}</div>,
                  },
                  {
                    left: '66%',
                    top: '40.5%',
                    height: '8%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(60deg)' },
                    name: 'yod',
                    onMouseOver: () => {
                      setHoverState('yod');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.yod}>{getPresentAnswer('yod')}</div>,
                  },
                  {
                    left: '30%',
                    top: '51.5%',
                    height: '8%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(-120deg)' },
                    name: 'ayin',
                    onMouseOver: () => {
                      setHoverState('ayin');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.ayin}>{getPresentAnswer('ayin')}</div>,
                  },
                  {
                    left: '66%',
                    top: '51.5%',
                    height: '8%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(120deg)' },
                    name: 'nun',
                    onMouseOver: () => {
                      setHoverState('nun');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.nun}>{getPresentAnswer('nun')}</div>,
                  },
                  {
                    left: '30%',
                    top: '62%',
                    height: '8%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(-60deg)' },
                    name: 'resh',
                    onMouseOver: () => {
                      setHoverState('resh');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.resh}>{getPresentAnswer('resh')}</div>,
                  },
                  {
                    left: '66%',
                    top: '62%',
                    height: '8%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(60deg)' },
                    name: 'tzaddi',
                    onMouseOver: () => {
                      setHoverState('tzaddi');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.tzaddi}>{getPresentAnswer('tzaddi')}</div>,
                  },
                  {
                    left: '68%',
                    top: '66%',
                    height: '22%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(36deg)' },
                    name: 'qoph',
                    onMouseOver: () => {
                      setHoverState('qoph');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.qoph}>{getPresentAnswer('qoph')}</div>,
                  },
                  {
                    left: '28%',
                    top: '66%',
                    height: '22%',
                    width: '5%',
                    style: { cursor: 'pointer', transform: 'rotate(-36deg)' },
                    name: 'shin',
                    onMouseOver: () => {
                      setHoverState('shin');
                    },
                    onMouseOut: () => {
                      setHoverState('');
                    },
                    render: (area: any, index: number) => <div style={styles.shin}>{getPresentAnswer('shin')}</div>,
                  },
                ]}
                className="treeClass"
                src="images/kabbalah/Tree1.png"
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
const styles = {
  sphereItem: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
  aleph: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    transform: 'rotate(60deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  beth: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '50%',
    transform: 'rotate(-60deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  gimel: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
  dalet: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '400%',
    transform: 'rotate(-90deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  he: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '200%',
    transform: 'rotate(-30deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  vav: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
  zain: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '200%',
    transform: 'rotate(30deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  cheth: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
  teth: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '400%',
    transform: 'rotate(-90deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  yod: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '200%',
    transform: 'rotate(300deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  kaph: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
  lamed: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    transform: 'rotate(60deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  mem: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
  nun: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    transform: 'rotate(240deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  samekh: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
  ayin: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    transform: 'rotate(120deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  peh: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    transform: 'rotate(-90deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  tzaddi: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '200%',
    transform: 'rotate(-60deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  qoph: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '250%',
    transform: 'rotate(-36deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  resh: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    transform: 'rotate(60deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  shin: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '250%',
    transform: 'rotate(36deg)',
    color: '#000000',
    fontWeight: 'bold',
  },
  tav: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center' as 'center',
    marginTop: '20%',
    color: '#000000',
    fontWeight: 'bold',
  },
};
export default SetView;

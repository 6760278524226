import React from 'react';
import { OfficerProps } from '../types';
function Officer(props: OfficerProps) {
  return (
    <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 row-top-spacing">
      <div className="pastor-gallery-block">
        <div className="overlay-bg"></div>
        <div className="pastor-img">
          <a href="pastor-details.html">
            <img src={props.Photo} className="img-responsive" alt="pastor-img-1" />
          </a>
        </div>
        <div className="pastor-content">
          <a href="pastor-details.html">
            <h4 className="pastor-id">{props.Name}</h4>
          </a>
          <p className="pastor-post">{props.Title}</p>
          <div className="social-icon social-two">
            <ul>
              <li>
                <a href={`mailto:${props.Email}`} target="_blank" className="google-plus" rel="noreferrer">
                  <i className="fa fa-envelope"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Officer;

export const quotesList = [
  {
    id: 1,
    Quote: 'Do what thou wilt shall be the whole of the Law',
    By: 'Aiwass: The Minister of Hoor-paar-kraat',
    Page: 'home',
  },
  {
    id: 2,
    Quote: 'Love is the law, love under will',
    By: 'Aiwass: The Minister of Hoor-paar-kraat',
    Page: 'home',
  },
  {
    id: 3,
    Quote:
      'Behold!  She is seated upon the throne of turquoise and lapis lazuli, and she is like a flawless emerald, and upon the pillars that support the canopy of her throne are sculptured the Ram, and the Sparrow, and the Cat, and a strange fish.',
    By: 'The Vision and the Voice',
    Page: 'home',
  },
  {
    id: 4,
    Quote:
      '"It is improper to disclose the nature of these ceremonies; firstly, because their Initiates are bound by the strictest vows not to do so; secondly, because surprise is an element in their efficacy; and thirdly, because the Magical Formulae explicitly or implicitly contained therein are, from a practical point of view, both powerful and dangerous. Automatic safeguards there are, it is true; but a Black Magician of first-class ability might find a way to overcome these obstacles, and work great mischief upon others before the inevitable recoil of his artillery destroys him."',
    By: 'Aleister Crowley: Magick Without Tears, Ch. XIII,',
    Page: 'initiations',
  },
  {
    id: 4,
    Quote: '"Magick is the science and art of causing change to occur in conformity with the Will."',
    By: 'Aleister Crowley,',
    Page: 'aa',
  },
];

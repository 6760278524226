import PageBanner from '../components/PageBanner';
import SectionBody from '../components/SectionBody';
import SectionHeading from '../components/SectionHeading';
import SectionSubHeading from '../components/SectionSubHeading';
import { getRandomBackground, getSiteCopyById } from '../shared/utilities';
function ThelemaView() {
  return (
    <>
      <PageBanner BannerTitle="About Thelema" BannerImage={`url('images/top-banner.jpg')`} />
      <section
        id="about-block"
        className="about-main-block abt-two"
        style={{ backgroundImage: getRandomBackground(), marginTop: '0px' }}
      >
        <div className="container" style={{ backgroundColor: '#ffffff', marginTop: '0px' }}>
          <div className="abt-img" style={{ marginTop: '10px' }}>
            <img src="images/thelema/thelema.jpg" className="img-responsive" alt="abt-img-1" />
          </div>
          <div className="abt-content">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="abt-sectionOne">
                  <SectionHeading Heading={getSiteCopyById('about.thelema.title')} />
                  <SectionSubHeading Heading={getSiteCopyById('about.thelema.subtitle')} />
                  <SectionBody Heading={getSiteCopyById('lapis.thelema.body')} />
                </div>
              </div>
            </div>

            <br />
          </div>
        </div>
      </section>
    </>
  );
}
export default ThelemaView;

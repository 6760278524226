//import unauthenticatedClient from '../../client';
import axios from 'axios';
import { validate as uuidValidate } from 'uuid';
import unauthenticatedClient from '../../client';

export const EXPORT_LOGOUT = 'EXPORT_LOOUT';
export const EXPORT_LOGIN = 'EXPORT_LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const login = payload => async dispatch => {
  const axoisConfig = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  if (uuidValidate(payload)) {
    try {
      const res = await axios.get(`${unauthenticatedClient.defaults.url}lapisUser/login/${payload}`, {}, axoisConfig);
      dispatch({
        type: EXPORT_LOGIN,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: LOGIN_ERROR,
        payload: console.log(e),
      });
    }
  } else {
    dispatch({
      type: LOGIN_ERROR,
      payload: console.log(payload, 'not a valid uuid'),
    });
  }
};

import Immutable from 'immutable';
import { combineReducers } from 'redux';
import AngelsOfTheDay from '../../data/Questions/12/AngelsOfTheDay.json';
import AngelsOfTheNight from '../../data/Questions/12/AngelsOfTheNight.json';
import ArabicZodiacNames from '../../data/Questions/12/ArabicZodiacNames.json';
import BodyOfMan from '../../data/Questions/12/BodyOfMan.json';
import Breastplate from '../../data/Questions/12/Breastplate.json';
import ChineseZodiac from '../../data/Questions/12/ChineseZodiac.json';
import EgyptianGodsOfTheZodiac from '../../data/Questions/12/EgyptianGodsOfTheZodiac.json';
import GeomanticFiguresEnglish from '../../data/Questions/12/GeomanticFiguresEnglish.json';
import GeomanticFiguresSymbols from '../../data/Questions/12/GeomanticFiguresSymbols.json';
import GepmanticFigures from '../../data/Questions/12/GepmanticFigures.json';
import GoeticDemonsDayAsc from '../../data/Questions/12/GoeticDemonsDayAsc.json';
import GoeticDemonsDayCad from '../../data/Questions/12/GoeticDemonsDayCad.json';
import GoeticDemonsDaySuc from '../../data/Questions/12/GoeticDemonsDaySuc.json';
import GoeticDemonsNightAsc from '../../data/Questions/12/GoeticDemonsNightAsc.json';
import GoeticDemonsNightCad from '../../data/Questions/12/GoeticDemonsNightCad.json';
import GoeticDemonsNightSuc from '../../data/Questions/12/GoeticDemonsNightSuc.json';
import GreekZodiacNames from '../../data/Questions/12/GreekZodiacNames.json';
import HebrewCalendar from '../../data/Questions/12/HebrewCalendar.json';
import HebrewZodiac from '../../data/Questions/12/HebrewZodiac.json';
import HinduZodiacNames from '../../data/Questions/12/HinduZodiacNames.json';
import HoroscopeHouses from '../../data/Questions/12/HoroscopeHouses.json';
import OrdersOfQliphoth from '../../data/Questions/12/OrdersOfQliphoth.json';
import OrdersOfQliphothEnglish from '../../data/Questions/12/OrdersOfQliphothEnglish.json';
import OrdinalPositions from '../../data/Questions/12/OrdinalPositions.json';
import PerfumesAscendent from '../../data/Questions/12/PerfumesAscendent.json';
import PerfumesCadent from '../../data/Questions/12/PerfumesCadent.json';
import PerfumesSuccedent from '../../data/Questions/12/PerfumesSuccedent.json';
import SanskritZodiacNames from '../../data/Questions/12/SanskritZodiacNames.json';
import SensesOfMan from '../../data/Questions/12/SensesOfMan.json';
import SingleLetters from '../../data/Questions/12/SingleLetters.json';
import TheApostles from '../../data/Questions/12/TheApostles.json';
import TwelveTribes from '../../data/Questions/12/TwelveTribes.json';
import ZodiacSymbols from '../../data/Questions/12/ZodiacSymbols.json';
import ZodiacalSigns from '../../data/Questions/12/ZodiacalSigns.json';
const twelves = combineReducers({
  angelsOfTheDay: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        AngelsOfTheDay.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  angelsOfTheNight: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        AngelsOfTheNight.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  arabicZodiacNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ArabicZodiacNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  bodyOfMan: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        BodyOfMan.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  breastplate: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Breastplate.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  chineseZodiac: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ChineseZodiac.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  egyptianGodsOfTheZodiac: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EgyptianGodsOfTheZodiac.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  geomanticFiguresEnglish: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GeomanticFiguresEnglish.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  geomanticFiguresSymbols: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GeomanticFiguresSymbols.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  geomanticFigures: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GepmanticFigures.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  goeticDemonsDayAsc: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GoeticDemonsDayAsc.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  goeticDemonsDayCad: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GoeticDemonsDayCad.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  goeticDemonsDaySuc: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GoeticDemonsDaySuc.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  goeticDemonsNightAsc: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GoeticDemonsNightAsc.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  goeticDemonsNightCad: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GoeticDemonsNightCad.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  goeticDemonsNightSuc: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GoeticDemonsNightSuc.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  greekZodiacNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GreekZodiacNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewCalendar: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewCalendar.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewZodiac: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewZodiac.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hinduZodiacNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HinduZodiacNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  horoscopeHouses: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HoroscopeHouses.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  ordersOfQliphoth: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        OrdersOfQliphoth.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  ordersOfQliphothEnglish: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        OrdersOfQliphothEnglish.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  ordinalPositions: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        OrdinalPositions.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  perfumesAscendent: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PerfumesAscendent.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  perfumesSuccedent: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PerfumesSuccedent.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  perfumesCadent: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PerfumesCadent.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  sanskritZodiacNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        SanskritZodiacNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  sensesOfMan: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        SensesOfMan.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  singleLetters: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        SingleLetters.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  theApostles: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TheApostles.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  twelveTribes: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TwelveTribes.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  zodiacSymbols: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ZodiacSymbols.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  zodiacalSigns: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ZodiacalSigns.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
});

export default twelves;

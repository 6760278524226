import Immutable from 'immutable';

const profile = (state = new Immutable.Map(), action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default profile;

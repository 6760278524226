import Immutable from 'immutable';
import { duesList } from '../../data/dues';

const duesListItems = (state = new Immutable.Map(), action) => {
  return state.merge(
    Immutable.fromJS(
      duesList.reduce((map, property) => {
        map[property.id] = property;
        return map;
      }, {})
    )
  );
};

export default duesListItems;

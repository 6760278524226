import React, { useState } from 'react';
import PageBanner from '../components/PageBanner';
import { useSelector } from 'react-redux';
import { EventListItem, ImmutableEventListItem } from '../types';
import Modal from 'react-modal';
import { util } from 'prettier';
import { safeHtml } from '../shared/utilities';

function EventsView() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<ImmutableEventListItem>();

  const events = useSelector((state: any) => {
    return state.eventItems;
  });

  const showEvent = (event: any) => {
    setSelectedEvent(event);
    setIsOpen(true);
  };
  const hideEvent = () => {
    setIsOpen(false);
  };
  const renderEventItems = () => {
    let duesItems: any = [];
    events.forEach((eventItem: ImmutableEventListItem) => {
      duesItems.push(
        <div key={eventItem.get('id')} className="col-lg-3 col-md-4 col-sm-6 col-xs-12 row-top-spacing">
          <div className="upcoming-block">
            <div className="upcoming-img block-effect">
              <a onClick={() => showEvent(eventItem)}>
                <img src={eventItem.get('image')} className="img-responsive" alt="upcoming-img-01" />
                <div style={styles.eventTime}>
                  <div>{eventItem.get('startDate')}</div>
                  <div> &nbsp;</div>
                </div>
              </a>
            </div>
            <div className="upcoming-content">
              <a onClick={() => showEvent(eventItem)}>
                <h4 className="upcoming-heading">{eventItem.get('title')}</h4>
              </a>
              <p className="upcoming-dtl">{eventItem.get('summary')}</p>
            </div>
          </div>
        </div>
      );
    });
    return duesItems;
  };

  return (
    <>
      <PageBanner BannerImage={`url('images/top-banner.jpg')`} BannerTitle="Upcoming Events" />
      <Modal isOpen={modalIsOpen} style={customStyles}>
        <h2>{selectedEvent?.get('title')}</h2>
        <img src={selectedEvent?.get('image')} className="img-responsive" alt="upcoming-img-01" />
        <div>{safeHtml(selectedEvent?.get('body')!)}</div>
        <button onClick={() => hideEvent()}>close</button>
      </Modal>
      <section id="upcoming-events" className="upcoming-main-block" style={{ backgroundColor: '#ffffff' }}>
        <div className="container">
          <div className="row">Please follow us on Social Media for the most up to date events:</div>
          <div className="row" style={{ height: '400px', textAlign: 'center' }}>
            <a href="https://www.instagram.com/oto_lapislazulioasis/">
              <img style={{ width: 128 }} src="images/social/insta.webp" alt="Link to Instagram" />
            </a>
            &nbsp;
            <a href="https://www.facebook.com/lapislazulioto/events">
              <img style={{ width: 128 }} src="images/social/face-small.webp" alt="Link to Facebook Events" />
            </a>
          </div>

          {/* <div className="row">{renderEventItems()}</div> */}
        </div>
      </section>
    </>
  );
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const styles = {
  eventTime: {
    marginTop: '-60px',
    width: '100%',
    paddingLeft: '20px',
    color: '#ffffff',
    fontFamily: 'Playfair Display',
    fontSize: '18pt',
    textAlign: 'center' as 'center',
  },
};
export default EventsView;

export const socialLinks = [
  {
    id: 1,
    title: 'Friend us on Facebook',
    link: 'https://www.facebook.com/lapislazulioto',
    image: 'images/social/facebook.jpg',
  },
  {
    id: 2,
    title: 'In Person events on Meetup',
    link: 'https://www.meetup.com/LapisLazuli-OTO/',
    image: 'images/social/meetup.jpg',
  },
  {
    id: 3,
    title: 'Chat with Us on Discord',
    link: 'https://discord.gg/2vqWCPuJg7',
    image: 'images/social/discord.jpg',
  },
];

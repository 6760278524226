import React from 'react';
import { getSiteCopyById, safeHtml } from '../shared/utilities';
import ImageAndTextLeft from './ImageAndTextLeft';
import SectionBody from './SectionBody';
import SectionHeading from './SectionHeading';
import SectionSubHeading from './SectionSubHeading';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useSelector } from 'react-redux';
import { ImmutableDegreeItem, ImmutableQuoteItem } from '../types';
import NaturalSort from 'natural-sort';
import ImageAndTextRight from './ImageAndTextRight';
import QuotesBlock from './Quotes';

function InitiationContent() {
  const quoteData = useSelector((state: any) => {
    return state.quotes.quotes.filter((item: ImmutableQuoteItem) => {
      return item.get('Page').toLocaleLowerCase() === 'initiations';
    });
  });

  const naturalSort = NaturalSort();
  const degrees = useSelector((state: any) => {
    return state.degreeListItems;
  });
  const renderInitiationFee = (fee: number) => {
    return fee ? `Initiation Fee: $${fee}` : '';
  };
  const renderAnnualDues = (fee: number) => {
    return fee ? `Annual Dues to USGL: $${fee}` : '';
  };
  const renderMonthlyDues = (fee: number) => {
    return fee ? `Monthly Dues to Lapis: $${fee}` : '';
  };
  const renderQuote = (quote: string) => {
    return (
      <>
        <span className="quotes-icon">
          <i style={{ fontSize: '8pt' }} className="fa fa-quote-left"></i>
        </span>
        <p className="client-dtl" style={{ overflowY: 'scroll', fontSize: '10pt' }}>
          {safeHtml(quote)}
        </p>
        <span className="quotes-icon">
          <i style={{ fontSize: '8pt' }} className="fa fa-quote-right"></i>
        </span>
      </>
    );
  };
  const renderDegreeItems = () => {
    let degreeItems: any = [];
    degrees
      .sort((a: number, b: number) => naturalSort(a, b))
      .forEach((degreeItem: ImmutableDegreeItem) => {
        if (degreeItem.get('id') > -1) {
          degreeItems.push(
            <div
              className="item testimonial-block"
              style={{
                borderLeftColor: '#343434',
                borderLeftWidth: '2px',
                borderLeftStyle: 'solid',
                backgroundColor: `${degreeItem.get('color')}`,
              }}
            >
              <div className="client-block" style={{ height: '600px' }}>
                <a href="pastor-details.html">
                  <h4 className="client-id" style={{ lineHeight: '1', marginTop: '0' }}>
                    {degreeItem.get('number')}
                  </h4>
                </a>
                <h5 className="client-id">{degreeItem.get('name')}</h5>
                <p className="client-post" style={{ paddingBottom: '0', lineHeight: '1.1' }}>
                  {renderInitiationFee(degreeItem.get('initiationFee'))}
                  <br />
                  {renderAnnualDues(degreeItem.get('annualDuesGL'))}
                  <br />
                  {renderMonthlyDues(degreeItem.get('monthlyDues'))}
                </p>
                {degreeItem.get('description').length > 0 ? renderQuote(degreeItem.get('description')) : null}
              </div>
            </div>
          );
        } else {
          degreeItems.push(
            <div className="item testimonial-block">
              <div className="client-block" style={{ height: '600px' }}></div>
            </div>
          );
        }
      });
    return degreeItems;
  };

  return (
    <div className="abt-content">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="abt-sectionOne">
            <SectionHeading Heading={getSiteCopyById('about.initiation.title')} />
            <SectionSubHeading Heading={getSiteCopyById('about.initiation.subtitle')} />
            <SectionBody Heading={getSiteCopyById('lapis.initiation.body')} />
          </div>
        </div>
      </div>
      <ImageAndTextLeft
        ButtonLink={null}
        ButtonText={null}
        Image="images/initiation/initiation2.jpg"
        HeaderText={getSiteCopyById('lapis.about.initiation.left-to-right.title')}
        BodyText={getSiteCopyById('lapis.about.initiation.left-to-right.body')}
      />
      <br />
      <section id="testimonial" className="testimonial-main-block">
        <div className="parallax" style={{ backgroundImage: "url('images/testimonial/testimonial-bg.jpg')" }}>
          <div className="overlay-bg"></div>

          <Carousel
            centerMode={true}
            centerSlidePercentage={33}
            showThumbs={false}
            autoPlay={false}
            infiniteLoop={true}
            showStatus={false}
            selectedItem={1}
          >
            {renderDegreeItems()}
          </Carousel>
        </div>
      </section>
      <br />
      <ImageAndTextRight
        ButtonLink={null}
        ButtonText={null}
        Image="images/initiation/initiation3.jpg"
        HeaderText={getSiteCopyById('lapis.about.initiation.right-to-left.title')}
        BodyText={getSiteCopyById('lapis.about.initiation.right-to-left.body')}
      />
      <br />
      <QuotesBlock Quotes={quoteData} />
      <br />
    </div>
  );
}
export default InitiationContent;

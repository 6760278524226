export const events = [
  // {
  //   id: 7,
  //   title: 'II degree initiations',
  //   summary: 'Initiation ceremony for candidates moving into the 2nd degree.',
  //   body: 'This is a private, members only, event.',
  //   startDate: '02/18/2023',
  //   image: 'images/upcoming/minerval.png',
  // },
  // {
  //   id: 8,
  //   title: 'Open House',
  //   summary: 'Come visit us at our temple space in Glendale discuss all things Thelema. ',
  //   body: 'Come visit us at our new temple space in Downtown Glendale (6831 N 58th Ave, Glendale AZ 85301, Rear of Masonic Lodge) and have a cup of tea or coffee and meet with local body members. Ask us questions, learn about Thelema, O.T.O., The Gnostic Mass ',
  //   startDate: '03/11/2023 2 - 4 PM',
  //   image: 'images/upcoming/coffee.jpg',
  // },
  // {
  //   id: 9,
  //   title: 'Gnostic Mass',
  //   summary: 'Come celebrate the Gnostic Mass with us at our new temple space in Glendale',
  //   body: 'Come celebrate the Gnostic Mass with us at our new temple space in Downtown Glendale (6831 N 58th Ave, Glendale AZ 85301, Rear of Masonic Lodge). Mass starts promptly at 3:00 PM and is a participatory ritual. $5.00 dontations are requested.',
  //   startDate: '03/12/2023 3 - 5 PM',
  //   image: 'images/upcoming/upcoming-01.png',
  // },
  // {
  //   id: 10,
  //   title: 'Open House',
  //   summary: 'Come visit us at our temple space in Glendale and discuss all things Thelema ',
  //   body: 'Come visit us at our new temple space in Downtown Glendale (6831 N 58th Ave, Glendale AZ 85301, Rear of Masonic Lodge) and have a cup of tea or coffee and meet with local body members. Ask us questions, learn about Thelema, O.T.O., The Gnostic Mass ',
  //   startDate: '04/08/2023 2 - 4 PM',
  //   image: 'images/upcoming/coffee.jpg',
  // },
  // {
  //   id: 11,
  //   title: 'Gnostic Mass',
  //   summary: 'Come celebrate the Gnostic Mass with us at our new temple space in Glendale',
  //   body: 'Come celebrate the Gnostic Mass with us at our new temple space in Downtown Glendale (6831 N 58th Ave, Glendale AZ 85301, Rear of Masonic Lodge). Mass starts promptly at 3:00 PM and is a participatory ritual. $5.00 dontations are requested.',
  //   startDate: '04/09/2023 3 - 5 PM',
  //   image: 'images/upcoming/upcoming-01.png',
  // },
  // {
  //   id: 12,
  //   title: 'Business Meeting',
  //   summary: 'Private Business meeting for Members of Lapis Lazuli Oasis',
  //   body: 'This is a private meeting for dues current initiate members of Lapis Lazuli Oasis. Come and discuss the future of the Oasis and see how you can help our mission.',
  //   startDate: '03/12/2023 5 - 6 PM',
  //   image: 'images/upcoming/business.jpg',
  // },
];

export const siteCopyList = [
  /* HOME */
  {
    id: 'home.title',
    text: 'Lapis Lazuli Oasis',
    onPage: 'home',
  },
  {
    id: 'home.subtitle',
    text: 'An Oasis of Ordo Templi Orientis in the Valley of Phoenix Arizona',
    onPage: 'home',
  },
  {
    id: 'home.body',
    text: `Do what thou wilt shall be the whole of the Law. <br /><br />Lapis Lazuli Oasis is an official local body of Ordo Templi Orientis (O.T.O.) located in Phoenix, Arizona, USA. The body was chartered by the National Grand Master General, Sabazius X° on the 24th of January, 2005.
    <br /><br />
    Lapis Lazuli Oasis also operates under the auspices of Ecclesia Gnostica Catholica, the ecclesiastical rite of O.T.O., and hosts regular performances of Liber XV, the Gnostic Catholic Mass. The purpose of the Mass is to “administer the virtues to the brethren,” to its members and interested public. We occasionally perform the rites of Baptism, Confirmation, and Ordination to those that meet the E.G.C. requirements.
    <br /><br />
    The Oasis recognizes and honors the Rituals of the Elements and Feasts of the Times throughout the year. Additionally, many classes with guest speakers, and workshops on Thelemic thought and magical practices are offered.
    <br />
    <br />
    Love is the law, love under will.`,
    onPage: 'home',
  },
  {
    id: 'home.activities.heading',
    text: 'Lapis Lazuli Activities',
    onPage: 'home',
  },
  {
    id: 'home.activities.body',
    text: 'Here are just a few of the activities we offer for our community.',
    onPage: 'home',
  },
  /* 
    
    DUES
    
    */
  {
    id: 'dues.title',
    text: 'Dues for Members',
    onPage: 'dues',
  },
  {
    id: 'dues.heading',
    text: 'As a non-profit organization, Lapis Lazuli Oasis relies solely upon the dues and donations of its members and supporters.',
    onPage: 'dues',
  },
  {
    id: 'dues.body',
    text: 'As an initiated member of O.T.O. you are required to pay a yearly amount of dues to USGL, however, as a member of a local body such as Lapis Lazuli Oasis, you are also required to pay monthly dues to support the body. Presently Lapis Lazuli Oasis requests $10.00 per month in dues from all Minerval degree members, but all others are a flat dues structure of $25.00 per month.',
    onPage: 'dues',
  },
  {
    id: 'fues.questions.heading',
    text: 'Questions about your Dues?',
    onPage: 'dues',
  },
  {
    id: 'dues.questions.body',
    text: "Please reach out directly to the local treasurer at: <a href='mailto:lapislazuli.treasurer@oto-usa.org'>lapislazuli.treasurer@oto-usa.org</a> and ask your specific questions about your dues.",
    onPage: 'dues',
  },
  /* 
    
    DONATIONS
    
    */
  {
    id: 'donations.title',
    text: 'Donations',
    onPage: 'donations',
  },
  {
    id: 'donations.heading',
    text: 'As a non-profit organization, Lapis Lazuli Oasis relies solely upon the dues and donations of its members and supporters.',
    onPage: 'donations',
  },
  {
    id: 'donations.body',
    text: 'Your donations will ensure the continued mission of Lapis Lazuli Oasis in the Phoenix Valley. Your donations are applied to general operations of the Order and our outreach.',
    onPage: 'donations',
  },
  {
    id: 'donations.questions.heading',
    text: 'Questions about your Donations?',
    onPage: 'donations',
  },
  {
    id: 'donations.questions.body',
    text: "Please reach out directly to the local treasurer at: <a href='mailto:lapislazuli.treasurer@oto-usa.org'>lapislazuli.treasurer@oto-usa.org</a> and ask your specific questions about your donations.",
    onPage: 'donations',
  },
  {
    id: 'donations.paypal.title',
    text: 'Donations Welcome',
    onPage: 'donations',
  },
  {
    id: 'donations.paypal.body',
    text: 'These donations blah blach ',
    onPage: 'donations',
  },
  {
    id: 'donations.smile.heading',
    text: 'Support us on Amazon Smile',
    onPage: 'donations',
  },
  {
    id: 'donations.smile.body',
    text: 'You can link us up in your Amazon account and we will recieve 0.5% of evey dollar you spend on Amazon.',
    onPage: 'donations',
  },
  /* 
    
    ABOUT OTO

    */
  {
    id: 'about.oto.heading',
    text: 'Ordo Templi Orientis',
    onPage: 'about/oto',
  },
  {
    id: 'about.oto.body',
    text: `The letters O.T.O. stand for Ordo Templi Orientis, the Order of Oriental Templars, or Order of the Temple of the East. <br />
    O.T.O. is dedicated to the high purpose of securing the Liberty of the Individual and his or her advancement in Light, Wisdom, Understanding, Knowledge, and Power through Beauty, Courage, and Wit, on the Foundation of Universal Brotherhood.<br />
    <br />
    Ordo Templi Orientis is the first of the great Old Æon orders to accept The Book of the Law, received by Aleister Crowley in 1904 EV. <br />
    This book proclaims a New Æon in human thought, culture and religion. The Æon arises from a single supreme injunction: the Law of Thelema, which is Do what thou wilt.
    <br /><br />
    U.S. Grand Lodge is the governing body of O.T.O. in the United States. It is the most populous and active branch of O.T.O., with many local bodies spread throughout the country.
    <br /><br />
    Ordo Templi Orientis has two constituent rites, Mysteria Mystica Maxima and Ecclesia Gnostica Catholica.
    <br /><br />
    Mysteria Mystica Maxima (M∴M∴M∴) is the initiatory arm of O.T.O.
    <br /><br />
    Ecclesia Gnostica Catholica (E.G.C.), the Gnostic Catholic Church, is the ecclesiastical arm of O.T.O. It confers sacraments including the eucharist of the Gnostic Mass, baptism, confirmation, and ordination.
    <br /><br />
    Many O.T.O. events are open to the public. Those who wish to learn more about O.T.O. are encouraged to attend our public events. 
    <br />
    Attendance at a celebration of the Gnostic Mass is a particularly good choice for newcomers, as it provides an excellent introduction to our symbolism and philosophy.`,
    onPage: 'about/oto',
  },
  /* 
    
    ABOUT AA
    
    */
  {
    id: 'about.aa.heading',
    text: 'A∴ A∴',
    onPage: 'about/aa',
  },
  {
    id: 'about.aa.body',
    text: `O.T.O. has long worked in close alliance with the A∴A∴, which first proclaimed the Law of Thelema to the world. <br />The A∴A∴ is a teaching and initiatory system dedicated to the personal spiritual advancement of its individual members.<br />Within A∴A∴ all services are rendered free of charge, and no social activities are held.<br /> <br />O.T.O. and A∴A∴ have jointly issued the journal The Equinox since 1912 ev, now in its fourth volume.<br />Although they are distinctly separate organizations, O.T.O. has historically assisted A∴A∴ with practical matters that lie outside the latter’s primary mission, which is purely spiritual in nature. <br /><br />Aspirants to the A∴A∴ may write to:
    <br />Chancellor<br />
    BM ANKH<br />
    London WC1N 3XX ENGLAND
    <br /><br />
    Email contact: <a href='mailto:secretary@outercol.org'>Secretary</a>
    <br /><br />
    For more information: <a target='_blank' href='http://www.outer-college.org'>http://www.outer-college.org</a>`,
    onPage: 'about/aa',
  },
  /* 
    
    LIBRARY
    
    */
  {
    id: 'library.title',
    text: 'Our Online Library',
    onPage: 'library',
  },
  {
    id: 'library.subtitle',
    text: 'An assorted collection of Holy Books of Thelema and other documents',
    onPage: 'library',
  },
  {
    id: 'library.body',
    text: 'Here you will find...',
    onPage: 'library',
  },
  /*
  DOWNLOADS
    
  */
  {
    id: 'downloads.title',
    text: 'Free Downloads',
    onPage: 'Downloads',
  },
  {
    id: 'downloads.subtitle',
    text: 'A collection of various downloads available for you to download for free.',
    onPage: 'Downloads',
  },
  {
    id: 'downloads.body',
    text: 'Here you will find documents, mp3 files and other resources.',
    onPage: 'Downloads',
  },

  /*

ABOUT INITIATIONS
*/

  {
    id: 'about.initiation.title',
    text: 'Initiation into O.T.O.',
    onPage: 'about initiations',
  },
  {
    id: 'about.initiation.subtitle',
    text: 'O.T.O. welcomes all Men and Women 18 years or older, free (not incarcerated) and of good report',
    onPage: 'about initiations',
  },
  {
    id: 'lapis.initiation.body',
    text: `Membership in O.T.O. is achieved by a process of progressive initiation rites performed under the auspice of M∴M∴M∴ (Mysteria Mystica Maxima). While we are distinctively not a form of Freemasonry, our initiation rituals bear some similarities to those found in certain rites within Masonic systems. These rituals are dramaturgical in nature, meaning that they put the candidate for initiation through a series of dramatic moral and ethical lessons.   One of the best ways to explain the process of dramatic initiation is to imagine being led through a play, in which you are the star, but you just don't know the lines. For this reason, our initiations are secret. <br /><br />O.T.O. initiations are conferred through a physical ceremony conducted by a chartered initiator. Initiations are almost always held at O.T.O. local bodies, most commonly Oases and Lodges. The introductory initiation into O.T.O. is known as the "Minerval" or 0°. <br /><br /> Therefore, the path to becoming an initiate member of O.T.O. involves contacting a nearby or otherwise convenient local body which conducts Minerval initiations (see our Locations page), obtaining an application for initiation from that body, and submitting it along with the appropriate dues and fees. <br /><br /> In order to establish "good report", your application must be signed by two sponsors who are already I° initiates of O.T.O. Prospective sponsors will generally want to get to know you before signing your application, which may involve your attendance with them at several local body events, or time spent with them privately.`,
    onPage: 'about initiations',
  },
  {
    id: 'lapis.about.initiation.left-to-right.title',
    text: 'Degrees of O.T.O.',
    onPage: 'about initiations',
  },
  {
    id: 'lapis.about.initiation.left-to-right.body',
    text: 'Initiate membership is subdivided by degree. There are a total of 21 initiate degrees in O.T.O., including thirteen numbered degrees and eight un-numbered, intermediate degrees or sub-degrees. The degrees of O.T.O. are divided into three Grades or "Triads": the Hermit, the Lover, and the Man of Earth, as follows: <br />',
    onPage: 'about initiations',
  },

  {
    id: 'lapis.about.initiation.right-to-left.title',
    text: 'Next Steps',
    onPage: 'about initiations',
  },
  {
    id: 'lapis.about.initiation.right-to-left.body',
    text: 'If you feel like initiation into O.T.O. may be the right path for you, we encourage you to reach out to us and come meet us in person. If you have not already done so, come attend a <a href="egc">Gnostic Mass</a> or other social events and get to know some of our members in person. Additionally, you may also email our <a href="mailto:lapislazuli.initiation@oto-usa.org?Subject=Initiation%20questions">Initiation Secretary</a> for an application to become part of the order. Again, you will need to be of full legal age (18 in the US), free (not incarcerated), and of good report (meaning you will need 2 full members of at least first degree to sponsor and vouch for you) ',
    onPage: 'about initiations',
  },
  /*
  ABOUT MASONRY
    
  */

  {
    id: 'about.masonry.title',
    text: 'O.T.O. and Masonry',
    onPage: 'about masonry',
  },
  {
    id: 'about.masonry.subtitle',
    text: 'A clear differentiation between fraternities.',
    onPage: 'about masonry',
  },
  {
    id: 'lapis.masonry.body',
    text: `Although, in the past, O.T.O. has historically been associated with various rites of Freemasonry, it must be definitively stated and understood that O.T.O. membership does not, of itself, confer any status whatsoever in Freemasonry.
    Over one hundred years ago, when O.T.O. was established in Germany, it was closely allied to several rites of European Freemasonry. However, in 1918, Aleister Crowley determined it appropriate for O.T.O. to assert its own unique identity as an independent system. At that time, while retaining the use of certain convenient customs and terminology used in early Freemasonry, Crowley revised the O.T.O. rituals, insignia and modes of recognition to avoid infringing upon the legitimate privileges of the established and recognized authorities of modern Freemasonry, and also to specifically reflect the teachings of Thelema. O.T.O. has not claimed to "make Masons" since that time. 
<br /> <br />
Further revisions along similar lines have been implemented in more recent years.
    Despite some similarities between names and titles used within O.T.O. and the names and titles used in Masonry, various churches, and other organizations, conferral of any degree, rank, office, or status within O.T.O. does not constitute conferral of any degree, rank, office, or status in any other active organization, Masonic, religious, or otherwise; any more than status as the captain of a ship equates to status as the captain of a football team.
    It should, however, also be recognized and duly noted that though no formal relationship exists between these two august Fraternities, that they do in fact share one very distinct and important commonality between them; the shared Vision of Universal Freedom and the Brotherhood of Mankind. <br /><br />May this shared
    vision for humanity serve to facilitate a richer understanding of each other and our respective organizations and bring us closer together in common purpose.`,
    onPage: 'about masonry',
  },

  /*
  ABOUT LAPIS
    
  */
  {
    id: 'about.lapis.title',
    text: 'About Lapis Lazuli Oasis',
    onPage: 'about us',
  },
  {
    id: 'about.lapis.subtitle',
    text: 'We are a chartered body of Ordo Templi Orientis (O.T.O.) serving the valley of Phoenix AZ',
    onPage: 'about us',
  },
  {
    id: 'lapis.about.body',
    text: 'Chartered in 2005, Lapis Lazuli Oasis has been promulgating the Law of Thelema and serving the Greater Phoenix community with the rites of Initiation, celebration of the Gnostic Mass, classes and lectures, and community outreach. Our core mission is to raise awareness of the Law of Thelema in our community and, much like a ‘stellar nursery’, provide all the tools needed for infant stars to give birth to themselves. Our community membership spans all walks of life and we welcome all with open arms. Though times have been challenging over the last couple of years with Covid-19, we are now fully open again and performing public rituals and meetings.',
    onPage: 'about us',
  },

  {
    id: 'lapis.about.left-to-right.title',
    text: 'Gnostic Mass',
    onPage: 'about us',
  },
  {
    id: 'lapis.about.left-to-right.body',
    text: 'The Gnostic Mass is a celebration of the miracle of incarnation whereby both Priest and Priestess focus their magical energy upon a sacrament. This magical eucharistic ritual is a supreme celebration of life and the elements and invites each congregant to participate and focus on their aspiration. Read more about the Gnostic Mass and other rituals of the EGC <a href="egc">HERE</a>',
    onPage: 'about us',
  },

  {
    id: 'lapis.about.right-to-left.title',
    text: 'Initiation',
    onPage: 'about us',
  },
  {
    id: 'lapis.about.right-to-left.body',
    text: 'We celebrate the initiation rites of the M∴M∴M∴ (Mysteria Mystica Maxima) starting with the initial degree of Minerval. From there, the candidate is passed through a series of rituals that illustrate certain moral and ethical behaviors appropriate for the new Æon and the Law of Thelema. Read more about our initiation structure <a href="initiations">HERE</a>',
    onPage: 'about us',
  },

  /*
  ABOUT EGC
    
  */
  {
    id: 'about.egc.title',
    text: 'Ecclesia Gnostica Catholica (Gnostic Catholic Church)',
    onPage: 'about egc',
  },
  {
    id: 'about.egc.subtitle',
    text: 'The ecclesiastical rite of Ordo Templi Orientis.',
    onPage: 'about egc',
  },
  {
    id: 'lapis.egc.body',
    text: 'E.G.C. is a Thelemic religious environment dedicated to the advancement of Light, Life, Love, and Liberty through alignment with the Law of Thelema. The central activity of E.G.C. is the celebration of the Gnostic Mass, as set forth in Liber XV, written by Aleister Crowley for O.T.O. and E.G.C. in 1913. Lay membership in E.G.C. is available through baptism and confirmation. Members of O.T.O. in good standing are eligible for clerical ordination in E.G.C. Members of II° and higher are eligible for ordination as Deacon, and those who have reached K.E.W. are eligible for ordination as Priest, Priestess, or Deacon-Sacerdote. Many O.T.O. local bodies celebrate the Gnostic Mass on a regular basis. In most locations, no formal affiliation is required to attend the Mass.',
    onPage: 'about egc',
  },

  {
    id: 'lapis.egc.left-to-right.title',
    text: 'Gnostic Mass',
    onPage: 'about us',
  },
  {
    id: 'lapis.egc.left-to-right.body',
    text: 'The Gnostic Mass is a celebration of the miracle of incarnation whereby both Priest and Priestess focus their magical energy upon a sacrament. This magical eucharistic ritual is a supreme celebration of life and the elements and invites each congregant to participate and focus on their aspiration. Read more about the Gnostic Mass and other rituals of EGC <a href="egc">HERE</a>',
    onPage: 'about us',
  },

  {
    id: 'lapis.egc.right-to-left.title',
    text: 'Confirmation',
    onPage: 'about us',
  },
  {
    id: 'lapis.egc.right-to-left.body',
    text: 'The Gnostic Mass is a celebration of the miracle of incarnation whereby both Priest and Priestess focus their magical energy upon a sacrament. This magical eucharistic ritual is a supreme celebration of life and the elements and invites each congregant to participate and focus on their aspiration. Read more about the Gnostic Mass and other rituals of EGC <a href="egc">HERE</a>',
    onPage: 'about us',
  },

  {
    id: 'lapis.egc.left-to-right2.title',
    text: 'Baptism',
    onPage: 'about us',
  },
  {
    id: 'lapis.egc.left-to-right2.body',
    text: 'The Gnostic Mass is a celebration of the miracle of incarnation whereby both Priest and Priestess focus their magical energy upon a sacrament. This magical eucharistic ritual is a supreme celebration of life and the elements and invites each congregant to participate and focus on their aspiration. Read more about the Gnostic Mass and other rituals of EGC <a href="egc">HERE</a>',
    onPage: 'about us',
  },

  {
    id: 'lapis.egc.right-to-left2.title',
    text: 'Other Rites',
    onPage: 'about us',
  },
  {
    id: 'lapis.egc.right-to-left2.body',
    text: 'The Gnostic Mass is a celebration of the miracle of incarnation whereby both Priest and Priestess focus their magical energy upon a sacrament. This magical eucharistic ritual is a supreme celebration of life and the elements and invites each congregant to participate and focus on their aspiration. Read more about the Gnostic Mass and other rituals of EGC <a href="egc">HERE</a>',
    onPage: 'about us',
  },

  /*
CONTACT
*/
  {
    id: 'lapis.contact.header',
    text: 'Please use the form below to contact us with any questions you may have.',
    onPage: 'contact us',
  },

  /*
  ABOUT THELEMA
    
  */
  {
    id: 'about.thelema.title',
    text: 'THELEMA',
    onPage: 'about thelema',
  },
  {
    id: 'about.thelema.subtitle',
    text: 'A law of the new Aeon',
    onPage: 'about thelema',
  },
  {
    id: 'lapis.thelema.body',
    text: `Thelema (/θəˈliːmə/) is a social or spiritual philosophy derived from Western esotericism. While it is most often regarded as a religion—a new religious movement in particular—it is also referred to as a philosophy, "religious philosophy", "spiritual philosophy", or "religious matrix".
    <br /><br />
    The fundamental principle underlying Thelema, known as the "law of Thelema", is "Do what thou wilt shall be the whole of the Law. Love is the law, love under will." This phrase can be, and usually is, interpreted universally, having moral, mystical, and socio-political implications.
    <br /><br />
    Thelema was developed in the early 1900s by Aleister Crowley, an English writer, mystic, and ceremonial magician.[1] He believed himself to be the prophet of a new age, the Æon of Horus, based upon a spiritual experience that he and his wife, Rose Edith, had in Egypt in 1904.[2] By his account, a possibly non-corporeal or "praeterhuman" being that called itself Aiwass contacted him and dictated a text known as The Book of the Law or Liber AL vel Legis, which outlined the principles of Thelema.[3] An adherent of Thelema is a Thelemite. 
    <br /><br />
    We always advise people interested in us to acquaint themselves with the Book of the Law, which is the central holy text of Thelema and the Gnostic Mass, which is the central public and private ritual of Ordo Templi Orientis.
    <br /><br />
    We encourage those interested in familiarizing themselves with our mysteries to attend a public celebration of the Gnostic Mass. We have a lively, diverse, and welcoming community and attending a public event, such as a Gnostic Mass, is a great way to get to know us.
    <br /><br />
    We understand and appreciate some of the difficulties that a seeker may face when first approaching Thelema. The body of Aleister Crowley’s work is extensive, often technical, and not always accessible. A common question that we have often received concerns where to begin. We have put together a list of introductory documents to assist those interested in learning more about Thelema and O.T.O.
    <br /><br />
    For some Introductory material regarding Thelema, we recommend:
    <br /><br />
    <a href="http://www.lapislazuli-oto.org/library">The Message of the Master Therion</a> - Explains the essence of the new Law in a very simple manner.
    <br /><br />
    <a href="http://www.lapislazuli-oto.org/library">Concerning the Law of Thelema</a>
    <br /><br />
    <a href="http://www.lapislazuli-oto.org/library">The Law of Liberty</a> - This is a further explanation of the Book of the Law in reference to certain ethical problems.
    <br /><br />
    Ordo Templi Orientis “is the first of the great Old Æon orders to accept The Book of the Law” and confers initiation through physical ceremonies designed to instruct initiates by allegory and symbol in the profound mysteries of nature, and thereby to assist each initiate in discovering their own True Will. `,
    onPage: 'about thelema',
  },
];

/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from 'react-redux';

export const siteAddress = 'http://www.lapislazuli-oto.org/';
export const safeHtml = (stringData: string) => {
  return <div dangerouslySetInnerHTML={{ __html: stringData }}></div>;
};

export const getSiteCopyById = (id: string) => {
  const siteCopyList = useSelector((state: any) => {
    return state.siteCopy;
  });
  return siteCopyList.get(id)?.get('text') ? siteCopyList.get(id).get('text') : '';
};
function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const getRandomBackground = () => {
  let bgs = [
    'url("images/scarletwomen.jpg")',
    'url("images/legisbs.jpg")',
    'url("images/thothbg2.jpg")',
    'url("images/crowley1.jpg")',
    'url("images/booksbg.jpg")',
  ];
  return bgs[getRandomInt(bgs.length)];
};

import Immutable from 'immutable';
import { combineReducers } from 'redux';

import AAGrades from '../../data/Questions/10/AAGrades.json';
import Animals from '../../data/Questions/10/Animals.json';
import Archangels from '../../data/Questions/10/Archangels.json';
import ArchdemonsOfTheQliphoth from '../../data/Questions/10/ArchdemonsOfTheQliphoth.json';
import BodyOfGod from '../../data/Questions/10/BodyOfGod.json';
import ChemicalElementsGoldenDawn from '../../data/Questions/10/ChemicalElementsGoldenDawn.json';
import ChoirsOfAngels from '../../data/Questions/10/ChoirsOfAngels.json';
import Diseases from '../../data/Questions/10/Diseases.json';
import DukesOfEdom from '../../data/Questions/10/DukesOfEdom.json';
import EgyptianSoul from '../../data/Questions/10/EgyptianSoul.json';
import ElementsAndQuarters from '../../data/Questions/10/ElementsAndQuarters.json';
import EmperorsScaleColor from '../../data/Questions/10/EmperorsScaleColor.json';
import EmpressesScaleColor from '../../data/Questions/10/EmpressesScaleColor.json';
import EtruscianGods from '../../data/Questions/10/EtruscianGods.json';
import FourtyBuddhistMeditations from '../../data/Questions/10/FourtyBuddhistMeditations.json';
import GnosticGods from '../../data/Questions/10/GnosticGods.json';
import GreekGods from '../../data/Questions/10/GreekGods.json';
import GreekMuses from '../../data/Questions/10/GreekMuses.json';
import HebrewSaints from '../../data/Questions/10/HebrewSaints.json';
import HinduGods from '../../data/Questions/10/HinduGods.json';
import HinduSoul from '../../data/Questions/10/HinduSoul.json';
import IslamicAngels from '../../data/Questions/10/IslamicAngels.json';
import KingsOfEdom from '../../data/Questions/10/KingsOfEdom.json';
import KingsScaleColor from '../../data/Questions/10/KingsScaleColor.json';
import KnightsOfTheRoundTable from '../../data/Questions/10/KnightsOfTheRoundTable.json';
import MagicalBirds from '../../data/Questions/10/MagicalBirds.json';
import MagicalBirdsFormula from '../../data/Questions/10/MagicalBirdsFormula.json';
import MagicalFormulae from '../../data/Questions/10/MagicalFormulae.json';
import MagicalPowers from '../../data/Questions/10/MagicalPowers.json';
import MasonicOfficers from '../../data/Questions/10/MasonicOfficers.json';
import MesopotamianGods from '../../data/Questions/10/MesopotamianGods.json';
import MysticNumbers from '../../data/Questions/10/MysticNumbers.json';
import OrderOfQliphoth from '../../data/Questions/10/OrderOfQliphoth.json';
import OrdersOfQliphothEnglish from '../../data/Questions/10/OrdersOfQliphothEnglish.json';
import OrphicGods from '../../data/Questions/10/OrphicGods.json';
import PersianGods from '../../data/Questions/10/PersianGods.json';
import PreciousStones from '../../data/Questions/10/PreciousStones.json';
import QueensScaleColor from '../../data/Questions/10/QueensScaleColor.json';
import RomanGods from '../../data/Questions/10/RomanGods.json';
import ScandinavianGods from '../../data/Questions/10/ScandinavianGods.json';
import SeferYetzirah from '../../data/Questions/10/SeferYetzirah.json';
import ShintoGods from '../../data/Questions/10/ShintoGods.json';
import SumerialGods from '../../data/Questions/10/SumerialGods.json';
import TaoistGods from '../../data/Questions/10/TaoistGods.json';
import TenCommandmends from '../../data/Questions/10/TenCommandmends.json';
import TenEgyptianGods from '../../data/Questions/10/TenEgyptianGods.json';
import TenHells from '../../data/Questions/10/TenHells.json';
import TenMagicalWeapons from '../../data/Questions/10/TenMagicalWeapons.json';
import TenPerfumes from '../../data/Questions/10/TenPerfumes.json';
import TenPlagues from '../../data/Questions/10/TenPlagues.json';
import TenSpheres from '../../data/Questions/10/TenSpheres.json';
import TranscendentalMorality from '../../data/Questions/10/TranscendentalMorality.json';
import VegetableDrugs from '../../data/Questions/10/VegetableDrugs.json';

const tens = combineReducers({
  aaGrades: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        AAGrades.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  animals: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Animals.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  archangels: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Archangels.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  archdemonsOfTheQliphoth: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ArchdemonsOfTheQliphoth.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  bodyOfGod: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        BodyOfGod.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  chemicalElementsGoldenDawn: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ChemicalElementsGoldenDawn.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  choirsOfAngels: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ChoirsOfAngels.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  diseases: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Diseases.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  dukesOfEdom: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        DukesOfEdom.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  egyptianSoul: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EgyptianSoul.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  elementsAndQuarters: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ElementsAndQuarters.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  emperorsScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EmperorsScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  empressesScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EmpressesScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  etruscianGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EtruscianGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  fourtyBuddhistMeditations: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        FourtyBuddhistMeditations.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  gnosticGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GnosticGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  greekGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GreekGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  greekMuses: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GreekMuses.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewSaints: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewSaints.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hinduGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HinduGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hinduSoul: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HinduSoul.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  islamicAngels: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        IslamicAngels.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  kingsOfEdom: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        KingsOfEdom.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  kingsScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        KingsScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  knightsOfTheRoundTable: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        KnightsOfTheRoundTable.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  magicalBirds: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MagicalBirds.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  magicalBirdsFormula: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MagicalBirdsFormula.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  magicalFormulae: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MagicalFormulae.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  magicalPowers: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MagicalPowers.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  masonicOfficers: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MasonicOfficers.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  mesopotamianGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MesopotamianGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  mysticNumbers: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        MysticNumbers.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  orderOfQliphoth: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        OrderOfQliphoth.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  ordersOfQliphothEnglish: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        OrdersOfQliphothEnglish.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  orphicGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        OrphicGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  persianGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PersianGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  preciousStones: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PreciousStones.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  queensScaleColor: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        QueensScaleColor.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  romanGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        RomanGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  scandinavianGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ScandinavianGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  seferYetzirah: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        SeferYetzirah.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  shintoGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ShintoGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  sumerianGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        SumerialGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  taoistGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TaoistGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tenCommandments: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TenCommandmends.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tenEgyptianGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TenEgyptianGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tenHells: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TenHells.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tenMagicalWeapons: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TenMagicalWeapons.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tenPerfumes: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TenPerfumes.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tenPlagues: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TenPlagues.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tenSpheres: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TenSpheres.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  transcendentalMorality: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TranscendentalMorality.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  vegetableDrugs: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        VegetableDrugs.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
});

export default tens;

import React from 'react';
import InitiationContent from '../components/InitiationContent';
import PageBanner from '../components/PageBanner';
import { getRandomBackground } from '../shared/utilities';
function InitiationView() {
  return (
    <>
      <PageBanner BannerTitle="About Initiation" BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ backgroundImage: getRandomBackground() }}>
        <section id="about-block" className="about-main-block abt-two" style={{ marginTop: '0px' }}>
          <div className="container" style={{ backgroundColor: '#ffffff' }}>
            <div className="abt-img">
              <br />
              <img src="images/initiation/initiation1.jpg" className="img-responsive" alt="abt-img-1" />
            </div>

            <InitiationContent />
          </div>
        </section>
      </div>
    </>
  );
}
export default InitiationView;

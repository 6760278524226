import React from 'react';
import { safeHtml } from '../shared/utilities';
import { MainBlockProps } from '../types';
function MainBlock(props: MainBlockProps) {
  const getBackground = () => {
    if (props.BackgroundImage.length > 0) {
      return { backgroundImage: props.BackgroundImage };
    } else {
      return { backgroundColor: props.BackgroundColor };
    }
  };
  return (
    <section id="about" className="about-main-block" style={getBackground()}>
      <div className="container-fluid">
        <div className="row row-spacing" style={{ textAlign: 'left' }}>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="about-img">
              <img src={props.Image} className="img-responsive" alt="about-img" />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="about-content" style={{ marginTop: props.TopMargin }}>
              <h3 className="about-heading">{props.Title}</h3>
              <h5 className="about-subheading">{props.SubHeading}</h5>
              <div className="about-dtl">{safeHtml(props.Content)}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default MainBlock;

import { off } from 'process';
import React from 'react';
import { useSelector } from 'react-redux';
import Officer from '../components/Officer';
import PageBanner from '../components/PageBanner';
import { getRandomBackground } from '../shared/utilities';
function OfficersView() {
  const officerList = useSelector((state: any) => {
    return state.officers;
  });

  const renderOfficers = () => {
    let retVal: any = [];
    officerList.forEach((officer: any) => {
      retVal.push(
        <Officer
          key={officer.get('key')}
          Name={officer.get('Name')}
          Title={officer.get('Title')}
          Email={officer.get('Email')}
          Photo={officer.get('Photo')}
        />
      );
    });
    return retVal;
  };
  return (
    <>
      <PageBanner BannerTitle="Lapis Lazuli Officers" BannerImage={`url('images/top-banner.jpg')`} />
      <section
        id="pastor-gallery"
        className="pastor-gallery-main-block"
        style={{ backgroundImage: getRandomBackground() }}
      >
        <div className="container">{renderOfficers()}</div>
      </section>
    </>
  );
}
export default OfficersView;

import React from 'react';
import { siteAddress } from '../shared/utilities';
function Header() {
  return (
    <head>
      <title>Church - HTML Template</title>
      <meta charSet="utf-8" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta name="description" content="Church - HTML Template" />
      <meta name="keywords" content="Lapis Lazuli Oasis, O.T.O., Thelema, Gnostic Mass" />
      <meta name="author" content="wp-organic" />
      <meta name="MobileOptimized" content="320" />
      <link rel="icon" type="image/icon" href={`${siteAddress}images/favicon/favicon.ico`} />
      <link href={`${siteAddress}css/bootstrap.min.css`} rel="stylesheet" type="text/css" />
      <link href={`${siteAddress}css/font-awesome.min.css`} rel="stylesheet" type="text/css" />
      <link href={`${siteAddress}css/flaticon.css`} rel="stylesheet" type="text/css" />
      <link
        href="https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,900|Source+Sans+Pro:300,400,600,700"
        rel="stylesheet"
      />
      <link href={`${siteAddress}css/menumaker.css`} rel="stylesheet" type="text/css" />
      <link href={`${siteAddress}css/magnific-popup.css`} rel="stylesheet" type="text/css" />
      <link href={`${siteAddress}css/datepicker.css`} rel="stylesheet" type="text/css" />
      <link href={`${siteAddress}css/style.css`} rel="stylesheet" type="text/css" />
    </head>
  );
}
export default Header;

//import unauthenticatedClient from '../../client';
import axios from 'axios';
import unauthenticatedClient from '../../client';
export const EXPORT_SET_DATA = 'EXPORT_SET_DATA';
export const USERS_ERROR = 'USERS_ERROR';

export const updateSetData = payload => async dispatch => {
  const { userId, setId, amount } = { ...payload };
  const axoisConfig = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `${unauthenticatedClient.defaults.url}lapisUser/score`,
      {
        userId: userId,
        setId: setId,
        amount: amount,
      },
      axoisConfig
    );
    dispatch({
      type: EXPORT_SET_DATA,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: USERS_ERROR,
      payload: console.log(e),
    });
  }
};

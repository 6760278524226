export const homeSliderList = [
  {
    id: 1,
    image: `url('images/mass/mass1.jpg')`,
    heading: 'Lapis Lazuli Oasis<br />Gnostic Mass',
    countdownDate: '2022/04/01',
    link: 'events',
    subHeading:
      'Join us in a celebration of life and the elements and experience for yourself the magical eucharist of the Gnostic Mass. See our Events for a list of upcoming celebrations.',
  },
  {
    id: 2,
    image: `url('images/aa/Legis1.jpg')`,
    heading: 'The Book of the Law',
    countdownDate: '2022/04/01',
    subHeading:
      'Written in April of 1904 this work is the foundational text that forms the basis of the Law of Thelema. ',
    link: 'library',
  },
  {
    id: 3,
    image: `url('images/temple/temple4.jpg')`,
    heading: 'Initiation',
    countdownDate: '2022/04/01',
    link: 'initiation',
    subHeading: 'Initiation into O.T.O. marks the starting point of your journey into our mysteries.',
  },
];

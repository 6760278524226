import Immutable from 'immutable';
import { libraryItems } from '../../data/library';

const library = (state = new Immutable.Map(), action) => {
  return state.merge(
    Immutable.fromJS(
      libraryItems.reduce((map, property) => {
        map[property.id] = property;
        return map;
      }, {})
    )
  );
};

export default library;

import Immutable from 'immutable';
import { combineReducers } from 'redux';

import SetData from '../../data/Questions/sets';
const sets = combineReducers({
  setData: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        SetData.reduce((map, property) => {
          map[property.setID] = property;
          return map;
        }, {})
      )
    );
  },
});

export default sets;

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { TabBlockItem, TabBlockItemsProps } from '../types';
import { Link } from 'react-router-dom';

function TabBlock(props: TabBlockItemsProps) {
  const renderItems = () => {
    let items: any = [];
    props.Items.forEach((tabBlockItem: TabBlockItem) => {
      items.push(
        <div key={tabBlockItem.id} className="col-lg-3 col-md-2 col-sm-4 col-xs-12">
          <div className="abt-tab-item">
            <Link to={tabBlockItem.Link}>
              <div className="abt-tab-icon">
                <i className={tabBlockItem.Image}></i>
              </div>
              <h6 className="abt-icon-name">{tabBlockItem.Title}</h6>
            </Link>
          </div>
        </div>
      );
    });
    return items;
  };
  return (
    <section id="abt-tab" className="tab-main-block">
      <div className="parallax" style={{ backgroundImage: `url('images/bg-img.jpg')` }}>
        <div className="overlay-bg"></div>
        <div className="abt-tab-block">
          <div className="container">
            <div className="abt-tab-content text-center">
              <h3 className="abt-tab-heading">{props.Title}</h3>
              <h5 className="abt-tab-subheading">{props.SubTitle}</h5>
            </div>
            <div className="abt-tab-icons">
              <div className="row">{renderItems()}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default TabBlock;

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
function FourOFour() {
  return (
    <header id="error-banner" className="banner-main-block error-pg">
      <div className="bg-img" style={{ backgroundImage: `url('images/error-01.jpg')` }}>
        <div className="overlay-bg"></div>
        <div className="container">
          <div className="error-block">
            <h1 className="error-headingOne">404</h1>
            <h3 className="error-headingTwo">The page you requested is not here</h3>

            <div className="go-back-btn">
              <a href="/" className="btn btn-default">
                Go back to home page
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default FourOFour;

import React from 'react';
import { safeHtml } from '../shared/utilities';
import { HomeSliderProps, ImmutableHomeSliderItem } from '../types';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';

function HomeSlider(props: HomeSliderProps) {
  const getImageHeight = () => {
    let height = '100vh';
    if (window.innerWidth <= 500) {
      height = '30vh';
    }
    return height;
  };
  const renderItems = () => {
    let sliderItems: any = [];
    props.items.forEach((sliderIem: ImmutableHomeSliderItem) => {
      sliderItems.push(
        <div
          key={sliderIem.get('id')}
          style={{
            height: getImageHeight(),
            backgroundPosition: '50% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            backgroundImage: sliderIem.get('image'),
          }}
        >
          <div className="container">
            <div className="slider-dtl">
              <Link to={sliderIem.get('link')}>
                <h1 style={{ cursor: 'pointer' }} className="slider-heading">
                  {safeHtml(sliderIem.get('heading'))}
                </h1>
                <h5 className="slider-subheading">{safeHtml(sliderIem.get('subHeading'))}</h5>
              </Link>
            </div>
          </div>
        </div>
      );
    });
    return sliderItems;
  };
  return (
    <div style={styles.scrollContainer}>
      <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}>
        {renderItems()}
      </Carousel>
    </div>
  );
}
const styles = {
  scrollContainer: {},
};
export default HomeSlider;

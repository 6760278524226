import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageBanner from '../components/PageBanner';
import { login } from '../redux/lapisUser/actions';
import { Link } from 'react-router-dom';
import unauthenticatedClient from '../client';

function UserView(props: any) {
  const dispatch = useDispatch();

  const loggedInUser = useSelector((state: any) => {
    return state.lapisUser;
  });
  const userScores = useSelector((state: any) => {
    return state.scores;
  });
  const setData = useSelector((state: any) => {
    return state.questions;
  });

  useEffect(() => {
    if (loggedInUser.size === 0) {
      dispatch(login(props.location.search.replace('?', '')));
    }
  }, [loggedInUser, dispatch, props]);

  const getUsetName = () => {
    let un = '';
    if (loggedInUser.size > 0) {
      un = loggedInUser.toArray()[0][1].get('name');
    }
    return un;
  };
  const renderScores = () => {
    let scores: any = [];
    userScores
      .sort((a: any, b: any) => {
        if (parseInt(a.get('score')) === parseInt(b.get('score'))) {
          return 0;
        } else {
          if (parseInt(a.get('score')) > parseInt(b.get('score'))) {
            return -1;
          } else {
            return 1;
          }
        }
      })
      .forEach((score: any) => {
        setData.sets.setData.forEach((set: any) => {
          if (set.get('id') === score.get('setId')) {
            scores.push(
              <div className="row">
                <div className="col-md-8">{set.get('setName')}</div>
                <div className="col-md4">{score.get('score')}</div>
              </div>
            );
          }
        });
      });
    return scores;
  };
  return (
    <>
      <PageBanner BannerTitle="My Acount" BannerImage={`url('images/top-banner.jpg')`} />

      <section
        id="about-block"
        className="about-main-block abt-two"
        style={{ marginTop: '0px', backgroundImage: "url('images/thothbg1.jpg')" }}
      >
        <section id="ministry-leader">
          <div>
            {getUsetName().length > 0 ? (
              <div className="container">
                <div className="section text-center">
                  <h3 className="section-heading">Welcome Back ~ You are logged in</h3>
                  <h5 className="sub-heading">
                    You are now able to access sections of the website specific to your account.
                  </h5>
                </div>
                <div className="ministry-leader-content">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="leader-img">
                        <img src="images/officers/secretary.jpg" className="img-responsive" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="leader-dtl" style={{ marginTop: '0px' }}>
                        <h4 className="leader-name">{getUsetName()}</h4>
                        <br />
                        <p>
                          You can resume playing the Study Guide <Link to="studyGuide">Here</Link>
                        </p>
                        <h4 className="leader-name">Your Score Data</h4>
                        <br />
                        <div className="row">
                          <div className="col-md-8">
                            <strong>Set Name</strong>
                          </div>
                          <div className="col-md4">
                            <strong>My Score</strong>
                          </div>
                        </div>
                        {renderScores()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="section text-center">
                  <h3 className="section-heading">Please Sign In</h3>
                  <h5 className="sub-heading">
                    In order to access this section of the website, you will need to sign in first.
                  </h5>
                  <br />
                  <a
                    className="btn btn-default active"
                    href={`${unauthenticatedClient.defaults.url}login/federated/google`}
                    style={{ width: '25%', backgroundColor: '#000000' }}
                  >
                    Sign in with Google to Play
                  </a>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </>
  );
}
export default UserView;

import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import NaturalSort from 'natural-sort';
import { QuotesProps } from '../types';

function QuotesBlock(props: QuotesProps) {
  const naturalSort = NaturalSort();

  const renderQuotes = () => {
    let setItems: any = [];
    props.Quotes.sort((a: any, b: any) => naturalSort(a.get('id'), b.get('id'))).forEach((quoteItem: any) => {
      setItems.push(
        <div key={quoteItem.get('id')} className="item pastor-block">
          <div className="container">
            <div className="pastor-content">
              <span className="quotes-icon">
                <i className="fa fa-2x fa-quote-left"></i>
              </span>
              <h2 className="pastor-dtl">{quoteItem.get('Quote')}</h2>
              <div className="pastor-id">
                <p>{quoteItem.get('By')}</p>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return setItems;
  };
  return (
    <section id="pastor" className="pastor-main-block block-spacing">
      <div className="parallax" style={{ backgroundImage: `url('images/Pastor/pastor-bg.jpg')` }}>
        <div className="overlay-bg"></div>
        <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
          {renderQuotes()}
        </Carousel>
      </div>
    </section>
  );
}
export default QuotesBlock;

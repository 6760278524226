import Immutable from 'immutable';
import { newsletterList } from '../../data/newsletters';

const newsletters = (state = new Immutable.Map(), action) => {
  return state.merge(
    Immutable.fromJS(
      newsletterList.reduce((map, property) => {
        map[property.id] = property;
        return map;
      }, {})
    )
  );
};

export default newsletters;

import Immutable from 'immutable';
import { combineReducers } from 'redux';
import AllOrdinalPositions from '../../data/Questions/32/AllOrdinalPositions.json';
import HeavensOfAssiah from '../../data/Questions/32/HeavensOfAssiah.json';
import HeavensOfAssiahHebrew from '../../data/Questions/32/HeavensOfAssiahHebrew.json';
import HebrewNames from '../../data/Questions/32/HebrewNames.json';
import HebrewWords from '../../data/Questions/32/HebrewWords.json';
import Thoth from '../../data/Questions/32/Thoth.json';
const thirtyTwos = combineReducers({
  allOrdinalPositions: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        AllOrdinalPositions.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  heavensOfAssiah: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HeavensOfAssiah.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  heavensOfAssiahHebrew: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HeavensOfAssiahHebrew.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewWords: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewWords.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  thoth: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Thoth.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
});

export default thirtyTwos;

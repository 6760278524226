import React from 'react';
function PreLoader() {
  return (
    <div className="preloader" style={style.noDisplay}>
      <div className="status" style={style.noDisplay}>
        <div className="status-message"></div>
      </div>
    </div>
  );
}
const style = {
  noDisplay: {
    display: 'none',
  },
};
export default PreLoader;

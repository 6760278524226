export const libraryItems = [
  {
    id: 1,
    title: 'Liber AL Vel Legis',
    description: 'The foundational text of Thelema',
    image: 'images/library/CCXX.jpg',
    class: 'A',
    written: '1904',
    link: 'downloads/books/liberAL-220-eng.pdf',
  },
  {
    id: 2,
    title: 'Liber LXV',
    description: 'Liber Cordis Cincti Serpente',
    image: 'images/library/LXV.jpg',
    class: 'D',
    written: '',
    link: 'downloads/books/Liber65.pdf',
  },
  {
    id: 3,
    title: 'Liber LII',
    description: 'Manifesto of the O.T.O.',
    image: 'images/library/LII.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/52(LII) Manifesto of the O.T.O..pdf',
  },
  {
    id: 4,
    title: 'Liber CI',
    description: 'An Open Letter',
    image: 'images/library/CI.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/101(CI) An Open Letter.pdf',
  },
  {
    id: 5,
    title: 'Liber CXXIV',
    description: 'Of Eden and the Sacred Oak',
    image: 'images/library/CXXIV.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/124(CXXIV)Of Eden and the Sacred Oak.pdf',
  },
  {
    id: 6,
    title: 'Liber CLXI',
    description: 'Concerning the Law of Thelema',
    image: 'images/library/CLXI.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/161(CLXI) Concerning the Law of Thelema.pdf',
  },
  {
    id: 7,
    title: 'Liber CXCIV',
    description: 'An Intimation with Reference to the Constitution of the Order',
    image: 'images/library/CXCIV.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/194(CXCIV)An Intimation with Reference to the Constitution of the Order.pdf',
  },
  {
    id: 8,
    title: 'Liber CCC',
    description: 'Khabs Am Pekht',
    image: 'images/library/CCC.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/300(CCC)Khabs Am Pekht.pdf',
  },
  {
    id: 9,
    title: 'Liber DCCCXXXVII',
    description: 'The Law of Liberty',
    image: 'images/library/DCCCXXXVII.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/837(DCCCXXXVII) The Law of Liberty.pdf',
  },
  {
    id: 10,
    title: 'Liber LXXVII',
    description: 'Oz',
    image: 'images/library/LXXVII.jpg',
    class: 'Unclassified',
    written: '',
    link: 'downloads/books/LXXVII(57)Oz.pdf',
  },
  {
    id: 11,
    title: 'Liber XV',
    description: 'The Gnostic Mass',
    image: 'images/library/XV.jpg',
    class: 'Unclassified',
    written: '1912',
    link: 'downloads/books/Mass Book.pdf',
  },
];

import React from 'react';
import EGCContent from '../components/EGCContent';

import PageBanner from '../components/PageBanner';
import { getRandomBackground } from '../shared/utilities';
function EGCView() {
  return (
    <>
      <PageBanner BannerTitle="About E.G.C." BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ backgroundImage: getRandomBackground() }}>
        <section id="about-block" className="about-main-block abt-two" style={{ marginTop: '0' }}>
          <div className="container" style={{ backgroundColor: '#ffffff' }}>
            <br />
            <div className="abt-img">
              <img src="images/egc/egc1.jpg" className="img-responsive" alt="abt-img-1" />
            </div>
            <EGCContent />
          </div>
        </section>
      </div>
    </>
  );
}
export default EGCView;

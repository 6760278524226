import React, { useState } from 'react';
import PageBanner from '../components/PageBanner';
import { getSiteCopyById } from '../shared/utilities';
import { contact } from '../redux/contact/actions';
import { useDispatch } from 'react-redux';

function ContactView() {
  const dispatch = useDispatch();
  const [contactName, setContactName] = useState<string>('');
  const [contactPhone, setContactPhone] = useState<string>('');
  const [contactEmail, setContactEmail] = useState<string>('');
  const [contactMessage, setContactMessage] = useState<string>('');
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const isValid = () => {
    let valid = true;
    if (
      contactName.length === 0 ||
      contactPhone.length === 0 ||
      contactEmail.length === 0 ||
      contactMessage.length === 0
    ) {
      valid = false;
    }
    return valid;
  };
  return (
    <>
      <PageBanner BannerTitle="Contact Lapis Lazuli Oasis" BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ backgroundImage: 'url("images/thothbg1.jpg")' }}>
        <section id="about-block" className="about-main-block abt-two" style={{ marginTop: '0' }}>
          <div className="container">
            <br />
            <section id="contact-form" className="form-main-block">
              <div className="section">
                <h4 className="section-heading">Contact Us</h4>
                <h5 className="sub-heading">{getSiteCopyById('lapis.contact.header')}</h5>
              </div>
              <div className="container">
                <div className="row">
                  <div className="contact-form-block">
                    {messageSent === false ? (
                      <form className="contact-form" method="post">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="YOUR Name"
                              value={contactName}
                              onChange={(e: any) => {
                                let v = e.target.value;
                                setContactName(v);
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="number"
                              name="number"
                              placeholder="Phone Number"
                              value={contactPhone}
                              onChange={(e: any) => {
                                let v = e.target.value;
                                setContactPhone(v);
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email Address"
                              value={contactEmail}
                              onChange={(e: any) => {
                                let v = e.target.value;
                                setContactEmail(v);
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div className="form-group">
                            <textarea
                              id="message"
                              className="form-control"
                              name="message"
                              placeholder="Message"
                              value={contactMessage}
                              onChange={(e: any) => {
                                let v = e.target.value;
                                setContactMessage(v);
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <button
                            disabled={!isValid()}
                            onClick={() => {
                              dispatch(
                                contact({
                                  name: contactName,
                                  phone: contactPhone,
                                  email: contactEmail,
                                  message: contactMessage,
                                })
                              );
                              setContactEmail('');
                              setContactName('');
                              setContactPhone('');
                              setContactMessage('');
                              setMessageSent(true);
                            }}
                            type="button"
                            className="btn btn-default"
                          >
                            Send
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div className="item pastor-block">
                        <div className="container">
                          <div className="pastor-content">
                            <span className="quotes-icon">
                              <i className="fa fa-2x fa-quote-left"></i>
                            </span>
                            <h2 className="pastor-dtl">Thank You</h2>
                            <div className="pastor-id">
                              <p>Your message has been sent</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      </div>
    </>
  );
}
export default ContactView;

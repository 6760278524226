import React from 'react';
import { useSelector } from 'react-redux';
import PageBanner from '../components/PageBanner';
import SectionBody from '../components/SectionBody';
import SectionHeading from '../components/SectionHeading';
import SectionSubHeading from '../components/SectionSubHeading';
import { getSiteCopyById } from '../shared/utilities';
import { ImmutableDownloadItem, ImmutableLibraryItem } from '../types';
function DownloadView() {
  const downloadList = useSelector((state: any) => {
    return state.downloadListItems;
  });

  const renderLibrary = () => {
    let retVal: any = [];
    downloadList.forEach((liber: ImmutableDownloadItem) => {
      retVal.push(
        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div className="news-block">
            <div className="news-img block-effect">
              <a href={liber.get('link')}>
                <img src={liber.get('image')} className="img-responsive" alt="blog-img-1" />
              </a>
            </div>
            <div className="news-content">
              <div className="news-desc">
                <p className="news-date">
                  <i className="fa fa-calendar"></i>Created By {liber.get('createdBy')}
                </p>
              </div>
              <a href="blog-details.html">
                <h4 className="news-heading">{liber.get('title')}</h4>
              </a>
            </div>
          </div>
        </div>
      );
    });
    return retVal;
  };

  return (
    <>
      <PageBanner BannerTitle="Downloads" BannerImage={`url('images/top-banner.jpg')`} />

      <div style={{ paddingTop: '1px', backgroundColor: '#ffffff' }}>
        <section id="donation" className="donation-main-block donation-two">
          <div className="container">
            <div className="abt-content">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="abt-sectionOne">
                    <SectionHeading Heading={getSiteCopyById('downloads.title')} />
                    <SectionSubHeading Heading={getSiteCopyById('downloads.subtitle')} />
                    <SectionBody Heading={getSiteCopyById('downloads.body')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="news" className="news-main-block news-two">
          <div className="container">
            <div className="row">{renderLibrary()}</div>
          </div>
        </section>
      </div>
    </>
  );
}
export default DownloadView;

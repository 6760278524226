import Immutable from 'immutable';
import { quotesList } from '../../data/quotes';
import { combineReducers } from 'redux';

const quotes = combineReducers({
  quotes: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        quotesList.reduce((map, property) => {
          map[property.id] = property;
          return map;
        }, {})
      )
    );
  },
});

export default quotes;

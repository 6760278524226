import Immutable from 'immutable';
import { events } from '../../data/event';

const eventItems = (state = new Immutable.Map(), action) => {
  return state.merge(
    Immutable.fromJS(
      events.reduce((map, property) => {
        map[property.id] = property;
        return map;
      }, {})
    )
  );
};

export default eventItems;

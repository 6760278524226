import React from 'react';
import HomeSlider from '../components/HomeSlider';
import MainBlock from '../components/MainBlock';
import TabBlock from '../components/TabBlock';
import QuotesBlock from '../components/Quotes';
import { ImmutableQuoteItem, TabBlockItem } from '../types';
import { getSiteCopyById } from '../shared/utilities';
import { useSelector } from 'react-redux';

function HomeView() {
  const quoteData = useSelector((state: any) => {
    return state.quotes.quotes.filter((item: ImmutableQuoteItem) => {
      return item.get('Page').toLocaleLowerCase() === 'home';
    });
  });
  const homeSliderItems = useSelector((state: any) => {
    return state.homeSliderItems;
  });
  const tabBlockItems: TabBlockItem[] = [
    {
      id: 1,
      Title: 'Initiation',
      Image: 'fa fa-eye',
      Link: 'initiation',
    },
    {
      id: 2,
      Title: 'Gnostic Mass',
      Image: 'fa fa-glass',
      Link: 'egc',
    },
    {
      id: 3,
      Title: 'Events',
      Image: 'flaticon-prayers',
      Link: 'events',
    },

    {
      id: 6,
      Title: 'Holy Books',
      Image: 'flaticon-pdf',
      Link: 'library',
    },
  ];
  return (
    <>
      <HomeSlider items={homeSliderItems} />
      <MainBlock
        Title={getSiteCopyById('home.title')}
        SubHeading={getSiteCopyById('home.subtitle')}
        Content={getSiteCopyById('home.body')}
        Image="images/nature/lapis.jpg"
        BackgroundColor="#efefef"
        BackgroundImage='url("images/thothbg1.jpg")'
        TopMargin="5%"
      />
      <TabBlock
        Title={getSiteCopyById('home.activities.heading')}
        SubTitle={getSiteCopyById('home.activities.body')}
        Items={tabBlockItems}
      />

      <QuotesBlock Quotes={quoteData} />
    </>
  );
}
export default HomeView;

export const newsletterList = [
  {
    id: 23,
    name: 'Apr 2022 Newsletter',
    date: '4/17/2022',
    link: 'LLO_Apr_2022_3.pdf',
    image: 'images/newsletter/Aries.jpg',
    description:
      'Including information and annoucements about Upcoming masses, Minerval deaslines, rescheduled business meeting',
  },
  {
    id: 22,
    name: 'Apr 2022 Newsletter',
    date: '4/6/2022',
    link: 'LLO_Apr_2022_2.pdf',
    image: 'images/newsletter/Aries.jpg',
    description:
      'Three days of the writing of the Book of the Law, Business meeting, Signs and Songs (new tarot and tunes format), outdoor cleanup',
  },
  {
    id: 21,
    name: 'Apr 2022 Announcements',
    date: '4/5/2022',
    link: 'LLO_Apr_2022.pdf',
    image: 'images/newsletter/Aries.jpg',
    description:
      'News about masses, upcoming minerval initiations, EGC membership, new PayPal subscription options. Covid updtes for mass attendance.',
  },
  {
    id: 20,
    name: 'Feb 2022 Newsletter',
    date: '1/30/2022',
    link: 'LLO_Feb_2022.pdf',
    image: 'images/newsletter/Aquarius.jpg',
    description:
      'Change in mastership of the local body, new dues structure, new information about events and Covid restrictions.',
  },
  {
    id: 19,
    name: 'Nov 2021 Newsletter',
    date: '11/10/2021',
    link: 'LLO_Nov_2021.pdf',
    image: 'images/newsletter/Scorpio.jpg',
    description: 'Astrology discussion, Tarot and Tunes - The Emperor. Updated Covid restriction information',
  },
  {
    id: 18,
    name: 'Oct 2021 Newsletter',
    date: '10/07/2021',
    link: 'LLO_Oct_2021.pdf',
    image: 'images/newsletter/Libra.jpg',
    description: 'Astrology discussion, Tarot and Tunes - The Lovers. Updated Covid restriction information',
  },
  {
    id: 17,
    name: 'Sept 2021 Newsletter',
    date: '09/13/2021',
    link: 'LLO_Sep_2021_B.pdf',
    image: 'images/newsletter/Virgo.jpg',
    description:
      'Astrology discussion, Tarot and Tunes - Adjustment. Cafe Thelema, T-Sirts for sale, Updated Covid restriction information',
  },
  {
    id: 16,
    name: 'Sep 2021 Events Update',
    date: '08/30/2021',
    link: 'LLO_Sep_2021.pdf',
    image: 'images/newsletter/Virgo.jpg',
    description:
      'Astrology discussion, Tarot and Tunes - The Chariot. Cafe Thelema Cancelled,  Updated Covid restriction information',
  },
  {
    id: 15,
    name: 'Aug 2021 Newsletter',
    date: '08/23/2021',
    link: 'LLO_Aug_2021.pdf',
    image: 'images/newsletter/Leo.jpg',
    description:
      'Information about getting dues current, information about vaccination status, event attendance, officer email addresses',
  },
  {
    id: 14,
    name: 'Jul 2021 Newsletter',
    date: '07/07/2021',
    link: 'LLO_Jul_2021.pdf',
    image: 'images/newsletter/Cancer.jpg',
    description: 'Astrology discussion, Tarot and Tunes - The Hanged Man.,  Updated Covid restriction information',
  },
  {
    id: 13,
    name: 'Jun 2021 Newsletter',
    date: '06/10/2021',
    link: 'LLO_Jun_2021.pdf',
    image: 'images/newsletter/Gemini.jpg',
    description:
      'Astrology discussion, Tarot and Tunes - Art.,  Updated Covid restriction information, event information, etc...  ',
  },
  {
    id: 12,
    name: 'May 2021 Newsletter',
    date: '04/28/2021',
    link: 'LLO_May_2021.pdf',
    image: 'images/newsletter/Taurus.jpg',
    description:
      'Astrology discussion, Tarot and Tunes - The Star., T-Shirts for sale,  Updated Covid restriction information',
  },
  {
    id: 11,
    name: 'Apr 2021 Newsletter',
    date: '04/06/2021',
    link: 'LLO_Apr_2021.pdf',
    image: 'images/newsletter/Aries.jpg',
    description:
      'The Holy Days, Astrology discussion, Tarot and Tunes - The Moon., T-Shirts,  Updated Covid restriction information',
  },
  {
    id: 10,
    name: 'Mar 2021 Newsletter',
    date: '03/09/2021',
    link: 'LLO_Mar_2021.pdf',
    image: 'images/newsletter/Pisces.jpg',
    description: 'Astrology discussion, Tarot and Tunes - The Aeon., T-Shirts,  Updated Covid restriction information',
  },
  {
    id: 9,
    name: 'Feb 2021 Newsletter',
    date: '02/12/2021',
    link: 'LLO_Feb_2021.pdf',
    image: 'images/newsletter/Aquarius.jpg',
    description:
      'Temple of Solomon pt. 12, Astrology discussion, Tarot and Tunes - The Universe., T-Shirts,  Updated Covid restriction information',
  },
  {
    id: 8,
    name: 'Jan 2021 Newsletter',
    date: '01/12/2021',
    link: 'LLO_Jan_2021.pdf',
    image: 'images/newsletter/Capricorn.jpg',
    description:
      '16th Anniversary, Astrology discussion, Temple of Solomon pt.11,  Updated Covid restriction information',
  },
  {
    id: 7,
    name: 'Passing of David Garett',
    date: '10/22/2020',
    link: 'LLO_David_Garett.pdf',
    image: 'images/newsletter/Libra.jpg',
    description: 'Celebration of the Greater Feast of Brother and Past Master of Lapis Lazuli Oasis David Garett',
  },
  {
    id: 6,
    name: 'The Sigilum Dei',
    date: '07/12/2020',
    link: 'LLO_Colin_Campbell_2021.pdf',
    image: 'images/newsletter/Cancer.jpg',
    description: 'USGL education commitee hosts a talk by Collin Campbell on the Sigilum Dei. This event is past',
  },
  {
    id: 5,
    name: 'Jul 2020 Newsletter',
    date: '07/05/2020',
    link: 'LLO_Jul_2020.pdf',
    image: 'images/newsletter/Cancer.jpg',
    description: 'Cafe Thelema, Event updates, Website updated, G-Suite and Virtual Valley usage for members',
  },
  {
    id: 4,
    name: 'Mar 2020 Newsletter',
    date: '03/21/2020',
    link: 'LLO_Mar_2020.pdf',
    image: 'images/newsletter/Pisces.jpg',
    description: 'Special announcement from Sabazius X° on events and general Covid closures ',
  },
  {
    id: 3,
    name: 'Equinox Ritual Canceled',
    date: '03/18/2020',
    link: 'LLO_Mar_2020_B.pdf',
    image: 'images/newsletter/Pisces.jpg',
    description: 'Special announcement from the Master on events and general Covid closures',
  },
  {
    id: 2,
    name: 'Upcoming Events',
    date: '03/02/2020',
    link: 'LLO_Mar_2020_C.pdf',
    image: 'images/newsletter/Pisces.jpg',
    description: 'Cafe Thelema, Gnostic Mass, Magick Study Group, Pagans in the Park, Equinox Ritual',
  },
  {
    id: 1,
    date: '01/31/2020',
    name: 'Jan 2020 Newsletter',
    link: 'LLO_Jan_2020_C.pdf',
    image: 'images/newsletter/Capricorn.jpg',
    description: 'Cafe Thelema, Business Meeting, Magick Study Group, Sistars of Lapis meeting',
  },
];

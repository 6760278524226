import moment from 'moment';
import { siteAddress } from '../shared/utilities';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ImmutableLibraryItem, ImmutableNewsletterItem } from '../types';
import NaturalSort from 'natural-sort';

function Footer() {
  const naturalSort = NaturalSort();

  const libraryList = useSelector((state: any) => {
    return state.library;
  });

  const renderLibrary = () => {
    let retVal: any = [];
    libraryList.forEach((liber: ImmutableLibraryItem) => {
      if (retVal.length < 6) {
        retVal.push(
          <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4 portfolio-item">
            <div className="portfolio-img">
              <img src="images/library/stack1.jpg" className="img-responsive" alt="blog-img-1" />
              <div style={{ fontSize: '10pt' }}>{liber.get('title')}</div>
              <div className="portfolio-overlay">
                <a href={liber.get('link')}>
                  <i className="fa fa-download"></i>
                </a>
              </div>
            </div>
          </div>
        );
      }
    });
    return retVal;
  };

  const newsletters = useSelector((state: any) => {
    return state.newsletters;
  });
  const renderNewsletterItems = () => {
    let newsletterItems: any = [];
    newsletters
      .sort((a: number, b: number) => naturalSort(b, a))
      .forEach((newsletterItem: ImmutableNewsletterItem) => {
        if (newsletterItems.length < 3) {
          newsletterItems.push(
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-xs-3 footer-post">
                <a href={`/downloads/newsletters/${newsletterItem.get('link')}`}>
                  <img
                    src={`${siteAddress}${newsletterItem.get('image')}`}
                    className="img-responsive"
                    alt="post-img-02"
                  />
                </a>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-9 footer-post">
                <a href={`/downloads/newsletters/${newsletterItem.get('link')}`}>
                  <p>
                    {newsletterItem.get('name')}
                    <br />
                    <span className="footer-post-date">{newsletterItem.get('date')}</span>
                  </p>
                </a>
              </div>
            </div>
          );
        }
      });
    return newsletterItems;
  };

  return (
    <footer
      id="footer"
      className="footer-main-block"
      style={{ borderTopStyle: 'solid', borderTopColor: '#e4b855', borderTopWidth: '2px' }}
    >
      <div className="bg-img" style={{ backgroundImage: `url('${siteAddress}images/footer-bg.jpg')` }}>
        <div className="overlay-bg"></div>
        <div className="container">
          <div className="row footer-block">
            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div className="footer-logo">
                <Link to={`/`}>
                  <img src={`${siteAddress}images/logo.png`} className="img-responsive" alt="footer-logo" />{' '}
                </Link>
              </div>
              <div className="row footer-content">
                <div className="col-lg-4 col-md-3 col-sm-3 col-xs-3">
                  <p>Mailing Address:</p>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                  <p>
                    Lapis Lazuli Oasis
                    <br />
                    P.O. Box 36325
                    <br />
                    Phoenix, AZ 85067
                  </p>
                  <p>Email:</p>{' '}
                  <p className="mail-to">
                    <a href="mailto:lapislazuli.master@oto-usa.org?Subject=Information" target="_top">
                      lapislazuli.master@oto-usa.org
                    </a>
                  </p>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-3"></div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9"></div>
              </div>
            </div>
            <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
              <div className="footer-section">
                <h6 className="footer-heading">Recent Newsletters</h6>
              </div>
              <div className="footer-content">{renderNewsletterItems()}</div>
            </div>

            <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
              <div className="footer-section">
                <h6 className="footer-heading">Holy Books</h6>
              </div>
              <div className="footer-content portfolio-popup">{renderLibrary()}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 footer-dtl text-center">
              <p>
                Copyright &copy; {moment().format('YYYY')} <a href="/">Lapis Lazuli O.T.O.</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;

const setData = [
  {
    setID: 1,
    setName: '22 Hebrew Letters',
    Description:
      'These are the 22 letters of the Hebrew Alphabet (Aleph-Bet), which are a foundation of most Western Hermetic Mysteries. You will benefit tremendously from their ardent study, as they form a universal key of sorts for unlocking many of the doors that lay before you in this path of study. This alphabet is made up of 22 letters and divided into 3 sets: Mother Letters (Aleph-Mem-Shin), Double Letters (Bet-Gimel-Dalet-Kaph-Peh-Resh-Tav), Single Letters (Samekh-Nun-Ayin-Qoph-Yod-Cheth-Heh-Zayin-Teth-Lamed-Tzaddi-Vav).',
    requires: [],
    Status: 'Active',
    reduxMap: 'twentyTwos.hebrewLetters',
    MasteryAt: 350,
    id: 'bc9b2dc2-94ae-43f9-b010-d5ebdb3576c4',
  },
  {
    setID: 2,
    setName: '32 Thoth Tarot',
    Description:
      'This is a collection of the 32 major associations of the Thoth Tarot Deck attributed to the Qabalistic Tree of Life, whereby each of the 22 cards of the Major Arcana are placed upon the 22 letter paths of the Tree of Life and the collected number cards are associated with the appropriate Sphere.',
    requires: [],
    Status: 'Active',
    reduxMap: 'thirtyTwos.thoth',
    MasteryAt: 400,
    id: '95759455-c4ca-4128-92cb-c41a70519f47',
  },
  {
    setID: 3,
    setName: '32 Hebrew Names',
    Description:
      'These are the 32 Hebrew words for the Spheres and letters of the Alphabet, tranliterated into English',
    requires: [{ setID: 144, score: 400 }],
    Status: 'Active',
    reduxMap: 'thirtyTwos.hebrewNames',
    MasteryAt: 400,
    id: '478794a2-02af-4055-98f7-3cfba2c608e5',
  },
  {
    setID: 4,
    setName: '22 Path Number Values',
    Description:
      'These are the 22 numeric values associated with the letters of the Hebrew Alphabet. When you work with traditional Hebrew Gematria, these are the numbers which you will use to aggrigate your word totals.',
    requires: [],
    Status: 'Active',
    reduxMap: 'twentyTwos.pathValues',
    MasteryAt: 350,
    id: '58d0fe81-1e92-4676-87c8-51d5255363c3',
  },
  {
    setID: 5,
    setName: '32 Hebrew Words',
    Description:
      'These are the 32 English translation of the Hebrew names of the Spheres of the Tree of Life as well as the letters of the Alphabet.',
    requires: [{ setID: 144, score: 400 }],
    Status: 'Active',
    reduxMap: 'thirtyTwos.hebrewWords',
    MasteryAt: 400,
    id: 'def54f73-1202-4e8c-ae1e-5e21f318a322',
  },
  {
    setID: 6,
    setName: '22 Heavens of Assiah',
    Description:
      'These 22 associations are the Elemental attributions of the Heavens of Assiah as presented in Liber 777 Column VII. They include the 3 primal elements, the 7 classical planets and 12 zodical signs',
    requires: [{ setID: 4, score: 350 }],
    Status: 'Active',
    reduxMap: 'twentyTwos.heavansOfAssiah',
    MasteryAt: 350,
    id: 'dedd4656-48c7-4c50-b629-5b24ae6c169b',
  },
  {
    setID: 7,
    setName: '22 Egyptian Gods',
    Description:
      "These 22 gods are derived from the 'Complete Practical Attributions of Egyptian Gods' as described in Liber 777 column XX.",
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    reduxMap: 'twentyTwos.egyptianGods',
    id: 'cf5e741d-90ac-4e30-b90d-51db0ddf0f87',
    MasteryAt: 350,
  },
  {
    setID: 8,
    setName: '7 Planets',
    Description:
      'These 7 planets represent the classical planets of old. Understanding them and their placement upon the tree of life will build a foundation of understanding of many other things that are based in 7s, like the Archangels or magical metals.',
    requires: [],
    Status: 'Active',
    reduxMap: 'sevens.planets',
    id: 'a0da0f1e-c715-4f55-95d4-a911f828f7d5',
    MasteryAt: 140,
  },
  {
    setID: 9,
    setName: '12 Zodiacal Signs',
    Description:
      'These 12 items are the English names of the 12 Zodiacal signs, They are superimposed over the 12 paths of the Tree of Life starting in Heh (Or Tzaddi if that is your prefernece) and end on the Moon. Knowing these 12 paths in order will open many doors for you with regard to many other systems of 12 like the single letters of the Hebre Alphabet, the Apostles, or Tribes of Israel.',
    requires: [],
    Status: 'Active',
    reduxMap: 'twelves.zodiacalSigns',
    MasteryAt: 250,
    id: 'd4b9d5e9-8234-46db-8796-2dd3179de762',
  },
  {
    setID: 10,
    setName: '22 Magical Weapons',
    Description: 'These 22 magical weapons are derived from Liber 777 Column XLI',
    requires: [{ setID: 4, score: 350 }],
    Status: 'Active',
    reduxMap: 'twentyTwos.magicalWeapons',
    MasteryAt: 350,
    id: '78035329-72b4-4326-be93-6071b75f9d3e',
  },
  {
    setID: 11,
    setName: '4 Four Archangels',
    Description: '',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.fourArchangels',
    MasteryAt: 100,
    id: '9a4314e6-929c-4d40-a809-ee250a9ea3df',
  },
  {
    setID: 12,
    setName: '4 Egyptian Jars',
    requires: [{ setID: 21, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.egyptianJars',
    Description: '',
    id: 'e6970879-43d3-4ace-b639-ff7401bb4efa',
  },
  {
    setID: 13,
    setName: '4 Egyptian Death Gods',
    Description: '',
    requires: [{ setID: 21, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.egyptianDeathGods',
    MasteryAt: 100,
    id: '5a4d7fbe-13bd-43ab-9036-cf0edbcf0604',
  },
  {
    setID: 14,
    setName: '4 Tetragrammaton',
    Description: '',
    requires: [
      { setID: 20, score: 100 },
      { setID: 21, score: 100 },
      { setID: 26, score: 100 },
    ],
    Status: 'Active',
    reduxMap: 'fours.tetragrammaton',
    MasteryAt: 100,
    id: 'ea3599a4-20db-48e1-a75f-b407daebfe32',
  },
  {
    setID: 15,
    setName: '4 God Names',
    Description: '',
    requires: [{ setID: 24, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.godNames',
    MasteryAt: 100,
    id: 'a9d04647-c616-4ea8-a41c-d486f55345ef',
  },
  {
    setID: 16,
    setName: '4 Four Gospels',
    Description: '',
    requires: [{ setID: 24, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.fourGospels',
    MasteryAt: 100,
    id: '90c1e9ea-7dff-4d8a-894f-4db27ce11729',
  },
  {
    setID: 17,
    setName: '4 Rivers of Evil',
    Description: '',
    requires: [{ setID: 24, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.riversOfEvil',
    MasteryAt: 100,
    id: 'f7d79e6c-91e8-4e5c-9276-965fdd339890',
  },
  {
    setID: 18,
    setName: '4 The Senses',
    Description: '',
    requires: [{ setID: 163, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.theSenses',
    MasteryAt: 100,
    id: 'ca94fbe6-216a-45ec-9152-52ad3f6f5f33',
  },
  {
    setID: 19,
    setName: '4 Rivers of Eden',
    Description: '',
    requires: [{ setID: 24, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.riversOfEden',
    MasteryAt: 100,
    id: '849b7c34-4302-4615-9d38-e1091f4d74d1',
  },
  {
    setID: 20,
    setName: '4 Powers of Sphinx',
    data: './Questions/4/PowersOfSphinx.json',
    Description: '',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.powersOfSphinx',
    MasteryAt: 100,
    id: 'f9a94391-9e6e-43b5-96a1-8cbc8587fb3d',
  },
  {
    setID: 21,
    setName: '4 The Sphinx',
    Description: '',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.theSphinx',
    MasteryAt: 100,
    id: 'c480cd38-6ac4-4a4c-9957-a919a7e8b91d',
  },
  {
    setID: 22,
    setName: '4 Hebrew Soul',
    Description: '',
    requires: [{ setID: 24, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.hebrewSoul',
    MasteryAt: 100,
    id: 'b039823f-bf7d-4ce4-a4df-5a9ef3bd52d9',
  },
  {
    setID: 23,
    setName: '4 Visions of Abraham',
    Description:
      'This information is the attribution of the cardinal directions in the bibilcal story of Abraham coming to canaan. As the Hebrew language does not have direct translations for the cardinal directions, they are given as the landmarks Abraham saw when he came to see the expanse of his new land in Genesis 13: 14-17',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.visionsOfAbraham',
    MasteryAt: 100,
    id: '254d0f8f-af55-4bd5-a7f0-6777aa40480d',
  },
  {
    setID: 24,
    setName: '4 TAROT Suits',
    Description: '',
    requires: [],
    Status: 'Active',
    reduxMap: 'fours.tarotSuits',
    MasteryAt: 100,
    id: '38c13019-f467-41a2-a966-48b8a9fe03da',
  },
  {
    setID: 25,
    setName: '10 Ten Spheres',
    requires: [],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.tenSpheres',
    id: 'd5769048-832b-4edc-a6c5-e3dc718e4038',
    Description:
      'The Ten Spheres of The Tree of Life represent the ten emminations of creation.\n\nEach Sphere can also be seen as a level or state of consciousness.\n\nThe Spheres are:\nKether\nChokmah\nBinah\nChesed\nGeburah\nTiphareth\nNetzach\nHod\nYesod\nMalkuth',
  },
  {
    setID: 26,
    setName: '4 Cardinal Directions',
    Description: 'Sourced from Liber 777 Column CX',
    requires: [],
    Status: 'Active',
    reduxMap: 'fours.cardinalDirections',
    MasteryAt: 100,
    id: '3184786b-a464-4b98-b721-46cbd2642036',
  },
  {
    setID: 27,
    setName: '4 Demon Kings',
    Description: 'Sourced from Liber 777 Column LXVIII',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.demonKings',
    MasteryAt: 100,
    id: 'e2ed330b-32dd-48d8-8c64-c0eeb646704d',
  },
  {
    setID: 28,
    setName: '4 Tetragrammaton Formula',
    Description: '',
    requires: [
      { setID: 20, score: 100 },
      { setID: 21, score: 100 },
      { setID: 26, score: 100 },
    ],
    Status: 'Active',
    reduxMap: 'fours.tetragrammatonFormula',
    MasteryAt: 100,
    id: '2cd985a5-73e4-4397-b849-5847d2a7f1e7',
  },
  {
    setID: 29,
    setName: '4 Liber Resh',
    Description: '',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    reduxMap: 'fours.liberResh',
    MasteryAt: 100,
    id: 'd82d750e-ee6f-4149-997b-0c13509e4294',
  },
  {
    setID: 30,
    setName: '7 Double Letters',
    Description: '',
    requires: [],
    Status: 'Active',
    reduxMap: 'sevens.doubleLetters',
    MasteryAt: 140,
    id: 'a914a5ea-7b03-478a-8042-7ca078da1be7',
  },
  {
    setID: 31,
    setName: '7 The Chakras',
    Description: 'Sourced from Liber 777 Column CXVIII',
    requires: [{ setID: 30, score: 140 }],
    Status: 'Active',
    reduxMap: 'sevens.chakras',
    MasteryAt: 140,
    id: '17c150de-186f-46ed-9c3c-226826af3e5f',
  },
  {
    setID: 32,
    setName: '7 Hebrew Planetary Spirits',
    Description: 'Sourced from Liber 777 Column CXCIII',
    requires: [{ setID: 8, score: 140 }],
    Status: 'Active',
    reduxMap: 'sevens.hebrewPlanetarySpirits',
    MasteryAt: 140,
    id: '0a749131-36f9-44bb-9435-305664dff84f',
  },
  {
    setID: 33,
    setName: '7 Planetary Metals',
    Description: 'Sourced from Liber 777 Column LXXXI',
    requires: [{ setID: 8, score: 140 }],
    Status: 'Active',
    reduxMap: 'sevens.planetaryMetals',
    MasteryAt: 140,
    id: '41839a8a-d143-4d4d-aa45-b434fd2e9124',
  },
  {
    setID: 34,
    setName: '7 Demon Ranks',
    Description: '',
    requires: [{ setID: 30, score: 140 }],
    Status: 'Active',
    reduxMap: 'sevens.demonRanks',
    MasteryAt: 140,
    id: 'caf2b063-5223-4cf7-9e95-961bd7eeb2d6',
  },
  {
    setID: 35,
    setName: '7 Planetary Angels',
    Description: 'Sourced from Liber 777 Column CLXXIII',
    requires: [{ setID: 8, score: 140 }],
    Status: 'Active',
    reduxMap: 'sevens.planetaryAngels',
    MasteryAt: 140,
    id: 'e5e50676-559b-412c-b8a3-cb0e199310c9',
  },
  {
    setID: 36,
    setName: '7 Seven Deadly Sins',
    Description: '',
    requires: [{ setID: 30, score: 140 }],
    Status: 'Active',
    reduxMap: 'sevens.sevenDeadlySins',
    MasteryAt: 140,
    id: '327736bc-de47-4969-96ae-bf2459636b44',
  },
  {
    setID: 37,
    setName: '10 Animals',
    Description: 'Sourced from 777 Column XXXVIII',
    requires: [],
    Status: 'Active',
    reduxMap: 'tens.animals',
    MasteryAt: 200,
    id: '90fa6dec-c3e5-407d-8c86-c32460da5282',
  },
  {
    setID: 38,
    setName: '10 The Archangels',
    Description: 'Sourced from 777 Column XCIC',
    requires: [{ setID: 66, score: 200 }],
    Status: 'Active',
    reduxMap: 'tens.archangels',
    MasteryAt: 200,
    id: '268be4ac-b818-423b-83dd-30f93013fa77',
  },
  {
    setID: 39,
    setName: '10 Body of God',
    Description: 'Sourced from 777 Column CIII',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    reduxMap: 'tens.bodyOfGod',
    MasteryAt: 200,
    id: 'ce8b2d13-8dca-483a-9889-2cb40701c52f',
  },
  {
    setID: 40,
    setName: '10 Elements and Quarters',
    Description: 'Sourced from 777 Column CX',
    requires: [],
    Status: 'Active',
    reduxMap: 'tens.elementsAndQuarters',
    MasteryAt: 200,
    id: '1aba0de0-4c5e-48c1-8c51-d7f797dfb1c5',
  },
  {
    setID: 41,
    setName: '10 Ten Egyptian Gods',
    Description: 'Sourced from 777 Column XIX',
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    reduxMap: 'tens.tenEgyptianGods',
    MasteryAt: 200,
    id: 'ad8b7ee7-e086-430c-b8dc-99553288fde3',
  },
  {
    setID: 42,
    setName: "10 A.'. A.'. Grades",
    Description: `The grades of the A.'.A.'. are a collection of initiatic grades attributed to an aspirant of this particular magical attainment system. While A.'.A.'. shares a common lineage from the grades of the Hermetic Order of the Golden Dawn, they should be seen as a different thing. You will notice that within the sphere of Malkuth that both Neophyte as well as Zelator are both mapped to a single sphere. The general path of ascension for the aspirant of A.’.A.’. starts at the bottommost sphere of Malkuth and follows the reversed path of the Flaming Sword. In general the work of the aspirant of A.’.A.’. is a lifelong pursuit whereby they seek to accomplish in their lifetime the completion of the Great Work.
      
      Sourced from 777 Column CXXI`,
    requires: [],
    Status: 'Active',
    reduxMap: 'tens.aaGrades',
    MasteryAt: 200,
    id: 'b1557937-b961-47de-98be-b710391ad1b6',
  },
  {
    setID: 43,
    setName: '10 Greek Gods',
    Description: 'Sourced from 777 Column XXXIV',
    requires: [{ setID: 25, score: 200 }],
    Status: 'Active',
    reduxMap: 'tens.greekGods',
    MasteryAt: 200,
    id: 'ec6a1067-cd90-4582-8ca6-419f562eb0b6',
  },
  {
    setID: 44,
    setName: '10 Hebrew Saints',
    Description: 'Sourced from 777 Column XCI',
    requires: [{ setID: 25, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.hebrewSaints',
    id: 'e31eccbe-49b5-4734-9d0f-da0d3b980e88',
  },
  {
    setID: 45,
    setName: '10 The Ten Hells',
    Description: 'Sourced from 777 Column CVI',
    requires: [{ setID: 66, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.tenHells',
    id: '050d3272-899c-416b-91ab-80dfc6700172',
  },
  {
    setID: 46,
    setName: '10 Ten Magical Weapons',
    Description: 'Sourced from 777 Column XLI',
    requires: [{ setID: 66, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.tenMagicalWeapons',
    id: '1b4f60eb-a877-461c-9237-e100d80345f7',
  },
  {
    setID: 47,
    setName: '10 Ten Perfumes',
    Description: 'Sourced from 777 Column XLII',
    requires: [{ setID: 66, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.tenPerfumes',
    id: 'b32a0c93-1440-43c2-b68e-98415d8484d0',
  },
  {
    setID: 48,
    setName: '10 Scandinavian Gods',
    Description: 'Sourced from 777 Column XXXIII',
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.scandinavianGods',
    id: '70540c86-32db-4291-8e5e-a417bd5c443b',
  },
  {
    setID: 49,
    setName: '10 Roman Gods',
    Description: 'Sourced from 777 Column XXXV',
    requires: [{ setID: 25, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.romanGods',
    id: '8ed9bd35-d74a-4ee5-afc7-b262c670680c',
  },
  {
    setID: 50,
    setName: '10 Precious Stones',
    Description: 'Sourced from 777 Column XL',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.preciousStones',
    id: '367aafc0-f9f2-4d56-bc1c-f160791ca0a8',
  },
  {
    setID: 51,
    setName: '10 Magical Powers',
    Description: 'Sourced from 777 Column XLV',
    requires: [{ setID: 66, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.magicalPowers',
    id: '124a8c16-4946-4638-9246-1448b6e16f89',
  },
  {
    setID: 52,
    setName: '10 Magical Formulae',
    Description: 'Sourced from 777 Column CLXXXVII',
    requires: [{ setID: 66, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.magicalFormulae',
    id: '9b8ddb58-45ce-4046-bc33-d42ed13d4c95',
  },
  {
    setID: 53,
    setName: '10 Hindu Gods',
    Description: 'Sourced from 777 Column XXII',
    requires: [{ setID: 25, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.hinduGods',
    id: '9087ec26-7d62-444e-8111-f8588a108861',
  },
  {
    setID: 54,
    setName: '7 Arbatel Spirits',
    Description: 'Sourced from Liber 777 Column LXXX',
    requires: [{ setID: 8, score: 140 }],
    Status: 'Active',
    MasteryAt: 140,
    reduxMap: 'sevens.arbatel',
    id: '3cb4762a-ada5-43e4-97b0-e747ff438cc8',
  },
  {
    setID: 55,
    setName: '4 Elemental Qualities',
    Description: 'Stephen Skinners The Complete Magicians Tables',
    requires: [{ setID: 163, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.elementalQualities',
    id: '70196f3a-3b26-4bdd-af36-abb4d14aa98b',
  },
  {
    setID: 56,
    setName: '4 The Four Houmours',
    Description: 'Stephen Skinners The Complete Magicians Tables',
    requires: [{ setID: 163, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.fourHumours',
    id: '9b371415-b331-4ef5-b3cf-30e4aee62608',
  },
  {
    setID: 57,
    setName: '4 Demon Kings of the Goetia',
    Description: 'Stephen Skinners The Complete Magicians Tables',
    requires: [{ setID: 11, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.demonKings',
    id: '00460f6a-0c52-4bfd-8491-e210c8e093fc',
  },
  {
    setID: 58,
    setName: '4 The Four Winds',
    Description: 'Stephen Skinners The Complete Magicians Tables',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.fourWinds',
    id: '6d750c9f-b651-498a-9136-52b435652780',
  },

  {
    setID: 59,
    setName: '7 Days of the Week',
    Description: 'Stephen Skinners The Complete Magicians Tables',
    requires: [{ setID: 8, score: 140 }],
    Status: 'Active',
    MasteryAt: 140,
    reduxMap: 'sevens.daysOfTheWeek',
    id: '780d9ed8-a7dc-4d3b-9d09-2d7bbafc6a07',
  },
  {
    setID: 60,
    setName: '10 Orders of Qliphoth',
    Description: '777 Column VIII',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.orderOfQliphoth',
    id: 'e2b4d327-c7a9-4848-80c4-d2c12d7f8efd',
  },
  {
    setID: 61,
    setName: '10 Orders of Qliphoth English',
    Description: '777 Column CVVIII',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.ordersOfQliphothEnglish',
    id: '655825a9-d59e-484c-add6-c83e755f15d2ß',
  },
  {
    setID: 62,
    setName: '10 Mystic Numbers',
    Description:
      '777 Column X. Can be illustrated by starting with 1 then the next number is the previous plus the ordinal position on the tree of life.',
    requires: [{ setID: 40, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.mysticNumbers',
    id: 'f3af82bc-1b0e-4763-9b46-5368ae8f6c68',
  },
  {
    setID: 63,
    setName: '10 Vegetable Drugs',
    Description: '777 Column XLIII',
    requires: [{ setID: 40, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.vegetableDrugs',
    id: '76ff5451-58c8-4d83-a154-ec615e6785c1',
  },
  {
    setID: 64,
    setName: '10 Sefer Yetzirah',
    Description: '777 Column XIII',
    requires: [],
    Status: 'Active',
    reduxMap: 'tens.seferYetzirah',
    id: 'd82ed9f9-4e5e-4e19-a863-58d0e3bd5c36',
    MasteryAt: 200,
  },
  {
    setID: 65,
    setName: '10 The Ten Plagues',
    Description: '777 Column CXXII',
    requires: [{ setID: 66, score: 200 }],
    Status: 'Active',
    reduxMap: 'tens.tenPlagues',
    id: 'b2f72d4b-5832-497e-8cda-895ceee2f90c',
    MasteryAt: 200,
  },
  {
    setID: 66,
    setName: '10 The Ten Commandments',
    Description: '',
    requires: [],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.tenCommandments',
    id: 'b3dbbdfa-d640-4d09-ab17-30d46ac197c9',
  },
  {
    setID: 67,
    setName: '10 Kings Scale Color',
    Description: '777 Column XV',
    requires: [{ setID: 40, score: 200 }],
    Status: 'Active',
    reduxMap: 'tens.kingsScaleColor',
    id: 'b2e8204a-4fdf-4aa6-beac-7d3ea2efa969',
    MasteryAt: 200,
  },
  {
    setID: 68,
    setName: '10 Queens Scale Color',
    Description: '777 Column XVI',
    requires: [{ setID: 40, score: 200 }],
    Status: 'Active',
    reduxMap: 'tens.queensScaleColor',
    id: '25b75970-457d-4945-8232-be76d8f3b5c2',
    MasteryAt: 200,
  },
  {
    setID: 69,
    setName: '10 Emperors Scale Color',
    Description: '777 Column XVII',
    requires: [{ setID: 40, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.emperorsScaleColor',
    id: '5df3aea8-a792-4859-ac27-1bee767042b4',
  },
  {
    setID: 70,
    setName: '10 Emperesses Scale Color',
    Description: '777 Column XVIII',
    requires: [{ setID: 40, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.empressesScaleColor',
    id: '656237ad-2503-4d71-a8ce-c14d88c0e8e5',
  },
  {
    setID: 71,
    setName: '10 Magical Birds',
    Description: '777 Column CLXXXVII (notes)',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.magicalBirds',
    id: 'af4bfa7c-f4d8-40b9-9c0e-6395818f6379',
  },
  {
    setID: 72,
    setName: '10 Magical Birds Formula',
    Description: '777 Column CLXXXVII (notes)',
    requires: [{ setID: 25, score: 100 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.magicalBirdsFormula',
    id: '6c7d2970-d345-44fb-9eb7-0c7c933be8a0',
  },
  {
    setID: 73,
    setName: '10 Fourty Buddhist Meditations',
    Description: '777 Column XXIII',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.fourtyBuddhistMeditations',
    id: '944e4ae0-0c3e-423a-be64-9876498063a6',
  },
  {
    setID: 74,
    setName: '10 Transcendental Morality',
    Description: '777 Column L',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.transcendentalMorality',
    id: '8dfeb658-4084-4fad-a12a-d65aa7e2ce32',
  },
  {
    setID: 75,
    setName: '10 Diseases',
    Description: '777 Column CLXXXVI',
    requires: [{ setID: 37, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.diseases',
    id: 'c41622cd-86e0-4b15-97ab-c20e476b2903',
  },
  {
    setID: 76,
    setName: '10 Egyptian Soul',
    Description: '777 Column CXVI',
    requires: [{ setID: 37, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.egyptianSoul',
    id: '4337049d-d6db-4724-9427-40ad7e5fc486',
  },
  {
    setID: 77,
    setName: '10 Hindu Soul',
    Description: '777 Column CXVII',
    requires: [{ setID: 37, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.hinduSoul',
    id: '9431c952-a9e4-494a-b4e4-d1c00f85b27e',
  },
  {
    setID: 78,
    setName: '10 Masonic Officers',
    Description: '777 Column CXV',
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.masonicOfficers',
    id: '984c0b1c-bdf7-4d73-9da4-51c4976af269',
  },
  {
    setID: 79,
    setName: '10 Chemical Elements of the Golden Dawn',
    Description:
      "Skinner's Complete Magicians Tables, E3. (A simple listing probably originally drawn up by the chemist Julian Baker.) Also note that each of these elements come from the 11 Reactive Nonmetals of the periodic table of elements, whereas the 11th could possibly be placed in Da'ath ",
    requires: [{ setID: 64, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.chemicalElementsGoldenDawn',
    id: '0c723786-69a2-436e-a33f-075cbad931f9',
  },
  {
    setID: 80,
    setName: '10 Islamic Angels',
    Description: "Skinner's Complete Magicians Tables, I13.",
    requires: [{ setID: 64, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.islamicAngels',
    id: '49668f87-5204-4579-90ec-eace0dc7d991',
  },
  {
    setID: 81,
    setName: '10 Arch-demons of the Qliphoth',
    Description: "Skinner's Complete Magicians Tables, K92.",
    requires: [{ setID: 64, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.archdemonsOfTheQliphoth',
    id: '9184ef97-1957-4990-95bb-e4ceb1875f98',
  },
  {
    setID: 82,
    setName: '10 Kings of Edom',
    Description: "Skinner's Complete Magicians Tables, K100-K102.",
    requires: [{ setID: 64, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.kingsOfEdom',
    id: '08311e71-8aaa-4e3e-82ec-2398db21994b',
  },
  {
    setID: 83,
    setName: '10 Dukes of Edom',
    Description: "Skinner's Complete Magicians Tables, K103-K105.",
    requires: [{ setID: 64, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.dukesOfEdom',
    id: 'a0875fe5-5a1c-4351-813a-59511db0a4c7',
  },
  {
    setID: 84,
    setName: '10 Sumerian Gods',
    Description: "Skinner's Complete Magicians Tables, P1.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.sumerianGods',
    id: '2937d0eb-aa0d-4f7b-b017-e8b806dfc777',
  },
  {
    setID: 85,
    setName: '10 Mesopotamian Gods',
    Description: "Skinner's Complete Magicians Tables, P2.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.mesopotamianGods',
    id: 'b54eae39-ecdd-4ca7-ac5b-e0a4ff2720cc',
  },
  {
    setID: 86,
    setName: '7 Babylonian Planetary Gods',
    Description: "Skinner's Complete Magicians Tables, P3.",
    requires: [{ setID: 8, score: 140 }],
    Status: 'Active',
    MasteryAt: 140,
    reduxMap: 'sevens.babylonianPlanetaryGods',
    id: 'c94bd4f7-3886-4137-8dbf-fb05044bce6b',
  },
  {
    setID: 87,
    setName: '10 Persian Gods',
    Description: "Skinner's Complete Magicians Tables, P5.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.persianGods',
    id: '3321a86f-1df8-4fd2-a8eb-e3715d2d8d9b',
  },
  {
    setID: 88,
    setName: '10 Gnostic Gods',
    Description: "Skinner's Complete Magicians Tables, P13.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.gnosticGods',
    id: 'b7f4927a-5dfe-4847-b88f-321dc953acb1',
  },
  {
    setID: 89,
    setName: '10 Orphic Gods',
    Description: "Skinner's Complete Magicians Tables, P19.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.orphicGods',
    id: '810cdb98-7614-4a8c-81c1-6f0e8f9f12bb',
  },
  {
    setID: 90,
    setName: '10 Greek Muses',
    Description: "Skinner's Complete Magicians Tables, P20.",
    requires: [{ setID: 37, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.greekMuses',
    id: '80812953-5606-4d0c-ad52-545fafd71bb5',
  },
  {
    setID: 91,
    setName: '10 Etruscan Gods',
    Description: "Skinner's Complete Magicians Tables, P23.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.etruscianGods',
    id: 'cb507993-fea0-40e1-a429-8dc0cad4b920',
  },
  {
    setID: 92,
    setName: '10 Shinto Gods',
    Description: "Skinner's Complete Magicians Tables, P31.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.shintoGods',
    id: '28d8114d-976e-4783-98da-cbe26d990fb2',
  },
  {
    setID: 93,
    setName: '10 Taoist Gods',
    Description: "Skinner's Complete Magicians Tables, P32.",
    requires: [
      { setID: 25, score: 200 },
      { setID: 49, score: 200 },
    ],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.taoistGods',
    id: 'fe36c5bf-0c88-4366-a339-22a7baf7ab6b',
  },
  {
    setID: 94,
    setName: '10 Knights of the Round Table',
    Description: "Skinner's Complete Magicians Tables, Q2.",
    requires: [{ setID: 42, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'knightsOfTheRoundTable',
    id: '0d10ed09-1ecb-4309-8641-80ac6102801c',
  },
  {
    setID: 95,
    setName: '12 Angels of the Day',
    Description: '777 CLXXIII (notes)',
    requires: [{ setID: 100, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.angelsOfTheDay',
    id: '147da961-21a5-4d8d-b169-661db164510f',
  },
  {
    setID: 96,
    setName: '12 Angels of the Night',
    Description: '777 CLXXIII (notes)',
    requires: [{ setID: 100, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.angelsOfTheNight',
    id: '4752f84e-50b0-439b-b0c9-7cdcaf5e6e63',
  },
  {
    setID: 97,
    setName: '12 Body of Man',
    Description: '777 ',
    requires: [{ setID: 100, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.bodyOfMan',
    id: '98415cff-13b5-4eb4-b257-51552da09968',
  },
  {
    setID: 98,
    setName: '12 Hebrew Zodiac',
    Description: '777 Column VI',
    requires: [
      { setID: 9, score: 250 },
      { setID: 161, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.hebrewZodiac',
    id: 'f62b1a0b-eef8-4a06-80dc-631992b0a982',
  },
  {
    setID: 99,
    setName: '12 Hebrew Calendar',
    Description: '777',
    requires: [
      { setID: 9, score: 250 },
      { setID: 161, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.hebrewCalendar',
    id: 'b7e78320-77b4-47f9-9ef4-94c9c69b373d',
  },
  {
    setID: 100,
    setName: '12 Ordinal Positions of the 12',
    Description:
      'In this exercise, you are given a set of numbers that represent the 12 paths on the Tree of Life which are mapped to the signs of the Zodiac. So the number 1 would map to the Sign of Aries.',
    requires: [],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.ordinalPositions',
    id: 'ae14886f-3b4d-425e-a07b-9673c1cc6ffa',
  },
  {
    setID: 101,
    setName: '12 Senses of Man',
    Description: '777',
    requires: [],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.sensesOfMan',
    id: '28b415f0-5860-4ba7-8929-ac823dcfbe50',
  },
  {
    setID: 102,
    setName: '12 Egyptian Gods of the Zodiac',
    Description: '777',
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.egyptianGodsOfTheZodiac',
    id: '132aabd8-5a08-43c8-ba43-7fd5a562eeca',
  },
  {
    setID: 103,
    setName: '12 The Apostles',
    Description: '777 Column XXXVI (notes)',
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.theApostles',
    id: 'bbbab14c-b6a9-43cb-8b63-9ddf237b0aa5',
  },
  {
    setID: 104,
    setName: '12 Geomantic Figures',
    Description: '777 Column XLIX',
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.geomanticFigures',
    id: '42bcdab0-eeee-4ab8-b084-45ef719760e6',
  },
  {
    setID: 105,
    setName: '12 Orders of Qliphoth',
    Description: '777 Column VIII',
    requires: [{ setID: 101, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.ordersOfQliphoth',
    id: '76e62843-687c-44dd-a1ed-5eca09234a49',
  },
  {
    setID: 106,
    setName: '12 Orders of Qliphoth English',
    Description: '777 Column VIII',
    requires: [{ setID: 101, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.ordersOfQliphothEnglish',
    id: '56d3a284-0def-4713-b5c8-e0e0991812bc',
  },
  {
    setID: 107,
    setName: '12 Perfumes Ascendant',
    Description: '777 Column CLII',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.perfumesAscendent',
    id: '8103e86e-5b77-4023-9c9c-8df83b90565e',
  },
  {
    setID: 108,
    setName: '12 Perfumes Succedent',
    Description: '777 Column CLII',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.perfumesSuccedent',
    id: '8e447afb-9d6e-458d-be99-fde933f340ab',
  },
  {
    setID: 109,
    setName: '12 Perfumes Cadent',
    Description: '777 Column CLIV',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.perfumesCadent',
    id: '8d050cc6-b326-4d7c-9101-d5f732a51589',
  },
  {
    setID: 110,
    setName: '12 Goetic Demons of the Day Ascendant',
    Description: '777 Column CLV',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.goeticDemonsDayAsc',
    id: '4cc1ef05-1e2d-45ac-a7bc-81a6f9243476',
  },
  {
    setID: 111,
    setName: '12 Goetic Demons of the Day Succedent',
    Description: '777 Column CLV',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.goeticDemonsDaySuc',
    id: 'cc2f80a0-76f8-4dc8-9fa8-7df9790a513d',
  },
  {
    setID: 112,
    setName: '12 Goetic Demons of the Day Cadent',
    Description: '777 Column CLV',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.goeticDemonsDayCad',
    id: 'bdbf06df-3f7a-4012-bfd9-9754a5166549',
  },
  {
    setID: 113,
    setName: '12 Goetic Demons of the Night Ascendant',
    Description: '777 Column CLV',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.goeticDemonsNightAsc',
    id: 'b0af2554-76da-492b-a523-2f8af5d28917',
  },
  {
    setID: 114,
    setName: '12 Goetic Demons of the Night Succedent',
    Description: '777 Column CLV',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.goeticDemonsNightSuc',
    id: '17adde18-7bfc-4ad5-9ab7-869d5a7dd50b',
  },
  {
    setID: 115,
    setName: '12 Goetic Demons of the Night Cadent',
    Description: '777 Column CLV',
    requires: [
      { setID: 100, score: 250 },
      { setID: 9, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.goeticDemonsNightCad',
    id: '1fccc180-3fd1-4b93-a8a7-9687ce51a971',
  },
  {
    setID: 116,
    setName: '12 The Twelve Tribes',
    Description:
      '777 Column CXLI <br />These are the names of the original twelve tribes of Israel from the Old Testament.',
    requires: [
      { setID: 9, score: 250 },
      { setID: 161, score: 250 },
    ],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.twelveTribes',
    id: '38bf0c7a-4a04-41cb-b95e-f82418479403',
  },
  {
    setID: 117,
    setName: '12 Chinese Zodiac',
    Description: "Stephen Skinner's Complete Magicians Tables F27",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.chineseZodiac',
    id: '5e2ab102-285b-4b41-9d1c-cb45a64dbaaa',
  },
  {
    setID: 118,
    setName: '12 Geomantic Figures English',
    Description: "Stephen Skinner's Complete Magicians Tables F27",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.geomanticFiguresEnglish',
    id: '0d03e643-0cc0-4cb3-86ee-2a693274dd4b',
  },
  {
    setID: 119,
    setName: '12 Arabic Zodiac Names',
    Description: "Stephen Skinner's Complete Magicians Tables H21",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.arabicZodiacNames',
    id: '7be4b8fa-ecfc-4f08-bc37-999bce378c3b',
  },
  {
    setID: 120,
    setName: '12 Hindu Zodiac Names',
    Description: "Stephen Skinner's Complete Magicians Tables H23",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.hinduZodiacNames',
    id: '947307cc-aa57-4c65-a2b8-d29306c426d3',
  },
  {
    setID: 121,
    setName: '12 Sanskrit Zodiac Names',
    Description: "Stephen Skinner's Complete Magicians Tables H24",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.sanskritZodiacNames',
    id: '63658989-379f-4c3f-bae8-d1da0a8733d9',
  },
  {
    setID: 122,
    setName: '12 Greek Zodiac Names',
    Description: "Stephen Skinner's Complete Magicians Tables H14",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.greekZodiacNames',
    id: '3189c452-3d8f-4897-bfc3-1ed7b8ab406c',
  },
  {
    setID: 123,
    setName: '12 Horoscope Houses',
    Description: "Stephen Skinner's Complete Magicians Tables H36",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.horoscopeHouses',
    id: 'bec09b91-2a15-48ff-8e3c-d12f32bca3d4',
  },
  {
    setID: 124,
    setName: '12 Breastplate of Aaron',
    Description: "Stephen Skinner's Complete Magicians Tables N10",
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.breastplate',
    id: '26185381-c1eb-42ef-bf2d-85e7628960bd',
  },
  {
    setID: 125,
    setName: '22 Legendary Orders of Being',
    Description: '777 Column CLXXXIII',
    requires: [{ setID: 4, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.legendaryOrdersOfBeing',
    id: '38a47997-a801-49ed-8ec2-8dd3ece3925f',
  },
  {
    setID: 126,
    setName: '22 Perfumes',
    Description: '777 Column XLII',
    requires: [{ setID: 130, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.perfumes',
    id: '2748d6ce-ec34-4c8c-88a3-cc90828de442',
  },
  {
    setID: 127,
    setName: '22 Mystic Numbers',
    Description: '777 Column X',
    requires: [{ setID: 4, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.mysticNumbers',
    id: '79ddeb0f-0401-4bae-b1ab-7d948cb9f2e5',
  },
  {
    setID: 128,
    setName: '22 Vegetable Drugs',
    Description: '777 Column XLIII',
    requires: [{ setID: 130, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.vegetableDrugs',
    id: '343d465d-3525-47e4-a885-acd0a41c351d',
  },
  {
    setID: 129,
    setName: '22 Precious Stones',
    Description: '777 Column XL',
    requires: [{ setID: 130, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.preciousStones',
    id: '1f485d8b-6769-48c2-a45a-28113d82d3ed',
  },
  {
    setID: 130,
    setName: '22 The Human Body',
    Description: '777 Column CLXXXII',
    requires: [],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.humanBody',
    id: 'd88365d2-5f43-4bbc-aea8-5e4f3936ac5b',
  },
  {
    setID: 131,
    setName: '22 Roman Gods',
    Description: '777 Column XXXV',
    requires: [{ setID: 137, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.romanGods',
    id: '3264f010-c234-4c2f-a43b-ba62bd19ffbe',
  },
  {
    setID: 132,
    setName: '22 Greek Gods',
    Description: '777 Column XXXIV',
    requires: [{ setID: 137, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.greekGods',
    id: 'bef2c018-158e-4eec-bc6d-5afac3116957',
  },

  {
    setID: 133,
    setName: '22 Kings Scale Color',
    Description: '777 Column XXXIV',
    requires: [{ setID: 134, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.kingsScaleColor',
    id: 'a2a6b1f8-2239-4e86-b87f-0c91d40a178c',
  },
  {
    setID: 134,
    setName: '22 Queens Scale Color',
    Description: '777 Column XXXIV',
    requires: [{ setID: 134, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.queensScaleColor',
    id: '82e09a5e-a763-410d-a950-fb6e1969d3ca',
  },
  {
    setID: 135,
    setName: '22 Emperors Scale Color',
    Description: '777 Column XXXIV',
    requires: [{ setID: 134, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.emperorsScaleColor',
    id: 'f10ac0f8-c001-4c72-b0b8-1434422d0891',
  },
  {
    setID: 136,
    setName: '22 Empresses Scale Color',
    Description: '777 Column XXXIV',
    requires: [{ setID: 134, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.empressesScaleColor',
    id: '53aa5ca5-acf2-4634-84ff-7cdc6c42c32b',
  },
  {
    setID: 137,
    setName: '22 Tarot Roman Numerals',
    Description:
      'This collection is a list of the ordinal positions of the THOTH TAROT cards in their Roman Numberal values. Note should be taken that the values of Lamed and Teth (Libra and Leo) seem to be reversed.   777 Column CLXXXIX',
    requires: [],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.tarotRomanNumerals',
    id: 'cc317545-d932-4b52-9c3a-6b9853bf8a14',
  },
  {
    setID: 138,
    setName: '22 Fourty Buddhist Meditations',
    Description: '777 Column XXIII',
    requires: [{ setID: 130, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.fourtyBuddhistMeditations',
    id: '079e2e93-08a7-43b9-876a-2a4455523978',
  },
  {
    setID: 139,
    setName: '22 Animals Real and Imaginary',
    Description: '777 Column XXXVIII',
    requires: [{ setID: 130, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.animalsRealAndImaginary',
    id: 'b7c2a082-f7ae-443e-8732-776ef21443ea',
  },
  {
    setID: 140,
    setName: '22 Magical Powers',
    Description: '777 Column XLV',
    requires: [{ setID: 4, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.magicalPowers',
    id: 'bb65207f-4b49-46d4-9b88-551eb8e27502',
  },
  {
    setID: 141,
    setName: '22 Transcendental Morality',
    Description: '777 Column L',
    requires: [{ setID: 130, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.transcendentalMorality',
    id: 'cc603712-47a0-40f4-8bfd-905e8b82a785',
  },
  {
    setID: 142,
    setName: '22 Diseases',
    Description: '777 Column CLXXXVI',
    requires: [{ setID: 130, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.diseases',
    id: 'baf626f1-7d77-4e12-8bae-d10380cea6e7',
  },
  {
    setID: 143,
    setName: '22 Hindu Dieties',
    Description: '777 Column XXII',
    requires: [{ setID: 137, score: 350 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.hinduDeities',
    id: 'c15fbd37-eeaf-4049-9224-a0fa6883b4fa',
  },
  {
    setID: 144,
    setName: '32 All Ordinal Positions',
    Description: '777 General',
    requires: [],
    Status: 'Active',
    MasteryAt: 400,
    reduxMap: 'thirtyTwos.allOrdinalPositions',
    id: '39a8b688-f941-4045-94ac-5dda4975b870',
  },
  {
    setID: 145,
    setName: '22 The English Alphabet',
    Description: '777 General',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.englishAlphabet',
    id: '5f8d7cfe-2ca5-4a1d-bde9-64c8887e9b7a',
  },
  {
    setID: 146,
    setName: '22 The Greek Alphabet',
    Description: 'Skinners Complete Magicians Tables L13',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.greekAlphabet',
    id: 'e2851db1-efa8-4f49-9bc5-81b4deb3f068',
  },
  {
    setID: 147,
    setName: '22 The Greek Alphabet English',
    Description: 'Skinners Complete Magicians Tables L15',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.greekAlphabetNames',
    id: 'f2b991d5-dac0-4dda-83f2-926fa7890c2e',
  },
  {
    setID: 148,
    setName: '22 The Greek Alphabet Number Values',
    Description: 'Skinners Complete Magicians Tables L16',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.greekAlphabetNumberValues',
    id: '678c5388-65da-4dce-a6a7-fd7636e215e7',
  },
  {
    setID: 149,
    setName: '22 The Arabic Alphabet',
    Description: 'Skinners Complete Magicians Tables L28',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.arabaicAlphabetNames',
    id: '5dd88f5b-b1df-4c9e-922a-ee691272b3cb',
  },
  {
    setID: 150,
    setName: '22 The Arabic Alphabet English',
    Description: 'Skinners Complete Magicians Tables L30',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.arabicAlphabet',
    id: '0eec7142-4060-4ae1-beef-41b945580d51',
  },
  {
    setID: 151,
    setName: '22 The Arabic Alphabet Values',
    Description: 'Skinners Complete Magicians Tables L31',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.arabicAlphabetValues',
    id: '71360965-f343-4d13-a8a5-e1e41a00635e',
  },
  {
    setID: 152,
    setName: '4 Powers of the Sphinx Latin',
    Description: 'Levi, Transcendental Magic',
    requires: [{ setID: 26, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.powersOfSphinx',
    id: '99d33075-9f4f-44a2-a3cb-a22e55058a2b',
  },
  {
    setID: 153,
    setName: '22 Elder Futhark',
    Description: 'Skinners Complete Magicians Tables L35 - L37',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.elderFuthark',
    id: '81a1b16a-cd53-46b6-be9e-56d65eb8dcf5',
  },
  {
    setID: 154,
    setName: '22 Elder Futhark Names',
    Description: 'Skinners Complete Magicians Tables L35 - L37',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.elderFutharkNames',
    id: '599a9d39-fe3a-47e5-a52e-c2aff106559a',
  },
  {
    setID: 155,
    setName: '22 Enochian Alphabet English',
    Description: 'Skinners Complete Magicians Tables L43',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.enochianAlphabetNames',
    id: '782823e3-65d6-4c6a-a562-dee60bc169ea',
  },
  {
    setID: 156,
    setName: '22 Enochian Alphabet',
    Description: 'Skinners Complete Magicians Tables L43',
    requires: [
      { setID: 1, score: 350 },
      { setID: 162, score: 350 },
    ],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.enochianAlphabet',
    id: 'f1f49123-36b0-4587-b827-582d4e235a34',
  },
  {
    setID: 157,
    setName: '12 Geomantic Symbols',
    Description: 'Skinners Complete Magicians Tables G1',
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.geomanticFiguresSymbols',
    id: '98b80497-6ad9-430a-9694-c81c5a013429',
  },

  {
    setID: 158,
    setName: '4 God Names Hebrew',
    Description: 'Misc',
    requires: [{ setID: 24, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.godNamesHebrew',
    id: 'ee73f218-eeee-4be1-a1ad-6ad10ee4d832',
  },

  {
    setID: 159,
    setName: '12 Zodiac Symbols',
    Description: '777 General',
    requires: [],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.zodiacSymbols',
    id: '5501cc23-3e7d-4080-be32-2cf060859d3d',
  },

  {
    setID: 160,
    setName: '10 Choirs of Angels in Briah',
    Description: '777 Column LXXXVI',
    requires: [{ setID: 64, score: 200 }],
    Status: 'Active',
    MasteryAt: 200,
    reduxMap: 'tens.choirsOfAngels',
    id: 'e615ffcf-5f58-4459-9fc8-e9d6fc991423',
  },
  {
    setID: 161,
    setName: '12 Hebew Single Letters',
    Description: 'Misc',
    requires: [{ setID: 9, score: 250 }],
    Status: 'Active',
    MasteryAt: 250,
    reduxMap: 'twelves.singleLetters',
    id: '14be9140-e902-48b4-9f58-09d1444736ef',
  },

  {
    setID: 162,
    setName: '4 The Elementals',
    Description: 'Stephen Skinners The Complete Magicians Tables',
    requires: [{ setID: 163, score: 100 }],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.theElementals',
    id: '7f96794b-325a-47d1-9cc8-9c2440963c00',
  },
  {
    setID: 163,
    setName: '4 The Elements',
    Description: 'Stephen Skinners The Complete Magicians Tables',
    requires: [],
    Status: 'Active',
    MasteryAt: 100,
    reduxMap: 'fours.theElements',
    id: '1dc69d8d-affb-458b-bceb-c2f514e01ee4',
  },
  {
    setID: 164,
    setName: '22 Golden Dawn Tarot Titles',
    Description: 'Skinners Complete Magicians Tables T3',
    requires: [{ setID: 25, score: 100 }],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.goldenDawnTarotTitles',
    id: 'b4d65e9c-18ee-4bfb-8e2d-5e0e7d87690d',
  },
  {
    setID: 165,
    setName: '32 The Heavens of Assiah',
    Description: 'Skinners Complete Magicians Tables K53',
    requires: [{ setID: 2, score: 400 }],
    Status: 'Active',
    MasteryAt: 400,
    reduxMap: 'thirtyTwos.heavensOfAssiah',
    id: 'f0f39003-feb8-4113-8f78-d90690d60fb2',
  },
  {
    setID: 166,
    setName: '32 The Heavens of Assiah Hebrew',
    Description: 'Skinners Complete Magicians Tables K52',
    requires: [{ setID: 2, score: 400 }],
    Status: 'Active',
    MasteryAt: 400,
    reduxMap: 'thirtyTwos.heavensOfAssiahHebrew',
    id: '4e03776a-526f-4bfc-97b9-d70b5a7c8cc8',
  },
  {
    setID: 167,
    setName: '22 Hebew Letters English',
    Description: 'Misc',
    requires: [],
    Status: 'Active',
    MasteryAt: 350,
    reduxMap: 'twentyTwos.hebrewLettersEnglish',
    id: '90cdf8b8-f723-4bb8-acd9-a30eb941e9c1',
  },
];
export default setData;

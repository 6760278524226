import Immutable from 'immutable';
import { EXPORT_LOGIN } from '../lapisUser/actions';
import { EXPORT_SET_DATA } from './actions';
const scores = (state = new Immutable.Map(), action) => {
  switch (action.type) {
    case EXPORT_LOGIN:
      return state.merge(
        Immutable.fromJS(
          action.payload[0].scoreData.reduce((map, property) => {
            map[property.id] = property;
            return map;
          }, {})
        )
      );
    case EXPORT_SET_DATA:
      return state.merge(
        Immutable.fromJS(
          action.payload.scores.reduce((map, property) => {
            map[property.id] = property;
            return map;
          }, {})
        )
      );
    default:
      return state;
  }
};

export default scores;

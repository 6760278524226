import Immutable from 'immutable';
import { siteCopyList } from '../../data/siteCopy';

const siteCopy = (state = new Immutable.Map(), action) => {
  return state.merge(
    Immutable.fromJS(
      siteCopyList.reduce((map, property) => {
        map[property.id] = property;
        return map;
      }, {})
    )
  );
};

export default siteCopy;

import ImageAndTextLeft from '../components/ImageAndTextLeft';
import ImageAndTextRight from '../components/ImageAndTextRight';
import PageBanner from '../components/PageBanner';
import SectionBody from '../components/SectionBody';
import SectionHeading from '../components/SectionHeading';
import SectionSubHeading from '../components/SectionSubHeading';
import { getSiteCopyById } from '../shared/utilities';

function DonationsView() {
  return (
    <>
      <PageBanner BannerImage={`url('images/top-banner.jpg')`} BannerTitle="Donations" />
      <div style={{ paddingTop: '1px', backgroundColor: '#ffffff' }}>
        <section id="donation" className="donation-main-block donation-two">
          <div className="container">
            <div className="abt-content">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="abt-sectionOne">
                    <SectionHeading Heading={getSiteCopyById('donations.title')} />
                    <SectionSubHeading Heading={getSiteCopyById('donations.heading')} />
                    <SectionBody Heading={getSiteCopyById('donations.body')} />
                  </div>
                </div>
              </div>
              <ImageAndTextLeft
                Image="images/donation/donations1.jpg"
                HeaderText={getSiteCopyById('donations.questions.heading')}
                BodyText={getSiteCopyById('donations.questions.body')}
                ButtonLink="https://www.paypal.com/donate/?hosted_button_id=6TJXRUJ8FBPB4"
                ButtonText="Donate via PayPal"
              />
            </div>
            <br />
          </div>
        </section>
      </div>
    </>
  );
}
export default DonationsView;
//

import React from 'react';
import { getSiteCopyById } from '../shared/utilities';
import ImageAndTextLeft from './ImageAndTextLeft';
import ImageAndTextRight from './ImageAndTextRight';
import SectionBody from './SectionBody';
import SectionHeading from './SectionHeading';
import SectionSubHeading from './SectionSubHeading';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function EGCContent() {
  return (
    <div className="abt-content">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="abt-sectionOne">
            <SectionHeading Heading={getSiteCopyById('about.egc.title')} />
            <SectionSubHeading Heading={getSiteCopyById('about.egc.subtitle')} />
            <SectionBody Heading={getSiteCopyById('lapis.egc.body')} />
          </div>
        </div>
      </div>

      <Carousel
        centerMode={false}
        centerSlidePercentage={100}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        showStatus={false}
        selectedItem={1}
        interval={6000}
      >
        <ImageAndTextLeft
          ButtonLink={null}
          ButtonText={null}
          Image="images/temple/temple3.jpg"
          HeaderText={getSiteCopyById('lapis.egc.left-to-right.title')}
          BodyText={getSiteCopyById('lapis.egc.left-to-right.body')}
        />
        <ImageAndTextRight
          ButtonLink={null}
          ButtonText={null}
          Image="images/egc/egc3.jpg"
          HeaderText={getSiteCopyById('lapis.egc.right-to-left.title')}
          BodyText={getSiteCopyById('lapis.egc.right-to-left.body')}
        />
        <ImageAndTextLeft
          ButtonLink={null}
          ButtonText={null}
          Image="images/egc/egc2.jpg"
          HeaderText={getSiteCopyById('lapis.egc.left-to-right2.title')}
          BodyText={getSiteCopyById('lapis.egc.left-to-right2.body')}
        />
        <ImageAndTextRight
          ButtonLink={null}
          ButtonText={null}
          Image="images/egc/egc4.jpg"
          HeaderText={getSiteCopyById('lapis.egc.right-to-left2.title')}
          BodyText={getSiteCopyById('lapis.egc.right-to-left2.body')}
        />
      </Carousel>

      <br />
    </div>
  );
}
export default EGCContent;

import React from 'react';
import { useSelector } from 'react-redux';
import ImageAndTextRight from '../components/ImageAndTextRight';
import PageBanner from '../components/PageBanner';
import QuotesBlock from '../components/Quotes';
import { getRandomBackground, getSiteCopyById } from '../shared/utilities';
import { ImmutableQuoteItem } from '../types';
function AAView() {
  const quoteData = useSelector((state: any) => {
    return state.quotes.quotes.filter((item: ImmutableQuoteItem) => {
      return item.get('Page').toLocaleLowerCase() === 'aa';
    });
  });
  return (
    <>
      <PageBanner BannerTitle="About A∴ A∴" BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ backgroundImage: getRandomBackground() }}>
        <section id="about-block" className="about-main-block abt-two" style={{ marginTop: '0' }}>
          <div className="container" style={{ backgroundColor: '#ffffff' }}>
            <br />
            <div className="abt-img">
              <img src="images/aa/aa2.jpg" className="img-responsive" alt="abt-img-1" />
            </div>
            <ImageAndTextRight
              ButtonLink={null}
              ButtonText={null}
              Image="images/aa/rays.png"
              HeaderText={getSiteCopyById('about.aa.heading')}
              BodyText={getSiteCopyById('about.aa.body')}
            />
          </div>
          <QuotesBlock Quotes={quoteData} />
        </section>
      </div>
    </>
  );
}
export default AAView;

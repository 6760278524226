export const duesList = [
  {
    id: 1,
    name: 'Minerval Dues',
    image: 'images/donation/donation-01.jpg',
    description:
      'As a Minerval member of the Order, you are requested to pay a reduced monthly amount to support the order.',
    fee: '$10.00',
    occurance: 'month',
    payPalSubscriptionLink: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-86K98029UP928692SMIUUYQI',
  },
  {
    id: 2,
    name: `All Other Degrees Dues`,
    image: 'images/donation/donation-02.jpg',
    description: 'As a full member of the Order, you are requested to pay a full monthly amount to support the order.',
    fee: '$25.00',
    occurance: 'month',
    payPalSubscriptionLink: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-32772776B5653514GMIUVISQ',
  },
  {
    id: 3,
    name: `Club Balalon`,
    image: 'images/donation/babalon1.png',
    description: 'This is an optional subscription for those who wish to contribute more on a monthly basis.',
    fee: '$100.00',
    occurance: 'month',
    payPalSubscriptionLink: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-1VN67649659980600MI4S2AI',
  },
];

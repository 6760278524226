/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { siteAddress } from '../shared/utilities';
import { Link } from 'react-router-dom';

function NavBar() {
  return (
    <div className="nav-bar navbar-fixed-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
            <div className="logo">
              <Link to={`/`}>
                <img style={{ height: '82px' }} src={`${siteAddress}images/logo.png`} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
            <div className="navigation-menu">
              <div id="cssmenu">
                <div id="menu-button">Menu</div>
                <ul>
                  <li className="has-sub">
                    <span className="submenu-button"></span>
                    <Link to={`/`}>Home</Link>
                  </li>
                  <li className="has-sub">
                    <span className="submenu-button"></span>
                    <a href="#">About</a>
                    <ul>
                      <li>
                        <Link to={`about`}>About Us</Link>
                      </li>
                      <li>
                        <Link to={`thelema`}>About Thelema</Link>
                      </li>
                      <li>
                        <Link to={`oto`}>O.T.O.</Link>
                      </li>
                      <li>
                        <Link to={`egc`}>EGC</Link>
                      </li>
                      <li>
                        <Link to={`initiation`}>Initiation</Link>
                      </li>
                      <li>
                        <Link to={`aa`}>A∴ A∴</Link>
                      </li>
                      <li>
                        <Link to={`masonry`}>O.T.O. and Masonry</Link>
                      </li>
                      <li>
                        <Link to={`officers`}>Officers</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-sub">
                    <span className="submenu-button"></span>
                    <a href="#">Donations</a>
                    <ul>
                      <li>
                        <Link to={`donate`}>Donate</Link>
                      </li>
                      <li>
                        <Link to={`dues`}>Dues</Link>
                      </li>
                    </ul>
                  </li>

                  <li className="has-sub">
                    <span className="submenu-button"></span>
                    <a href="#">Events</a>
                    <ul>
                      <li>
                        <Link to={`events`}>Events</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-sub">
                    <span className="submenu-button"></span>
                    <a href="#">Resources</a>
                    <ul>
                      <li>
                        <Link to={`library`}>Library</Link>
                      </li>
                      <li>
                        <Link to={`download`}>Downloads</Link>
                      </li>{' '}
                      <li>
                        <Link to={`newsletters`}>Newsletters</Link>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <Link to={`contact`}>Contact</Link>
                  </li>
                  <li>
                    <Link to={`social`}>Social</Link>
                  </li>
                  <li>
                    <Link to={`studyGuide`}>Play</Link>
                  </li>
                  <li>
                    <Link to={`user`}>My Lapis</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default NavBar;

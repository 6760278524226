import React from 'react';
import PageBanner from '../components/PageBanner';
import TextBlock from '../components/TextBlock';
import { getRandomBackground, getSiteCopyById } from '../shared/utilities';
function OTOView() {
  return (
    <>
      <PageBanner BannerTitle="About O.T.O." BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ backgroundImage: getRandomBackground() }}>
        <section id="about-block" className="about-main-block abt-two" style={{ marginTop: '0' }}>
          <div className="container" style={{ backgroundColor: '#ffffff' }}>
            <br />
            <div className="abt-img">
              <img src="images/oto/oto1.jpg" className="img-responsive" alt="abt-img-1" />
            </div>

            <TextBlock
              Heading={getSiteCopyById('about.oto.heading')}
              SubHeading=""
              Body={getSiteCopyById('about.oto.body')}
            />
          </div>
        </section>
      </div>
    </>
  );
}
export default OTOView;

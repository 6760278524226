import React from 'react';
import { safeHtml } from '../shared/utilities';
import { ImageAndTextProps } from '../types';
function ImageAndTextRight(props: ImageAndTextProps) {
  return (
    <div className="abt-sectionTwo">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="abt-dtl">
            <h5 className="abt-subheading">{props.HeaderText}</h5>
            <p>{safeHtml(props.BodyText)}</p>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
          <div className="abt-block-img">
            <img src={props.Image} className="img-responsive" alt="abt-img-02" />
            {props.ButtonLink ? (
              <span className="donation-btn">
                <a href={props.ButtonLink} style={{ background: '#0070ba', height: '50px', width: '220px', fontSize: '20px' }} className="btn btn-default">
                  {props.ButtonText}
                </a>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ImageAndTextRight;

import React from 'react';
import PageBanner from '../components/PageBanner';
import { useSelector } from 'react-redux';
import { ImmutableEventListItem, ImmutableSocialMediaItem } from '../types';

function SocialView() {
  const events = useSelector((state: any) => {
    return state.socialLinkList;
  });

  const renderEventItems = () => {
    let duesItems: any = [];
    events.forEach((eventItem: ImmutableSocialMediaItem) => {
      duesItems.push(
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 row-top-spacing">
          <div className="upcoming-block">
            <div className="upcoming-img block-effect">
              <a target="_blank" href={`${eventItem.get('link')}`} rel="noreferrer">
                <img src={eventItem.get('image')} className="img-responsive" alt="upcoming-img-01" />
                <div style={styles.eventTime}></div>
              </a>
            </div>
            <div className="upcoming-content">
              <a target="_blank" href={`${eventItem.get('link')}`} rel="noreferrer">
                <h4 className="upcoming-heading">{eventItem.get('title')}</h4>
              </a>
              <p className="upcoming-dtl">{eventItem.get('title')}</p>
            </div>
          </div>
        </div>
      );
    });
    return duesItems;
  };

  return (
    <>
      <PageBanner BannerImage={`url('images/top-banner.jpg')`} BannerTitle="Social Media" />

      <section
        id="upcoming-events"
        className="upcoming-main-block"
        style={{ backgroundImage: "url('images/thothbg1.jpg')" }}
      >
        <div className="container">
          <div className="row">{renderEventItems()}</div>
        </div>
      </section>
    </>
  );
}
const styles = {
  eventTime: {
    marginTop: '-60px',
    width: '100%',
    paddingLeft: '20px',
    color: '#ffffff',
    fontFamily: 'Playfair Display',
    fontSize: '23pt',
    textAlign: 'center' as 'center',
  },
};
export default SocialView;

export const degreeList = [
  {
    id: -1,
    name: '',
    initiationFee: 0,
    annualDuesGL: 0,
    monthlyDues: 0,
    color: '',
    number: '',
    triad: '',
    description: '',
  },
  {
    id: 0,
    name: 'Minerval',
    initiationFee: 42,
    annualDuesGL: 42,
    monthlyDues: 10,
    color: 'rgb(31 101 162 /59%)',
    number: '0',
    triad: 'Man of Earth',
    description:
      '"The Minerval degree is a Prologue to the First; the degrees subsequent to the Third but pendants to it. In this, the Man of Earth series, there are then but Three Degrees; and these Three are One."—Liber CXCIV <br /><br />"I begin by showing the object of the pure soul, "One, individual and eternal", in determining to formulate itself consciously, or, as I may say, to understand itself. It chooses to enter into relations with the solar system. It incarnates."—The Confessions of Aleister Crowley The Ego is attracted to the Solar System."<br /><br />"The Ego is attracted to the Solar System."-Magick Without Tears, Chapter XIII ‘System of the O.T.O’',
  },
  {
    id: 1,
    name: 'Man and Brother, or Woman and Sister',
    initiationFee: 42,
    annualDuesGL: 42,
    monthlyDues: 25,
    color: 'rgb(31 101 162 /59%)',
    number: 'I°',
    triad: 'Man of Earth',
    description:
      '"It incarnates. I explain the significance of birth and the conditions established by the process."—The Confessions of Aleister Crowley<br /><br />"The Child experiences Birth."—Magick Without Tears, Chapter XIII System of the O.T.O.’',
  },
  {
    id: 2,
    name: 'Magician',
    initiationFee: 42,
    annualDuesGL: 84,
    monthlyDues: 25,
    color: 'rgb(31 101 162 /59%)',
    number: 'II°',
    triad: 'Man of Earth',
    description:
      '"I next show how it may best carry out its object in the eucharist of life. It partakes, so to speak, of its own godhead in every action, but especially through the typical sacrament of marriage, understood as the voluntary union of itself with each element of its environment."—The Confessions of Aleister Crowley><br /><br />“The Man experiences Life… All subsequent Degrees of the O.T.O. are accordingly elaborations of the II°, since in a single ceremony it is hardly possible to sketch, even in the briefest outline, the Teaching of Initiates with regard to Life."—Magick Without Tears, Chapter XIII ‘System of the O.T.O.’"',
  },
  {
    id: 3,
    name: 'Master Magician',
    initiationFee: 84,
    annualDuesGL: 126,
    monthlyDues: 25,
    color: 'rgb(31 101 162 /59%)',
    number: 'III°',
    triad: 'Man of Earth',
    description:
      '"I then proceed to the climax of its career in death and show how this sacrament both consecrates (or, rather, sets its seal upon) the previous procedure and gives a meaning thereto, just as the auditing of the account enables the merchant to see his year’s transactions in perspective."—The Confessions of Aleister Crowley<br /><br />"He experiences Death."—Magick Without Tears, Chapter XIII ‘System of the O.T.O.’',
  },
  {
    id: 4,
    name: 'Perfect Magician and companion of the Holy Royal Arch of Enoch',
    initiationFee: 120,
    annualDuesGL: 168,
    monthlyDues: 25,
    color: 'rgb(31 101 162 /59%)',
    number: 'IV°',
    triad: 'Man of Earth',
    description:
      '"In the next ceremony I show how the individual, released by death from the obsession of personality, resumes relations with the truth of the universe. Reality bursts upon him in a blaze of adorable light; he is able to appreciate its splendour as he could not previously do, since his incarnation has enabled him to establish particular relations between the elements of eternity."—The Confessions of Aleister Crowley<br /><br />He experiences the World beyond Death.—Magick Without Tears, Chapter XIII ‘System of the O.T.O.’',
  },
  {
    id: 5,
    name: 'Perfect Initiate or Prince of Jerusalem',
    initiationFee: 36,
    annualDuesGL: 168,
    monthlyDues: 25,
    color: 'rgb(31 101 162 /59%)',
    number: 'P∴I∴',
    triad: 'Man of Earth',
    description:
      '"Finally, the cycle is closed by the reabsorption of all individuality into infinity. It ends in absolute annihilation which, as has been shown elsewhere in this book, may in reality be regarded either as an exact equivalent for all other terms soever, or (by postulating the category of time) as forming the starting point for new adventure of the same kind."—The Confessions of Aleister Crowley<br /><br />"This entire cycle of Point-Events is withdrawn into Annihilation."—Magick Without Tears, Chapter XIII ‘System of the O.T.O.’',
  },
  {
    id: 6,
    name: 'Knight of the East and West',
    color: 'rgb(57 57 57 / 40%)',
    number: '',
    triad: 'Outside of all triads',
    description:
      '"The degree of Knights of the East and West is but a bridge between the first and second series; but it is important, for in that grade a new pledge-form must be signed, and the new Knight vowed to devote his life to the Establishment of the Law of Thelema."—Liber CXCIV',
  },
  {
    id: 7,
    name: 'Sovereign Prince Rose-Croix, and Knight of the Pelican and Eagle',
    color: 'rgb(255 226 0 /43%)',
    number: 'V°',
    triad: 'Lovers',
    description:
      '"The members of the Fifth Degree are responsible for all that concerns the Social welfare of the Order. This grade is symbolically that of beauty and harmony; it is the natural stopping-place of the majority of men and women; for to proceed farther, as will appear, involves renunciation of the sternest kind. Here then is all joy, peace, well-being on all planes; the Sovereign Prince Rose Croix is attached equally to the higher and the lower, and forms a natural link between them. Yet let him look to it that his eyes are set on high!"—Liber CXCIV<br /><br />"The Rituals V°–IX° are then instructions to the Candidate how he should conduct himself; and they confer upon him, gradually, the Magical Secrets which make him Master of Life. I therefore answered the question "How should a young man mend his way?" in a series of rituals in which the candidate is instructed in the value of discretion, loyalty, independence, truthfulness, courage, self-control, indifference to circumstance, impartiality, scepticism, and other virtues, and at the same time assisted him to discover for himself the nature of this secret, the proper object of its employment and the best means for insuring success in its use. The first of these degrees is the V°, in which the secret is presented in a pageant; while he is also instructed in the essential elements of the history of the world, considered from the standpoint of his present state of evolution and in his proper relation to society in general with reference to the same."—The Confessions of Aleister Crowley',
  },
  {
    id: 8,
    name: 'Knight of the Red Eagle, and Member of the Senate of Knight Hermetic Philosophers',
    color: 'rgb(255 226 0 /43%)',
    number: '',
    triad: 'Lovers',
    description:
      '"The next grade, that which lies between the Fifth and Sixth Degrees, is called the Senate. This is the first of the governing bodies, properly speaking, and here we begin to insist upon Renunciation. For within this body is the Electoral College of the O.T.O."—Liber CXCIV<br /><br />"The degree of Knight Hermetic Philosopher follows, in which his intellectual and moral attitude is further defined."—The Confessions of Aleister Crowley',
  },
  {
    id: 9,
    name: 'Illustrious Knight (Templar) of the Order of Kadosch, and Companion of the Holy Graal',
    number: 'VI°',
    color: 'rgb(255 226 0 /43%)',
    triad: 'Lovers',
    description:
      '"The Sixth Degree is an executive or military body, and represents the temporal power of the Supreme and Holy King. Each member is amenable to military discipline. Singly or in concert with his comrades, each Knight is vowed to enforce the decisions of authority."—Liber CXCIV <br /><br />"In the VI°, his position having been thus made precise, he is shown how to consecrate himself to the particular Great Work which he came to earth in order to perform."—The Confessions of Aleister Crowley',
  },
  {
    id: 10,
    name: 'Grand Inquisitor Commander, and Member of the Grand Tribunal',
    number: '',
    color: 'rgb(255 226 0 /43%)',
    triad: 'Lovers',
    description:
      '"The Grade of Grand Inquisitor Commander follows. Here every member has the right to a seat on the Grand Tribunal, which body decides all disputes and complaints which have not been composed by the Chapters of Rose Croix or the Lodge Masters. Its verdicts are without appeal, unless a member of the Electoral College give sanction to take the case to the Areopagus of the Eighth Degree. All members of the Order, even of higher grades, are subject to the Grand Tribunal."—Liber CXCIV',
  },
  {
    id: 11,
    name: 'Prince of the Royal Secret',
    number: '',
    color: 'rgb(255 226 0 /43%)',
    triad: 'Lovers',
    description:
      '"The next grade is that of Prince of the Royal Secret. Every member of this degree is devoted to the Propagation of the Law in a very special manner; for this grade is the first in which the Beginning of the Inmost Secret is declared openly. He will therefore, by his personal exertions, induce one hundred and eleven persons to join the Order, before he may proceed to the Seventh Degree, except by special order from the Supreme and Holy King."—Liber CXCIV',
  },
  {
    id: 12,
    name: 'Theoreticus, and Very Illustrious Sovereign Grand Inspector General',
    number: 'VII°',
    color: 'rgb(255 226 0 /43%)',
    triad: 'Lovers',
    description:
      '"The Seventh Degree is, in military language, the Great General Staff of the Army of the Sixth Degree. From its members the Supreme and Holy King appoints a Supreme Grand Council."—Liber CXCIV<br /><br />"In the VII°, which is tripartite, he is first taught the principle of equilibrium as extended to all possible moral ideas; secondly, to all possible intellectual ideas; and lastly, he is shown how, basing all his actions on this impregnable rock of justice, he may so direct his life as to undertake his Great Work with the fullest responsibility and in absolute freedom from all possibility of interferences."—The Confessions of Aleister Crowley',
  },
  {
    id: 13,
    name: 'Magus of Light, and Bishop of Ecclesia Gnostica Catholica',
    number: '',
    color: 'rgb(255 226 0 /43%)',
    triad: 'Lovers',
    description: '',
  },
  {
    id: 14,
    name: 'Grandmaster of Light, and Inspector of Rites and Degrees',
    number: '',
    color: 'rgb(255 226 0 /43%)',
    triad: 'Lovers',
    description: '',
  },
  {
    id: 15,
    name: 'Perfect Pontiff of the Illuminati',
    number: 'VIII°',
    color: 'rgb(157 0 0 / 64%)',
    triad: 'Hermit',
    description:
      '"The Eighth Degree is a Philosophical Body. Its members being fully instructed in the Principles of the Order, save in one point only, devote themselves to the understanding of what they have learned in their initiation."—Liber CXCIV<br /><br />"In the VIII°, the secret is once more manifested to him, more clearly than before; and he is instructed in how to train himself to use it by certain preliminary practices involving acquaintance with some of those subtler energies which have hitherto, for the most part, eluded the observation and control of profane science."—The Confessions of Aleister Crowley',
  },
  {
    id: 16,
    name: 'Epopt of the Illuminati',
    number: '',
    color: 'rgb(157 0 0 / 64%)',
    triad: 'Hermit',
    description: '',
  },
  {
    id: 17,
    name: 'Initiate of the Sanctuary of the Gnosis',
    number: 'IX°',
    color: 'rgb(157 0 0 / 64%)',
    triad: 'Hermit',
    description:
      'In the IX°, which is never conferred upon anyone who has not already divined from previous indications the nature of the secret, it is explained to him fully.<br /><br />"The Ninth Degree-the Sanctuary of the Gnosis-is synthetic. The prime duty of its members is to study and practise the theurgy and thaumaturgy of the grade; but in addition they must be prepared to act as direct representatives of the Supreme and Most Holy King, radiating his light upon the whole world. Yet, from the nature of their initiation, they must veil their glory in a cloud of darkness. They move unseen and unrecognized among the youngest of us, subtly and loftily leading us into the holy ineffable mysteries of the True Light."—Liber CXCIV',
  },
  {
    id: 18,
    name: 'Rex Summus Sanctissimus',
    number: 'X°',
    color: 'rgb(157 0 0 / 64%)',
    triad: 'Hermit',
    description:
      'The X° is conferred upon the Supreme and Holy King of each national Grand Lodge.<br /><br />“The X°, as in the old system, is merely honorary…”—The Confessions of Aleister Crowley" <br /><br />The Supreme and Most Holy King is appointed by the O.H.O. His is the ultimate responsibility for all within his holy kingdom."—Liber CXCIV',
  },
  {
    id: 19,
    name: 'Initiate of the Eleventh Degree',
    number: 'XI°',
    color: 'rgb(157 0 0 / 64%)',
    triad: 'Hermit',
    description:
      '"but recent researches into the mysteries of the IX° have compelled me to add an XI°, to illustrate a scientific idea which has been evolved by the results of recent experiments."—The Confessions of Aleister Crowley<br /><br />"Of the Eleventh Degree, its powers, privileges, and qualifications, nothing whatever is said in any grade. It has no relation to the general plan of the Order, is inscrutable, and dwells in its own Palaces."—Liber CXCIV',
  },
  {
    id: 20,
    name: 'Frater Superior, and Outer Head of the Order',
    number: 'XII°',
    color: 'rgb(157 0 0 / 64%)',
    triad: 'Hermit',
    description:
      'The XII° is held exclusively by the Outer Head of the Order.<br /<<br />"The succession to the high office of O.H.O. is decided in a manner not here to be declared; but this you may learn, O Brother Magician, that he may be chosen even from the grade of a Minerval. And herein lieth a most sacred Mystery."—The Confessions of Aleister Crowley',
  },
];

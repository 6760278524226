/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
interface Props {
  BannerTitle: string;
  BannerImage: string;
}
function PageBanner(props: Props) {
  return (
    <header id="page-banner" className="banner-main-block">
      <div className="banner-img" style={{ backgroundImage: `${props.BannerImage}` }}>
        <div className="overlay-bg"></div>
        <div className="container">
          <div className="banner-block">
            <h3 className="section-heading">{props.BannerTitle}</h3>
            <ol className="breadcrumb">
              <li>
                <a href="index.html">Home</a>
              </li>
              <li className="active">
                <a href="#">{props.BannerTitle}</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </header>
  );
}
export default PageBanner;

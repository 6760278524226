import PageBanner from '../components/PageBanner';
import SectionBody from '../components/SectionBody';
import SectionHeading from '../components/SectionHeading';
import SectionSubHeading from '../components/SectionSubHeading';
import { getRandomBackground, getSiteCopyById } from '../shared/utilities';
function MasonryView() {
  return (
    <>
      <PageBanner BannerTitle="O.T.O. and Masonry" BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ backgroundImage: getRandomBackground() }}>
        <section id="about-block" className="about-main-block abt-two" style={{ marginTop: '0px' }}>
          <div className="container" style={{ backgroundColor: '#ffffff' }}>
            <div className="abt-img">
              <br />
              <img src="images/masonry/masonry1.jpg" className="img-responsive" alt="abt-img-1" />
            </div>
            <div className="abt-content">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="abt-sectionOne">
                    <SectionHeading Heading={getSiteCopyById('about.masonry.title')} />
                    <SectionSubHeading Heading={getSiteCopyById('about.masonry.subtitle')} />
                    <SectionBody Heading={getSiteCopyById('lapis.masonry.body')} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
export default MasonryView;

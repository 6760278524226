import React from 'react';
import { TextBlockProps } from '../types';
import SectionBody from './SectionBody';
import SectionHeading from './SectionHeading';
import SectionSubHeading from './SectionSubHeading';
function TextBlock(props: TextBlockProps) {
  return (
    <div>
      <div className="abt-content" style={{ marginTop: '0px' }}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="abt-sectionOne">
              <SectionHeading Heading={props.Heading} />
              <SectionSubHeading Heading={props.SubHeading} />
              <SectionBody Heading={props.Body} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TextBlock;

import React from 'react';
import AboutContent from '../components/AboutContent';
import PageBanner from '../components/PageBanner';
import { getRandomBackground } from '../shared/utilities';
function AboutView() {
  return (
    <>
      <PageBanner BannerTitle="About Us" BannerImage={`url('images/top-banner.jpg')`} />
      <div style={{ backgroundImage: getRandomBackground() }}>
        <br />
        <section id="about-block" className="about-main-block abt-two" style={{ marginTop: '0px' }}>
          <div className="container" style={{ backgroundColor: '#ffffff' }}>
            <div className="abt-img">
              <img src="images/nature/nature1.jpg" className="img-responsive" alt="abt-img-1" />
            </div>
            <br />

            <AboutContent />
          </div>
        </section>
      </div>
    </>
  );
}
const styles = {
  whiteBG: {
    backgroundColor: '#ffffff',
  },
};
export default AboutView;

export const downloadItems = [
  {
    id: 1,
    title: 'Liber LXV Chapter 1 MP3',
    image: 'images/library/LXV_I_AUDIO.jpg',
    link: 'downloads/mp3s/LXV_I.mp3',
    createdBy: 'Fr. A.S.P.A.',
  },
  {
    id: 2,
    title: 'Liber LXV Chapter 3 MP3',
    image: 'images/library/LXV_III_AUDIO.jpg',
    link: 'downloads/mp3s/LXV_III.mp3',
    createdBy: 'Fr. A.S.P.A.',
  },
  {
    id: 3,
    title: 'A Missal for the People',
    image: 'images/library/missal.jpg',
    link: 'downloads/books/Mass Missal.pdf',
    createdBy: 'Unknown',
  },
  {
    id: 4,
    title: 'Supplement to the Missal',
    image: 'images/library/missal.jpg',
    link: 'downloads/books/Supplement to the Missal.pdf',
    createdBy: 'Unknown',
  },
];

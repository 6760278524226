import Immutable from 'immutable';
import { combineReducers } from 'redux';

import CardinalDirections from '../../data/Questions/4/CardinalDirections.json';
import DemonKings from '../../data/Questions/4/DemonKings.json';
import DemonKingsGoetia from '../../data/Questions/4/DemonKingsGoetia.json';
import EgyptianDeathGods from '../../data/Questions/4/EgyptianDeathGods.json';
import EgyptianJars from '../../data/Questions/4/EgyptianJars.json';
import ElementalQualities from '../../data/Questions/4/ElementalQualities.json';
import FourArchangels from '../../data/Questions/4/FourArchangels.json';
import FourGospels from '../../data/Questions/4/FourGospels.json';
import FourHumours from '../../data/Questions/4/FourHumours.json';
import FourWinds from '../../data/Questions/4/FourWinds.json';
import GodNames from '../../data/Questions/4/GodNames.json';
import GodNamesHebrew from '../../data/Questions/4/GodNamesHebrew.json';
import HebrewSoul from '../../data/Questions/4/HebrewSoul.json';
import LiberResh from '../../data/Questions/4/LiberResh.json';
import PowersOfSphinx from '../../data/Questions/4/PowersOfSphinx.json';
import PowersOfSphinxLatin from '../../data/Questions/4/PowersOfSphinxLatin.json';
import RiversOfEden from '../../data/Questions/4/RiversOfEden.json';
import RiversOfEvil from '../../data/Questions/4/RiversOfEvil.json';
import TAROTSuits from '../../data/Questions/4/TAROTSuits.json';
import Tetragrammaton from '../../data/Questions/4/Tetragrammaton.json';
import TetragrammatonFormula from '../../data/Questions/4/TetragrammatonFormula.json';
import TheElementals from '../../data/Questions/4/TheElementals.json';
import TheElements from '../../data/Questions/4/TheElements.json';
import TheSenses from '../../data/Questions/4/TheSenses.json';
import TheSphinx from '../../data/Questions/4/TheSphinx.json';
import VisionsOfAbraham from '../../data/Questions/4/VisionsOfAbraham.json';

const fours = combineReducers({
  cardinalDirections: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        CardinalDirections.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  demonKings: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        DemonKings.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  demonKingsGoetia: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        DemonKingsGoetia.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  egyptianDeathGods: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EgyptianDeathGods.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  egyptianJars: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        EgyptianJars.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  elementalQualities: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        ElementalQualities.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  fourArchangels: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        FourArchangels.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  fourGospels: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        FourGospels.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  fourHumours: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        FourHumours.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  fourWinds: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        FourWinds.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  godNames: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GodNames.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  godNamesHebrew: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        GodNamesHebrew.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  hebrewSoul: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        HebrewSoul.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  liberResh: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        LiberResh.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  powersOfSphinx: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PowersOfSphinx.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  powersOfSphinxLatin: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        PowersOfSphinxLatin.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  riversOfEden: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        RiversOfEden.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  riversOfEvil: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        RiversOfEvil.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tarotSuits: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TAROTSuits.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tetragrammaton: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        Tetragrammaton.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  tetragrammatonFormula: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TetragrammatonFormula.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  theElementals: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TheElementals.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  theElements: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TheElements.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  theSenses: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TheSenses.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  theSphinx: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        TheSphinx.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
  visionsOfAbraham: (state = new Immutable.Map(), action) => {
    return state.merge(
      Immutable.fromJS(
        VisionsOfAbraham.Questions.reduce((map, property) => {
          map[property.hint] = property;
          return map;
        }, {})
      )
    );
  },
});

export default fours;

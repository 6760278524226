import Immutable from 'immutable';
import { EXPORT_LOGIN } from './actions';
const lapisUser = (state = new Immutable.Map(), action) => {
  switch (action.type) {
    case EXPORT_LOGIN:
      return state.merge(
        Immutable.fromJS(
          action.payload.reduce((map, property) => {
            map[property.id] = property;
            return map;
          }, {})
        )
      );
    default:
      return state;
  }
};

export default lapisUser;

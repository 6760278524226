import React from 'react';
import { getSiteCopyById } from '../shared/utilities';
import ImageAndTextLeft from './ImageAndTextLeft';
import ImageAndTextRight from './ImageAndTextRight';
import SectionBody from './SectionBody';
import SectionHeading from './SectionHeading';
import SectionSubHeading from './SectionSubHeading';
function AboutContent() {
  return (
    <div className="abt-content">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="abt-sectionOne">
            <SectionHeading Heading={getSiteCopyById('about.lapis.title')} />
            <SectionSubHeading Heading={getSiteCopyById('about.lapis.subtitle')} />
            <SectionBody Heading={getSiteCopyById('lapis.about.body')} />
          </div>
        </div>
      </div>
      <ImageAndTextLeft
        ButtonLink={null}
        ButtonText={null}
        Image="images/temple/temple3.jpg"
        HeaderText={getSiteCopyById('lapis.about.left-to-right.title')}
        BodyText={getSiteCopyById('lapis.about.left-to-right.body')}
      />
      <ImageAndTextRight
        ButtonLink={null}
        ButtonText={null}
        Image="images/nature/initiation1.jpg"
        HeaderText={getSiteCopyById('lapis.about.right-to-left.title')}
        BodyText={getSiteCopyById('lapis.about.right-to-left.body')}
      />
      <br />
    </div>
  );
}
export default AboutContent;
